import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./ninety_day_calls_report_thunk";
import { IReportCenterService } from "../../../../interfaces/report_center_service";

export interface NinetyDayCallsReportsListState extends EntityState<IReportCenterService> {
    error?: AppError | null,
    loading: LoadingType,  
}

const ninetyDayCallsReportsListAdapter: EntityAdapter<IReportCenterService> = createEntityAdapter<IReportCenterService>({
    selectId: (doc) => doc.id,
})

const initialState: NinetyDayCallsReportsListState = ninetyDayCallsReportsListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const ninetyDayCallsReportsListSlice = createSlice({
    name: 'ninetyDayCallsReportsList',
    initialState: initialState,
    reducers: {
        clearNinetyDayCallsReportsListError(state) {
            return { ...state, error: null };
        },
        clearNinetyDayCallsReportsListState(state) {
            return ninetyDayCallsReportsListAdapter.removeAll({ ...state, loading: LoadingType.idle, error: null });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getNinetyDayCallsReportsList.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getNinetyDayCallsReportsList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getNinetyDayCallsReportsList.fulfilled, (state, action) => ninetyDayCallsReportsListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearNinetyDayCallsReportsListError, clearNinetyDayCallsReportsListState } = ninetyDayCallsReportsListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = ninetyDayCallsReportsListAdapter.getSelectors();
export const selectState = (state: NinetyDayCallsReportsListState) => state;


