import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { RouteComponentProps } from "react-router-dom";
import SortableTable, {
  TableData,
} from "../../../../components/sortable_table/sortable_table";
import TableEmpty, {
  TableErrorHandler,
  TableLoading,
} from "../../../../components/table_empty/table_empty";
import { LoadingType } from "../../../../enums";
import {
  CalenderIcon,
  CompletedCallIcon,
  CompletedCallWhiteIcon,
  ScheduledCallIcon,
  ScheduledCallWhiteIcon,
  TickGreenIcon,
  TickGreyIcon,
} from "../../../../icons";
import { ServiceCallInfo } from "../../../../interfaces";
import { manageServiceCallActions } from "../../../../redux/sales_marketing/service_call_activity/manage_service_call/manage_service_calls_reducer";
import {
  selectServiceCallList,
  selectServiceCallListEntities,
  selectServiceCallListState,
} from "../../../../redux/sales_marketing/service_call_activity/service_call_list/service_call_list_selector";
import { getServiceCallList } from "../../../../redux/sales_marketing/service_call_activity/service_call_list/service_call_list_thunk";
import {
  getRoleUsersList,
  selectProfileState,
  useAppDispatch,
  useAppSelector,
  selectAccountManagerRoleUsersList,
  selectRoleUsersList,
} from "../../../../redux/store";
import { AppRoutes } from "../../../../routes";
import {
  CustomCheckBox,
  getContactIcon,
  getUserNameFromProfileDropdown,
} from "../../../../utils";
import { convertDateToTimeStamp, getDateString } from "../../../../variables";
import { CompleteServiceCall } from "./complete_service_call";
import SearchBar from "../../../../components/search_bar";
import '../service_call_activity/service_call_activity.scss'
import { CustomMultiFormSelect } from "../../../../components/form_selector/form_select";
import { DateInput } from "../../../../components/date_input/date_input";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import "react-datepicker/dist/react-datepicker.css";
interface Props extends RouteComponentProps<any> { }

const ServiceCallActivity: React.FunctionComponent<Props> = (props) => {
  const [showCompleted, setShowCompleted] = useState<boolean>(false);
  const [tab, setTab] = useState<
    | "Arrival Calls"
    | "4 Hour Guarantee"
    | "End of 1st Day Calls"
    | "End of 2nd Day Calls"
    | "Friday Calls"
    | "End of Assignment Call"
    | "Job Confirmation Calls"
    | "30 Day calls"
    | "60 Day calls"
    | "90 Day calls"
  >("Arrival Calls");
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const profileState = useAppSelector((state) => selectProfileState(state));
  const [sortedField, setSortedField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [search, setSearch] = useState("");

  const [showCompletePopup, setShowCompletePopup] = useState<boolean>(false);
  const [selectedCall, setSelectedCall] = useState<ServiceCallInfo>(
    new ServiceCallInfo()
  );

  const [thisWeek, setThisWeek] = useState<boolean>(false);

  const { location, history } = props;

  const tableHeader = [
    { title: "complete_call", code: "complete_call" },
    { title: "company", code: "company_name" },
    { title: "talent", code: "talent_name" },
    { title: "department", code: "department_name" },
    { title: "contact", code: "contact_name" },
    { title: "DM Reached", code: "dm_reached" },
    { title: "Call Method", code: "call_method" },
    { title: "Date of Scheduled Service Call", code: "date_of_scheduled_call" },
  ];

  const completedCallsTableheader = [
    { title: "company", code: "company_name" },
    { title: "talent", code: "talent_name" },
    { title: "department", code: "department_name" },
    { title: "contact", code: "contact_name" },
    { title: "DM Reached", code: "dm_reached" },
    { title: "Call Method", code: "call_method" },
    { title: "Date of Scheduled Service Call", code: "date_of_scheduled_call" },
    { title: "Date of Completed Service Call", code: "date_of_actual_call" },
    { title: "Call Results", code: "call_result" },
  ];

  const serviceCallsState = useAppSelector((state) =>
    selectServiceCallListState(state)
  );

  const serviceCalls = useAppSelector((state) => selectServiceCallList(state));
  const serviceCallEntities = useAppSelector((state) =>
    selectServiceCallListEntities(state)
  );
  // const accountManagersList = useAppSelector((state) =>
  //   selectAccountManagerRoleUsersList(state)
  // );

  const usersList = useAppSelector((state) => selectRoleUsersList(state));

  const [serviceCallsFilter, setServiceCallsFilter]: any = useState("");
  const [serviceFormState, setServiceFormState] = useState<{ available_from: number, available_to: number }>({
    available_from: 0,
    available_to: 0,
  })

  useEffect(() => {
    getcalls(false);
    dispatch(getRoleUsersList());
  }, []);

  useEffect(() => {
    const state = location.state as any;
    if (state) {
      if (state["completeCall"]) {
        const call = serviceCallEntities[state["id"]];
        if (call) {
          setSelectedCall({ ...call });
          setShowCompletePopup(true);
          setTab(call.service_call as any);
          setShowCompleted(call.call_completed);
          history.replace({ ...history.location, state: undefined });
          window.history.replaceState({}, document.title);
        }
      }
    }
  }, [location, serviceCallEntities]);

  const getcalls = (val: boolean) => dispatch(getServiceCallList(val));

  const handleSortFieldChange = (value: string) => {
    if (sortedField && sortedField === value) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  };

  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  // const [serviceDateRange, setServiceDateRange] = useState([null, null]);
  const [serviceDateRange, setServiceDateRange] = useState([thirtyDaysAgo, new Date()]);
  const [serviceStartDate, serviceEndDate] = serviceDateRange;
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);

  useEffect(() => {
    if (!serviceFormState.available_from && !serviceFormState.available_to && showCompleted) {
      setServiceFormState({ available_from: convertDateToTimeStamp(thirtyDaysAgo), available_to: convertDateToTimeStamp(new Date()) })
    }
  }, [])

  function handleChange(value) {
    setServiceFormState({
      ...serviceFormState, available_from: value[0] !== null ? convertDateToTimeStamp(value[0]) : 0,
      available_to: value[1] !== null ? convertDateToTimeStamp(new Date(value[1].getTime() + (1 * 24 * 60 * 60 * 1000))) : 0,
    });
    setServiceDateRange(value);
  }

  const [isFiltersApplied, setIsFiltersApplied] = useState<boolean>(false);
  useEffect(() => {
    if (serviceCallsFilter.length !== 0 || (serviceFormState.available_from !== 0) || thisWeek) {
      setIsFiltersApplied(true)
    }
    else {
      setIsFiltersApplied(false)
    }
  }, [serviceCallsFilter.length, serviceFormState.available_from, thisWeek]);

  useEffect(() => {
    if ((serviceFormState.available_from !== 0 && serviceFormState.available_to !== 0)) {
      setDatePickerOpen(false)
    }
  }, [serviceFormState.available_from, serviceFormState.available_to])

  useEffect(() => {
    if ((serviceStartDate === null && serviceEndDate === null) || (serviceFormState.available_from === 0 && serviceFormState.available_to === 0)) {
      setServiceDateRange([thirtyDaysAgo, new Date()]);
      setServiceFormState({ available_from: convertDateToTimeStamp(thirtyDaysAgo, true), available_to: convertDateToTimeStamp(new Date(), true) })
    }
  }, [showCompleted]);

  const openDatePicker = () => {
    setDatePickerOpen(true);
  };

  const getFilteredList = () => {
    let list: ServiceCallInfo[] = [...serviceCalls];
    let sortedList: ServiceCallInfo[] | undefined;
    list = list.filter((doc) => doc.call_completed === showCompleted && doc.service_call === tab);


    const today = new Date();
    // ✅ Get the first day of the current week (Sunday)
    const firstDay = new Date(
      today.setDate(today.getDate() - today.getDay()),
    );
    // ✅ Get the last day of the current week (Saturday)
    const lastDay = new Date(
      today.setDate(today.getDate() - today.getDay() + 6),
    );
    const firstDayTimeStampNumber = convertDateToTimeStamp(firstDay);
    const lastDayTimeStampNumber = convertDateToTimeStamp(lastDay);

    if (serviceFormState.available_from && serviceFormState.available_to && showCompleted) {
      sortedList = list.filter((doc) => {
        const isScheduleValid = doc.date_of_scheduled_call !== null &&
          convertDateToTimeStamp(thirtyDaysAgo) <= doc.date_of_scheduled_call &&
          doc.date_of_scheduled_call < convertDateToTimeStamp(new Date());

        return isScheduleValid;
      });
    }

    if (serviceCallsFilter?.length !== 0 && (serviceFormState.available_from !== 0 && serviceFormState.available_to !== 0)) {
      sortedList = list.filter((doc) => {
        const isScheduleValid =
          doc.date_of_scheduled_call !== null &&
          serviceFormState.available_from <= doc.date_of_scheduled_call &&
          doc.date_of_scheduled_call < serviceFormState.available_to;

        return isScheduleValid && (doc.modified_by !== null ? serviceCallsFilter.includes(doc?.modified_by!) : serviceCallsFilter.includes(doc?.created_by!));
      });

    }
    else if (serviceCallsFilter?.length !== 0) {
      sortedList = list.filter((doc) => {
        const userIncluded = doc.modified_by !== null ? serviceCallsFilter.includes(doc?.modified_by!) : serviceCallsFilter.includes(doc?.created_by!);
        return userIncluded;
      })
    }
    else if (serviceFormState.available_from !== 0 && serviceFormState.available_to !== 0 && showCompleted) {
      sortedList = list.filter((doc) => {
        const isScheduleValid =
          doc.date_of_scheduled_call !== null &&
          serviceFormState.available_from <= doc.date_of_scheduled_call &&
          doc.date_of_scheduled_call < serviceFormState.available_to;

        return isScheduleValid;
      });
    }
    if (thisWeek) {
      sortedList = list.filter((doc) => {
        const filter =
          doc.date_of_scheduled_call !== null && (firstDayTimeStampNumber <= doc.date_of_scheduled_call && doc.date_of_scheduled_call <= lastDayTimeStampNumber);
        return filter;
      });
    }
    if (serviceCallsFilter?.length !== 0 && thisWeek) {
      sortedList = list.filter((doc) => {
        const filter =
          doc.date_of_scheduled_call !== null && (firstDayTimeStampNumber <= doc.date_of_scheduled_call && doc.date_of_scheduled_call <= lastDayTimeStampNumber) &&
          (doc.modified_by !== null ? serviceCallsFilter.includes(doc?.modified_by!) : serviceCallsFilter.includes(doc?.created_by!));
        return filter;
      });
    }

    if (sortedField !== null) {
      sortedList = [...(sortedList ?? list)].sort((a, b) => {
        const valueA =
          a[sortedField] != null && a[sortedField] !== undefined
            ? typeof a[sortedField] == typeof "1"
              ? a[sortedField].trim().toLowerCase()
              : a[sortedField]
            : "";
        const valueB =
          b[sortedField] != null
            ? typeof b[sortedField] == typeof "1"
              ? b[sortedField].trim().toLowerCase()
              : b[sortedField]
            : "";
        if (sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }
    return (sortedList ?? list)?.filter(
      (item) => {
        const str = search.trim().toLowerCase();
        const companyName = item.company_name
          ? item.company_name.trim().toLowerCase().includes(str)
          : false;
        const departmentName = item.department_name
          ? item.department_name.trim().toLowerCase().includes(str)
          : false;
        const talentName = item.talent_name
          ? item.talent_name.trim().toLowerCase().includes(str)
          : false;
        const contactName = item.contact_name
          ? item.contact_name.trim().toLowerCase().includes(str)
          : false;
        const callResult = item.call_result
          ? item.call_result.trim().toLowerCase().includes(str)
          : false;

        return (item.service_call === t(tab) && item.call_completed === showCompleted) &&
          (companyName || departmentName || contactName || talentName || callResult);
      });
  };

  const getTabCount = (tabName) => {
    return serviceCalls.filter(
      (item) =>
        item.call_completed === showCompleted && item.service_call === tabName
    ).length;
  };
  const getTabFilterCount = (tabName) => {
    let list = serviceCalls.filter(
      (item) => item.call_completed === showCompleted && item.service_call === tabName
    ).length;

    let filteredList;
    const today = new Date();
    // ✅ Get the first day of the current week (Sunday)
    const firstDay = new Date(
      today.setDate(today.getDate() - today.getDay()),
    );
    // ✅ Get the last day of the current week (Saturday)
    const lastDay = new Date(
      today.setDate(today.getDate() - today.getDay() + 6),
    );
    const firstDayTimeStampNumber = convertDateToTimeStamp(firstDay);
    const lastDayTimeStampNumber = convertDateToTimeStamp(lastDay);
    
    if (serviceCallsFilter?.length !== 0 && (serviceFormState.available_from !== 0 && serviceFormState.available_to !== 0)) {
      filteredList = (serviceCalls.filter((doc) => {
        const isScheduleValid =
          doc.date_of_scheduled_call !== null &&
          serviceFormState.available_from <= doc.date_of_scheduled_call &&
          doc.date_of_scheduled_call < serviceFormState.available_to;

        const userIncluded = (doc.modified_by !== null ? serviceCallsFilter.includes(doc?.modified_by!) : serviceCallsFilter.includes(doc?.created_by!));
        return isScheduleValid && userIncluded && doc.call_completed === showCompleted && doc.service_call === tabName;

      }).length);
    }
    
    else if (serviceCallsFilter?.length !== 0 && thisWeek) {
      filteredList = serviceCalls.filter((doc) => {
        const filter =
          doc.date_of_scheduled_call !== null && (firstDayTimeStampNumber <= doc.date_of_scheduled_call && doc.date_of_scheduled_call <= lastDayTimeStampNumber) &&
          (doc.modified_by !== null ? serviceCallsFilter.includes(doc?.modified_by!) : serviceCallsFilter.includes(doc?.created_by!));
        return filter && doc.call_completed === showCompleted && doc.service_call === tabName;
      }).length;
    }

    else if (thisWeek) {

      filteredList = serviceCalls.filter((doc) => {
        const filter =
          doc.date_of_scheduled_call !== null && (firstDayTimeStampNumber <= doc.date_of_scheduled_call && doc.date_of_scheduled_call <= lastDayTimeStampNumber);
        return filter && doc.call_completed === showCompleted && doc.service_call === tabName;
      }).length;
    }
    else if (serviceCallsFilter.length !== 0) {
      filteredList = serviceCalls.filter((doc) => {
        const userIncluded = doc.modified_by !== null ? serviceCallsFilter.includes(doc?.modified_by!) : serviceCallsFilter.includes(doc?.created_by!);
        return userIncluded && doc.call_completed === showCompleted && doc.service_call === tabName;
      }).length;
    }
    else if (serviceFormState.available_from !== 0 && serviceFormState.available_to !== 0 && showCompleted) {

      filteredList = serviceCalls.filter((doc) => {
        const isScheduleValid = doc.date_of_scheduled_call !== null &&
          serviceFormState.available_from <= doc.date_of_scheduled_call &&
          doc.date_of_scheduled_call < serviceFormState.available_to;

        return isScheduleValid && doc.call_completed === showCompleted && doc.service_call === tabName;
      }).length;
    }
    else if (serviceCallsFilter?.length !== 0 && thisWeek) {

      filteredList = serviceCalls.filter((doc) => {
        const filter =
          doc.date_of_scheduled_call !== null && (firstDayTimeStampNumber <= doc.date_of_scheduled_call && doc.date_of_scheduled_call <= lastDayTimeStampNumber) &&
          (doc.modified_by !== null ? serviceCallsFilter.includes(doc?.modified_by!) : serviceCallsFilter.includes(doc?.created_by!));
        return filter && doc.call_completed === showCompleted && doc.service_call === tabName;
      }).length;
    }
    return (filteredList ?? list)

  };

  // const [disableInput, setDisableInput] = useState<boolean>(false);
  const showSpinner = () => serviceCallsState.loading === LoadingType.pending;

  const getTable = () => {
    if (showSpinner()) return <TableLoading />;

    if (serviceCallsState.error != null && serviceCalls.length === 0) {
      return (
        <TableErrorHandler
          error={serviceCallsState.error}
          onRefresh={() => getcalls(showCompleted)}
        />
      );
    }

    if (serviceCalls.length === 0 || getFilteredList().length === 0) {
      return (
        <TableEmpty
          title={"No calls found"}
          onClick={() => getcalls(showCompleted)}
        />
      );
    }

    if (getFilteredList().length !== 0) {
      return (
        <SortableTable
          headerList={showCompleted ? completedCallsTableheader : tableHeader}
          sortedField={sortedField}
          onSortChange={handleSortFieldChange}
          flexNumber={getFlexNumber}
          isAsc={sortDirection}
        >
          {getFilteredList().map((doc) => {
            return (

              <tr key={doc.id}>
                {!showCompleted ? (
                  <TableData customStyle={{ flex: getFlexNumber(0) }}>
                    <button
                      className="complete-call"
                      onClick={() => {
                        setSelectedCall(doc);
                        setShowCompletePopup(true);
                      }}
                    >
                      <span>{t("complete_call")}</span>
                    </button>
                  </TableData>
                ) : (
                  <TableData
                    customStyle={{ flex: getFlexNumber(0) }}
                    isButton={doc.company_name ? true : false}
                    onClick={() => handleCompanySelect(doc)}
                  >
                    <span>{doc.company_name}</span>
                  </TableData>
                )}
                {!showCompleted && (
                  <TableData
                    customStyle={{ flex: getFlexNumber(1) }}
                    isButton={doc.company_name ? true : false}
                    onClick={() => handleCompanySelect(doc)}
                  >
                    <span>{doc.company_name}</span>
                  </TableData>
                )}
                <TableData
                  customStyle={{ flex: getFlexNumber(2) }}
                  isButton={doc.talent_name ? true : false}
                  onClick={() => handleTalentSelect(doc)}
                >

                  <span>{doc.talent_name ?? ""}</span>
                </TableData>
                <TableData
                  customStyle={{ flex: getFlexNumber(3) }}
                  // isButton={doc.department_name ? true : false}
                  isButton={true}
                  onClick={() => handleDepartmentSelect(doc)}
                >
                  <span>{doc.department_name ?? t("corporate")}</span>
                </TableData>
                <TableData
                  customStyle={{ flex: getFlexNumber(4) }}
                  isButton={doc.contact_name ? true : false}
                  onClick={() => handleContactSelect(doc)}
                >
                  <span>{doc.contact_name}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(5) }}>
                  {doc.dm_reached ? <TickGreenIcon /> : <TickGreyIcon />}
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(6) }}>
                  {getContactIcon(doc.call_method?.toUpperCase())}
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(7) }}>
                  <span>{getDateString(doc.date_of_scheduled_call)}</span>
                </TableData>
                {showCompleted && (
                  <>
                    <TableData customStyle={{ flex: getFlexNumber(8) }}>
                      <span>{getDateString(doc.date_of_actual_call)}</span>
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(9) }}>
                      <span>{doc.call_result}</span>
                    </TableData>
                  </>
                )}
              </tr>

            );
          })}
        </SortableTable>
      );
    }
  };

  function getFlexNumber(value: number) {
    if (value === 0) return 1.2;
    if (value === 1) return 1;
    if (value === 2) return 1;
    if (value === 3) return 1;
    if (value === 4) return 1;
    if (value === 5) return 1;
    if (value === 6) return 1;
    if (value === 7) return 1;
    if (value === 8) return 1;
    if (value === 9) return 1;
    if (value === 10) return 1;
    if (value === 11) return 1;
    return 1;
  }

  function handleCompanySelect(value: ServiceCallInfo) {
    props.history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
      state: {
        id: value.company_id,
        name: value.company_name,
      },
    });
    return;
  }

  function handleDepartmentSelect(value: ServiceCallInfo) {
    props.history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.department_id}`,
      state: {
        id: value.company_id,
        name: value.company_name,
        departmentId: value.department_id,
        departmentName: value.department_name,
      },
    });
    return;
  }

  function handleTalentSelect(value: ServiceCallInfo) {
    props.history.push({
      pathname: `${AppRoutes.talentDetailsPage}/${value.talent_id}`,
      state: {
        id: value.talent_id,
        name: value.talent_name,
      },
    });
    return;
  }
  function handleContactSelect(value: ServiceCallInfo) {
    props.history.push({
      pathname: `${AppRoutes.contactsDetailsPage}/${value.contact_id}`,
      state: {
        id: value.contact_id,
        name: value.contact_name,
      },
    });
    return;
  }

  return (
    <div className="service-call-activity">
      <div className="flex-row main-header">
        <div
          className={`flex-grow main-header-item ${!showCompleted ? "selected" : ""
            }`}
          onClick={() => {
            setShowCompleted(false);
            getcalls(false);
          }}
        >
          {!showCompleted ? (
            <ScheduledCallWhiteIcon
              height={"20px"}
              width={"20px"}
              style={{ marginRight: "15px" }}
            />
          ) : (
            <ScheduledCallIcon
              height={"20px"}
              width={"20px"}
              style={{ marginRight: "15px" }}
            />
          )}
          Scheduled Service Calls
        </div>
        <div
          className={`flex-grow main-header-item ${showCompleted ? "selected" : ""
            }`}
          onClick={() => {
            setShowCompleted(true);
            getcalls(true);
          }}
        >
          {showCompleted ? (
            <CompletedCallWhiteIcon
              height={"20px"}
              width={"20px"}
              style={{ marginRight: "15px" }}
            />
          ) : (
            <CompletedCallIcon
              height={"20px"}
              width={"20px"}
              style={{ marginRight: "15px" }}
            />
          )}
          Completed Service Calls
        </div>
      </div>
      <div className="tab-navigation-container">
        <div className="header">
          <div className="tab-options-container">
            <div
              className={`tab-option ${tab === "Arrival Calls" ? "selected" : ""}`}
              onClick={() => setTab("Arrival Calls")}
            >
              <span>{t("arrival_calls")}</span>
            </div>
            <div
              className={`tab-option ${tab === "4 Hour Guarantee" ? "selected" : ""}`}
              onClick={() => setTab("4 Hour Guarantee")}
            >
              <span>{t("4 Hour Guarantee")}</span>
            </div>
            <div
              className={`tab-option ${tab === "End of 1st Day Calls" ? "selected" : ""}`}
              onClick={() => setTab("End of 1st Day Calls")}
            >
              <span>{t("End of 1st Day Calls")}</span>
            </div>
            <div
              className={`tab-option ${tab === "End of 2nd Day Calls" ? "selected" : ""}`}
              onClick={() => setTab("End of 2nd Day Calls")}
            >
              <span>{t("End of 2nd Day Calls")}</span>
            </div>
            <div
              className={`tab-option ${tab === "Friday Calls" ? "selected" : ""}`}
              onClick={() => setTab("Friday Calls")}
            >
              <span>{t("friday_calls")}</span>
            </div>
            <div
              className={`tab-option ${tab === "End of Assignment Call" ? "selected" : ""
                }`}
              onClick={() => setTab("End of Assignment Call")}
            >
              <span>{t("End of Assignment Calls")}</span>
            </div>
            <div
              className={`tab-option ${tab === "Job Confirmation Calls" ? "selected" : ""
                }`}
              onClick={() => setTab("Job Confirmation Calls")}
            >
              <span>{t("Job Confirmation Calls")}</span>
            </div>
            <div
              className={`tab-option ${tab === "30 Day calls" ? "selected" : ""
                }`}
              onClick={() => setTab("30 Day calls")}
            >
              <span>{t("30 Day calls")}</span>
            </div>
            <div
              className={`tab-option ${tab === "60 Day calls" ? "selected" : ""
                }`}
              onClick={() => setTab("60 Day calls")}
            >
              <span>{t("60 Day calls")}</span>
            </div>
            <div
              className={`tab-option ${tab === "90 Day calls" ? "selected" : ""
                }`}
              onClick={() => setTab("90 Day calls")}
            >
              <span>{t("90 Day calls")}</span>
            </div>
          </div>
        </div></div>
      <div className="table-container ">
        <div>
          <div className="actions">
            <div className="table-header">
              <CustomMultiFormSelect
                customStyle={{ width: "initial", marginLeft: "1em" }}
                // label={t("account_manager")}
                // name={"account_manager"}
                label={t("select_user")}
                name={"select_user"}
                value={serviceCallsFilter}
                placeholder={"All"}
                list={usersList.map((doc) => ({
                  label: `${doc.first_name ?? ""} ${doc.last_name ?? ""}`,
                  value: doc.id,
                }))}
                onChange={(value) => {
                  setServiceCallsFilter(value);
                }}
              />
              {
                !showCompleted ? null :
                  <div className="dateRange">
                    <label>{t("date_range")}</label>
                    <div className="date-input-icon" >
                      <div className="calendar-icon-div">
                        <CalenderIcon width={'100%'} height={'100%'} className="calendar-icon" onClick={openDatePicker} />
                      </div>
                      <DatePicker
                        selectsRange={true}
                        startDate={serviceStartDate}
                        endDate={serviceEndDate}
                        onChange={handleChange}
                        // isClearable={!showCompleted ? true : false}
                        isClearable={true}
                        placeholderText={'Start Date -  End Date'}
                        dateFormat={'MM.dd.yyyy'}
                        disabled={thisWeek}
                        //   onKeyDown={(e) => {
                        //     e.preventDefault();
                        //  }}  
                        onClickOutside={() => setDatePickerOpen(false)}
                        open={isDatePickerOpen}
                        onFocus={() => setDatePickerOpen(true)}
                      />
                    </div>

                  </div>
              }

              <div>
                <div className="serviceCall-toggle">
                  <CustomCheckBox
                    name={"this_week"}
                    title={t("this_week")}
                    checked={thisWeek}
                    onClick={() => { setThisWeek(!thisWeek); }}
                    disabled={showCompleted && serviceStartDate !== null ? true : false}
                  />
                </div>
              </div>
              <div className="total-count-action">
                <span>
                  {`${t("total_count")}: `}
                  <span className="total-count-number">
                    {getFilteredList()?.length}
                  </span>
                </span>
              </div>
            </div>
            <div className="sca-search-bar">
              <SearchBar
                value={search}
                onChange={(value) => setSearch(value)}
                onSearch={() => { }}
              />
            </div>
          </div>
          {getTable()}
        </div>

        <div className="total-count">
          Total {t(tab) + ":"} <span>{getFilteredList().length}</span>
        </div>
      </div>
      <div className="call-counts">
        <div className="total-count">
          Total Arrival Calls: <span>{isFiltersApplied ? getTabFilterCount(t("arrival_calls")) : getTabCount(t("arrival_calls"))}</span>
        </div>
        <div className="total-count">
          Total 4 Hour Guarantee: <span>{isFiltersApplied ? getTabFilterCount("4 Hour Guarantee") : getTabCount("4 Hour Guarantee")}</span>
        </div>
        <div className="total-count">
          Total End of 1st Day Calls: <span>{isFiltersApplied ? getTabFilterCount("End of 1st Day Calls") : getTabCount("End of 1st Day Calls")}</span>
        </div>
        <div className="total-count">
          Total End of 2nd Day Calls: <span>{isFiltersApplied ? getTabFilterCount("End of 2nd Day Calls") : getTabCount("End of 2nd Day Calls")}</span>
        </div>
        <div className="total-count">
          Total Friday Calls: <span>{isFiltersApplied ? getTabFilterCount(t("friday_calls")) : getTabCount(t("friday_calls"))}</span>
        </div>
        <div className="total-count">
          Total End of Assignment Calls: <span>{isFiltersApplied ? getTabFilterCount("End of Assignment Call") : getTabCount("End of Assignment Call")}</span>
        </div>
        <div className="total-count">
          Job Confirmation Calls: <span>{isFiltersApplied ? getTabFilterCount("Job Confirmation Calls") : getTabCount("Job Confirmation Calls")}</span>
        </div>
        <div className="total-count">
          30 Day calls: <span>{isFiltersApplied ? getTabFilterCount("30 Day calls") : getTabCount("30 Day calls")}</span>
        </div>
        <div className="total-count">
          60 Day calls: <span>{isFiltersApplied ? getTabFilterCount("60 Day calls") : getTabCount("60 Day calls")}</span>
        </div>
        <div className="total-count">
          90 Day calls: <span>{isFiltersApplied ? getTabFilterCount("90 Day calls") : getTabCount("90 Day calls")}</span>
        </div>
        <div className="service-count total-count">
          {`Total ${showCompleted ? "Completed" : "Scheduled"} Service Calls: `}
          <span className="total-span">{
            isFiltersApplied ? (getTabFilterCount(t("arrival_calls")) + getTabFilterCount(t("4 Hour Guarantee"))
              + getTabFilterCount(t("End of 1st Day Calls")) + getTabFilterCount(t("End of 2nd Day Calls")) + getTabFilterCount(t("friday_calls")) + getTabFilterCount("End of Assignment Call")) : serviceCalls?.filter((i) => i.call_completed === showCompleted)
              .length
          }</span>
        </div>
      </div>
      {showCompletePopup && profileState?.profile && (
        <Portal>
          <CompleteServiceCall
            showModal={showCompletePopup}
            callType={t(tab)}
            onClose={() => {
              setShowCompletePopup(false);
              dispatch(manageServiceCallActions.clearCreateServiceCallState());
              dispatch(manageServiceCallActions.clearUpdateServiceCallState());
            }}
            serviceCall={selectedCall}
            successTitle={t("complete_call")}
            currentLoginUserName={getUserNameFromProfileDropdown(
              profileState?.profile
            )}
            onSuccessClose={handleAddServiceCallSuccess}
            onGotoAgencyDashboard={handleGotoAgencyDashboard}
            onGotoCreated={handleOnGotoServiceCall}
            usersList={usersList}
          />
        </Portal>
      )}
    </div>
  );
  function handleAddServiceCallSuccess() {
    setShowCompletePopup(false);
    getcalls(false);
    dispatch(manageServiceCallActions.clearCreateServiceCallState());
    dispatch(manageServiceCallActions.clearUpdateServiceCallState());
  }

  function handleGotoAgencyDashboard() {
    handleAddServiceCallSuccess();
    props.history.push(AppRoutes.dashboardPage);
  }

  function handleOnGotoServiceCall(id: string, name: string) {
    handleAddServiceCallSuccess();
  }
};

export default ServiceCallActivity;
