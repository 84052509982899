import { IAddJobStaff, IAddSkill, ICreateJob, ICreateTalentClientCompanyInterview, IJobDocumentByPagePayload, IJobOrderDetail, IJobOrdersByPagePayload, IRemoveJobStaff, ITalentClientCompanyInterview, ITalentMatchesRequestBody } from "../interfaces";
import { ICreateGrossProfitCalculator } from "../interfaces/gross_profit_calculator";
import { IJobPost } from "../interfaces/job_posting";
import { SendByEmail } from "../interfaces/marketing_campaign";
import interceptor from "./interceptor";
import UrlBuilder from "./url_builder";

export const JobOrderAPI = {
    // [Jobs]

    getJobs() {
        return interceptor.get(UrlBuilder.jobOrders);
    },

    getJobsByPage(doc: IJobOrdersByPagePayload) {
        let url = `${UrlBuilder.getJobsByPage}?page=${doc.page}&size=${doc.size}`;
        if (doc.search_str) {
            url += `&search_str=${doc.search_str}`;
        }
        if (doc.filter_ids) return interceptor.post(url, doc.filter_ids);
        return interceptor.post(url);
    },

    getJobsDropdown(query?: { branch_id?: string, company_id?: string, department_id?: string, open_jobs?: boolean }) {
        if (query) {
            let queryString = `?company_id=${query.company_id}&open_jobs=${query.open_jobs}`;
            if (query.department_id)
                queryString += `&department_id=${query.department_id}`;
            return interceptor.get(`${UrlBuilder.jobDropdown}${queryString}`);
        }
        return interceptor.get(`${UrlBuilder.jobDropdown}`);
    },

    createJob(doc: { forceAdd: boolean, value: ICreateJob }) {
        if (doc.forceAdd) return interceptor.post(`${UrlBuilder.jobOrders}?force_add=${doc.forceAdd}`, doc.value);
        return interceptor.post(UrlBuilder.jobOrders, doc.value);
    },

    deleteJob(id: string) {
        return interceptor.delete(`${UrlBuilder.jobOrders}?job_id=${id}`);
    },

    updateJob(doc: IJobOrderDetail) {
        return interceptor.patch(UrlBuilder.jobOrders, doc);
    },

    getJobById(id: string) {
        return interceptor.get(`${UrlBuilder.jobOrders}?job_id=${id}`);
    },

    /// [Jobs] ---> Details --->  skills
    getJobSkills(doc: { job_id: string }) {
        return interceptor.get(`${UrlBuilder.jobSkills}?job_order_id=${doc.job_id}`);
    },
    addJobSkill(doc: { job_id: string, value: IAddSkill }) {
        return interceptor.post(`${UrlBuilder.jobSkills}?job_id=${doc.job_id}`, doc.value);
    },

    deleteJobSkill(doc: { job_id: string, value: IAddSkill }) {
        return interceptor.delete(`${UrlBuilder.jobSkills}?job_id=${doc.job_id}`, { data: doc.value });
    },

    ///[Jobs---> JobsDetails ---> Alpha omega staffing representatives]

    getJobStaff(doc: { job_id: string, role: string, }) {
        return interceptor.get(`${UrlBuilder.jobStaffs}?job_id=${doc.job_id}&role=${doc.role}`);
    },

    addJobStaff(data: IAddJobStaff) {
        return interceptor.post(UrlBuilder.jobStaffs, data);
    },

    deleteJobStaff(doc: IRemoveJobStaff) {
        return interceptor.delete(UrlBuilder.jobStaffs, { data: doc });
    },

    /// Matches

    getJobTalentMatches(doc: { job_id: string, query: ITalentMatchesRequestBody },) {
        return interceptor.post(`${UrlBuilder.talentMatches}?job_order_id=${doc.job_id}`, doc.query);
    },

    getJobCompanyMatches(doc: { job_id: string, query: ITalentMatchesRequestBody },) {
        return interceptor.post(`${UrlBuilder.companyMatches}?job_order_id=${doc.job_id}`, doc.query);
    },

    getJobsByCompany(data) {
        if (data.contact_id)
            return interceptor.get(`${UrlBuilder.jobOrdersByContact}?contact_id=${data['contact_id']}`);
        if (data.department_id)
            return interceptor.get(`${UrlBuilder.jobOrdersByCompany}?company_id=${data['company_id']}&department_id=${data['department_id']}`);
        else
            return interceptor.get(`${UrlBuilder.jobOrdersByCompany}?company_id=${data['company_id']}`);
    },

    getCompanyJobOrdersByPage(doc: IJobOrdersByPagePayload) {

        let url = doc.company_id ? `${UrlBuilder.getCompanyJobOrdersByPage}?page=${doc.page}&size=${doc.size}` : `${UrlBuilder.getContactJobOrdersByPage}?page=${doc.page}&size=${doc.size}`;
        if (doc.company_id) {
            url += `&company_id=${doc.company_id}`;
        }
        if (doc.contact_id) {
            url += `&contact_id=${doc.contact_id}`;
        }
        if (doc.department_id) {
            url += `&department_id=${doc.department_id}`;
        }
        if (doc.search_str) {
            url += `&search_str=${doc.search_str}`;
        }
        return interceptor.get(url);
    },

    getJobOrder(id: string) {
        return interceptor.get(`${UrlBuilder.jobOrders}?job_id=${id}`);
    },
    saveJobOrderData(data: IJobOrderDetail) {
        if (data.id)
            return interceptor.patch(`${UrlBuilder.jobOrders}`, data);
        else
            return interceptor.post(`${UrlBuilder.jobOrders}`, data);

    },
    createJobOrder(data) {
        return interceptor.post(`${UrlBuilder.jobOrders}`, data);
    },

    getJobOrderInterviews(data) {
        return interceptor.get(`${UrlBuilder.jobOrderInterviews}?job_order_id=${data.job_order_id}`);
    },
    getJobCertification(id) {
        return interceptor.get(`${UrlBuilder.jobCertification}?job_id=${id}`);
    },
    saveJobCertification(data) {
        return interceptor.post(`${UrlBuilder.jobCertification}`, data);
    },
    deleteJobCertification(data) {
        return interceptor.delete(`${UrlBuilder.jobCertification}`, { data: data });
    },
    getJobCredentials(id) {
        return interceptor.get(`${UrlBuilder.jobCredentials}?job_id=${id}`);
    },
    saveJobCredentials(data) {
        return interceptor.post(`${UrlBuilder.jobCredentials}`, data);
    },
    deleteJobCredentials(data) {
        return interceptor.delete(`${UrlBuilder.jobCredentials}`, { data: data });
    },
    getJobProfessionalSkills(id) {
        return interceptor.get(`${UrlBuilder.jobProfessionalSkills}?job_id=${id}`);
    },
    saveJobProfessionalSkills(data) {
        return interceptor.post(`${UrlBuilder.jobProfessionalSkills}`, data);
    },
    deleteJobProfessionalSkills(data) {
        return interceptor.delete(`${UrlBuilder.jobProfessionalSkills}`, { data: data });
    },
    getJobEquipments(id) {
        return interceptor.get(`${UrlBuilder.jobEquipments}?job_id=${id}`);
    },
    saveJobEquipments(data) {
        return interceptor.post(`${UrlBuilder.jobEquipments}`, data);
    },
    deleteJobEquipments(data) {
        return interceptor.delete(`${UrlBuilder.jobEquipments}`, { data: data });
    },
    getJobEducations(id) {
        return interceptor.get(`${UrlBuilder.jobEducations}?job_id=${id}`);
    },
    saveJobEducations(data) {
        return interceptor.post(`${UrlBuilder.jobEducations}`, data);
    },
    deleteJobEducations(data) {
        return interceptor.delete(`${UrlBuilder.jobEducations}`, { data: data });
    },
    getJobOrderServiceProfile(id) {
        return interceptor.get(`${UrlBuilder.jobOrderServiceProfile}?job_id=${id}`);
    },
    saveJobOrderServiceProfile(data) {
        return interceptor.patch(`${UrlBuilder.jobOrderServiceProfile}`, data);
    },
    getJobShifts(id) {
        return interceptor.get(`${UrlBuilder.jobShifts}?job_id=${id}`);
    },
    saveJobShifts(data) {
        if (data.id !== null)
            return interceptor.patch(`${UrlBuilder.jobShifts}`, data);
        else
            return interceptor.post(`${UrlBuilder.jobShifts}`, data);
    },
    deleteJobShifts(id) {
        return interceptor.delete(`${UrlBuilder.jobShifts}?job_shift_id=${id}`);
    },

    getJobOrderSuccessProfile(id) {
        return interceptor.get(`${UrlBuilder.jobOrderSuccessProfile}?job_id=${id}`);
    },

    saveJobOrderSuccessProfile(data) {
        return interceptor.patch(`${UrlBuilder.jobOrderSuccessProfile}`, data);
    },

    getJobTalentMatchesP(id, data) {
        return interceptor.post(`${UrlBuilder.talentMatches}?job_order_id=${id}`, data)
    },
    getJobCompanyMatchesP(id, data) {
        return interceptor.post(`${UrlBuilder.companyMatches}?job_order_id=${id}`, data);
    },

    getJobDocumentsAPI(jobOrderId) {
        return interceptor.get(`${UrlBuilder.jobDocuments}?job_order_id=${jobOrderId}`);
    },

    createJobDocumentVideo(data) {
        return interceptor.post(`${UrlBuilder.jobDocumentsUploadVideo}`, data);
    },
    updateJobDocumentUploadDocument(data) {
        return interceptor.patch(`${UrlBuilder.jobDocuments}`, data);
    },
    deleteJobDocumentUploadDocument(file_id: string) {
        return interceptor.delete(`${UrlBuilder.jobDocuments}?job_document_id=${file_id}`);
    },

    CreateDocVidDownloadLinkByEmail(data: SendByEmail) {
        return interceptor.post(`${UrlBuilder.jobDocSendByEmail}?file_id=${data.file_id}&email_id=${data.email_id}`);
      },

    createJobDocument(data) {
        return interceptor.post(`${UrlBuilder.jobDocuments}`, data);
    },


    // jop posting get description from chatgpt
    getjobpPostChatGPTQuery(jobOrderId) {
        return interceptor.post(`${UrlBuilder.jopPostGetChatGPTQuery}?job_id=${jobOrderId}`);
    },

    getJobPosts(jobOrderId) {
        return interceptor.get(`${UrlBuilder.jobPosts}?job_id=${jobOrderId}`);
    },
    postJobPosts(data) {
        return interceptor.post(`${UrlBuilder.jobPosts}`,data);
    },
    patchJobPosts(data:IJobPost) {
        return interceptor.patch(`${UrlBuilder.jobPosts}`,data);
    },
    deleteJobPosts(data) {
        return interceptor.delete(`${UrlBuilder.jobPosts}?job_post_id=${data.job_post_id}&job_id=${data.job_id}`);
    },
    postJobPostToGoogle(data) {
        return interceptor.post(`${UrlBuilder.jobPostsToGoogle}`,data);
    },

    getAllJobTalentClientInterviews(job_id: string) {
        return interceptor.get(`${UrlBuilder.talentClientInterviewsGetAll}?job_order_id=${job_id}`);
    },

    postTalentClientInterview(doc: ICreateTalentClientCompanyInterview) {
        return interceptor.post(UrlBuilder.talentClientInterviews, doc);
    },

    patchTalentClientInterview(doc: ITalentClientCompanyInterview) {
        return interceptor.patch(`${UrlBuilder.talentClientInterviews}?talent_client_interview_id=${doc.id}`, doc);
    },

    // gross profit calculator

    getGrossProfitCalculatorDetails() {
        return interceptor.get(UrlBuilder.grossProfitCalculatorTax);
    },
    createGrossProfitCalculatorDetails(doc: ICreateGrossProfitCalculator) {
        return interceptor.post(UrlBuilder.grossProfitCalculator, doc);
    },

}
