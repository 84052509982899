import { useEffect, useState } from 'react';
import EditIcon from '../../../../assets/icons/edit_button.svg';
import DeleteIcon from '../../../../assets/icons/delete-icon.svg';
import { LoadingType, THEME } from "../../../enums";
import { CustomButton, getPlusBtnIcon } from "../../../utils";
import { useTranslation } from "react-i18next";
import TabOptionsHeader from '../../../components/tab_options_header/tab_options_header';
import SortableTable, { TableData } from '../../../components/sortable_table/sortable_table';
import {
    selectSuccessProfileQuestionListState, selectSuccessProfileQuestionList,
    selectSuccessProfileQuestionStepState, selectSuccessProfileQuestionStepList,
    createJobOrderPlacement, updateJobOrderPlacement, getJobOrderPlacements, deleteJobOrderPlacement,
    selectJobOrderPlacementState, selectJobOrderPlacementList,
    createJobOrderSource, updateJobOrderSource, getJobOrderSources, deleteJobOrderSource,
    selectJobOrderSourceState, selectJobOrderSourceList,
} from "../../../redux/admin_center";
import { postSnackbarMessage, useAppDispatch, useAppSelector } from "../../../redux/store";
import './system_wide_page.scss';
import { Portal } from "react-portal";
import '../../../custom.scss';
import TableEmpty, { TableErrorHandler, TableLoading } from "../../../components/table_empty/table_empty";
import './jobs_page.scss'
import CustomFormSelect from '../../../components/form_selector/form_select';
import { jobOrderPlacementActions } from '../../../redux/admin_center/system_configuration/job_order_placement/job_order_placement_reducer';
import { jobOrderSourceActions } from '../../../redux/admin_center/system_configuration/job_order_source/job_order_source_reducer';
import { DeleteConfirmationDialog } from '../../components/delete_confirmation_dialog';
import SCJobsPopup from './popups/jobs_popup/jobs_popup';


const tableHeader = {
    successProfileQuestionStep: [
        { title: 'step_number', code: 'step_number' },
        { title: 'step', code: 'value' },
        { title: 'actions', code: 'actions' },
    ],
    successProfileQuestion: [
        { title: 'question_number', code: 'step_number' },
        { title: 'question', code: 'value' },
        { title: 'actions', code: 'actions' },
    ],
    jobOrderPlacement: [
        { title: 'type', code: 'actions' },
        { title: 'description', code: 'actions' },
    ],
    jobOrderSource: [
        { title: 'source', code: 'actions' },
        { title: 'description', code: 'actions' },
        { title: 'actions', code: 'actions' },
    ],
    workCompCode: [
        { title: 'code', code: 'actions' },
        { title: 'description', code: 'actions' },
        { title: 'actions', code: 'actions' },
    ]
}

const categories = {
    3: {
        label: "job_order_placement_types",
        value: ["title", "description"],
        buttonLabel: "add_job_order_placement_type",
        inputLabel: "type",
        inputLabel2: "description",
        deleteLabel: "delete_job_order_placement_type",
        deleteMessage: "delete_job_order_placement_type_message",
        updateLabel: "update_job_order_placement_type",
        methods: {
            create: createJobOrderPlacement,
            update: updateJobOrderPlacement,
            remove: deleteJobOrderPlacement,
            get: getJobOrderPlacements
        },
        headers: tableHeader.jobOrderPlacement,
        clearCreateState: jobOrderPlacementActions.clearJobOrderPlacementCreateState,
        clearUpdateState: jobOrderPlacementActions.clearJobOrderPlacementUpdateState,
        clearDeleteState: jobOrderPlacementActions.clearJobOrderPlacementDeleteState,
        clearDeleteStateError: jobOrderPlacementActions.clearJobOrderPlacementDeleteStateError,
    },
    4: {
        label: "job_order_sources",
        value: ["job_order_source", "description"],
        buttonLabel: "add_job_order_source",
        inputLabel: "source",
        inputLabel2: "description",
        deleteLabel: "delete_job_order_source",
        deleteMessage: "delete_job_order_source_message",
        updateLabel: "update_job_order_source",
        methods: {
            create: createJobOrderSource,
            update: updateJobOrderSource,
            remove: deleteJobOrderSource,
            get: getJobOrderSources
        },
        headers: tableHeader.jobOrderSource,
        clearCreateState: jobOrderSourceActions.clearJobOrderSourceCreateState,
        clearUpdateState: jobOrderSourceActions.clearJobOrderSourceUpdateState,
        clearDeleteState: jobOrderSourceActions.clearJobOrderSourceDeleteState,
        clearDeleteStateError: jobOrderSourceActions.clearJobOrderSourceDeleteStateError,
    },
}

const JobsPage = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<{visible: boolean, title: string} | null>(null);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [methodValue, setMethodValue] = useState({
        id: '', value: '', title: '', job_order_source: '',
        sequence_number: 0, description: ''
    });
    const [selectedCategory, setSelectedCategory] = useState(3);
    const [dropdownChanged, setDropDownChanged] = useState(true);
    const [stepNumber, setStepNumber] = useState('');

    const successProfileQuestionStepState = useAppSelector((state) => selectSuccessProfileQuestionStepState(state));
    const successProfileQuestionStepList = useAppSelector((state) => selectSuccessProfileQuestionStepList(state));
    const successProfileQuestionState = useAppSelector((state) => selectSuccessProfileQuestionListState(state));
    const successProfileQuestionList = useAppSelector((state) => selectSuccessProfileQuestionList(state));
    const jobOrderPlacementTypeState = useAppSelector((state) => selectJobOrderPlacementState(state));
    const jobOrderPlacementTypeList = useAppSelector((state) => selectJobOrderPlacementList(state));
    const jobOrderSourceState = useAppSelector((state) => selectJobOrderSourceState(state));
    const jobOrderSourceList = useAppSelector((state) => selectJobOrderSourceList(state));

    const reduxMapping = {
        1: { state: successProfileQuestionStepState, list: successProfileQuestionStepList },
        2: { state: successProfileQuestionState, list: successProfileQuestionList },
        3: { state: jobOrderPlacementTypeState, list: jobOrderPlacementTypeList },
        4: { state: jobOrderSourceState, list: jobOrderSourceList },
    }

    useEffect(() => {
        if (getMapping()?.state?.create?.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(getMapping()?.state?.create?.response));
            dispatch(getCategory()?.clearCreateState())
        }
        else if (getMapping()?.state?.update?.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(getMapping()?.state?.update?.response));
            dispatch(getCategory()?.clearUpdateState())
        }
        else if (getMapping()?.state?.remove?.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(getMapping()?.state?.remove?.response));
            dispatch(getCategory()?.clearDeleteState())
        }
    }, [
        getMapping()?.state?.create?.loading, 
        getMapping()?.state?.update?.loading, 
        getMapping()?.state?.remove?.loading
    ]);

    function getCategory(id = null) {
        if (id)
            return categories[id];
        return categories[selectedCategory];
    }

    function getMapping(id = null) {
        if (id)
            return reduxMapping[id];
        return reduxMapping[selectedCategory];
    }

    function handleCreateCloseDialog() {
        setShowCreateDialog(false);
    }

    function handleCloseDeleteDialog() {
        setShowDeleteDialog(null);
        setDeleteItemId(null);
    }

    function handleShowDeleteDialog(id, title) {
        setShowDeleteDialog({visible: true, title: title});
        setDeleteItemId(id);
    }

    function handleDeleteApprove() {
        if (deleteItemId) {
            dispatch(getCategory().methods.remove(deleteItemId));
        }
        handleCloseDeleteDialog();
    }

    function handleShowEditDialog(method) {
        setShowEditDialog(true);
        if (selectedCategory === 4) {
            setMethodValue({
                ...methodValue, id: method.id,
                title: method[getCategory().value[0]],
                job_order_source: method[getCategory().value[0]],
                description: method[getCategory().value[1]],
                sequence_number: method.sequence_number,
            });
        }
    }

    function handleCloseEditDialog() {
        setShowEditDialog(false);
    }

    function handleDropdownChange(value) {
        if (!value)
            value = '1';
        setDropDownChanged(true);
        setSelectedCategory(Number(value));
        dispatch(getCategory(value).methods.get());
    }

    function handleStepNumberChange(value) {
        if (!value) {
            if (successProfileQuestionStepList.length > 0)
                value = successProfileQuestionStepList[0].id;
        }
        setStepNumber(value);
    }

    function retryFetchingData() {
        setDropDownChanged(true);
        dispatch(getCategory().methods.get());
    }

    useEffect(() => {
        if (getMapping().state.loading == LoadingType.succeeded) {
            setDropDownChanged(false);
            if (selectedCategory == 1)
                setStepNumber(successProfileQuestionStepList.length > 0 ? successProfileQuestionStepList[0].id : '')
        }
    }, [getMapping().state.loading])

    useEffect(() => {
        if (getMapping().state.remove.loading == LoadingType.succeeded)
            dispatch(getCategory().methods.get());
    }, [getMapping().state.remove.loading])

    useEffect(() => {
        dispatch(getCategory().methods.get());
        return () => {

        }
    }, [])

    return (
        <div className="ac-sc-container">
            <div className="ac-sc-header">
                <div className="ac-sytm-cns-header">
                    <TabOptionsHeader onClose={() => props.history.goBack()} />
                </div>
            </div>
            <div className="ac-sc-dropdown">
                <div className="ac-sc-dropdown-button">
                    <div style={{ display: "flex", flexDirection: "row", width: "-webkit-fill-available" }}>
                        <CustomFormSelect
                            name={t("jobs_dropdown")}
                            list={Object.keys(categories).map(id => ({ value: id, label: categories[id].label }))}
                            onChange={(val) => handleDropdownChange(val)}
                            value={selectedCategory}
                            placeholder={t('job_order_placement_types')}
                            required={false}
                            label={t('please_select_jobs_category')}
                            customStyle={{ marginTop: "0 !important", maxWidth: "20vw", marginRight: "10px !important" }}
                        />
                        {selectedCategory == 2 ?
                            <CustomFormSelect
                                name={t("jobs_dropdown")}
                                list={
                                    successProfileQuestionStepList.length != 0 ?
                                        successProfileQuestionStepList.map(id => ({ value: id.id, label: `${id.step_number} - ${id.value}` }))
                                        : []
                                }
                                onChange={(val) => handleStepNumberChange(val)}
                                value={stepNumber}
                                placeholder={
                                    successProfileQuestionStepList.length != 0 ? `${successProfileQuestionStepList[0].step_number} - ${successProfileQuestionStepList[0].value}` : 'No Steps Available'}
                                required={false}
                                label={t('step')}
                                customStyle={{ marginTop: "0 !important", maxWidth: "20vw" }}
                            /> :
                            <></>
                        }
                    </div>
                    {
                        selectedCategory === 1 || selectedCategory == 2 ? null :
                            <div>
                                <CustomButton
                                    textStyle={{ textTransform: 'capitalize' }}
                                    enable={selectedCategory != 2 || successProfileQuestionStepList.length != 0 ? true : false}
                                    leftIcon={getPlusBtnIcon()}
                                    name={t(categories[selectedCategory].buttonLabel)}
                                    backgroundColor={THEME.buttonColor1}
                                    onClick={() => (setShowCreateDialog(true))}
                                    customStyle={{ width: "max-content" }}
                                />
                            </div>
                    }
                </div>
            </div>
            <div className="ac-sc-table ac-sc-jobs-table">
                {getMapping().state.loading == LoadingType.pending && dropdownChanged ? <TableLoading /> :
                    <TableBody
                        state={getMapping().state}
                        onRetry={retryFetchingData}
                        data={selectedCategory == 2 && stepNumber ? getMapping().list.filter((d) => (d.step_id == stepNumber)) : getMapping().list}
                        category={getCategory()}
                        handleShowEdit={handleShowEditDialog}
                        handleShowDelete={handleShowDeleteDialog}
                    />
                }
            </div>
            {showCreateDialog && <Portal>
                <SCJobsPopup
                    visible={showCreateDialog}
                    onClose={handleCreateCloseDialog}
                    onDismiss={handleCreateCloseDialog}
                    category={getCategory()}
                    category_id={selectedCategory}
                    reduxMapping={reduxMapping}
                    errorState={reduxMapping[selectedCategory]?.update?.error}
                />
            </Portal>}
            {showDeleteDialog && (
                <Portal>
                    <DeleteConfirmationDialog
                        message={t(getCategory()?.deleteMessage, { value: showDeleteDialog?.title })}
                        onCancel={() => setShowDeleteDialog(null)}
                        onConfirm={handleDeleteApprove}
                        deleting={getMapping()?.state?.remove === LoadingType.pending}
                        deleteType={""}
                        customDeleteHeaderTitle={t(getCategory()?.deleteLabel)}
                        state={getMapping()?.state}
                        onClearError={getCategory()?.clearDeleteStateError()}
                    />
                </Portal>
            )}
            {showEditDialog && <Portal>
                <SCJobsPopup
                    visible={showEditDialog}
                    onClose={handleCloseEditDialog}
                    onDismiss={handleCloseEditDialog}
                    methodValue={methodValue}
                    category={getCategory()}
                    category_id={selectedCategory}
                    reduxMapping={reduxMapping}
                    errorState={reduxMapping[selectedCategory]?.update?.error}
                />
            </Portal>}
        </div>

    )
}

const TableBody = ({ state, onRetry, data, category, handleShowEdit, handleShowDelete }) => {
    if (state.loading == LoadingType.failed) {
        return (<TableErrorHandler error={state.error} onRefresh={onRetry} />)
    } else if (data.length == 0) {
        return (<TableEmpty title={"No data found"} onClick={onRetry} />)
    } else {
        return (
            <SortableTable headerList={category.headers}>
                {data.map((doc, index) => {
                    return (
                        <tr key={doc.id}>
                            {category.value.map((value, index) => {
                                return (
                                    <TableData key={doc.id + index} customStyle={{ wordWrap: "break-word" }}>
                                        <span>{doc[value]}</span>
                                    </TableData>
                                )
                            })}
                            {category.label === 'job_order_placement_types' ? null :
                                <TableData>
                                    {
                                        <><img src={EditIcon} alt='edit_button' style={{ marginRight: '20px' }}
                                            onClick={() => handleShowEdit(doc)} /><img src={DeleteIcon} alt='delete_button'
                                                onClick={() => handleShowDelete(doc.id, doc[category.value[0]])} /></>
                                    }
                                </TableData>}
                        </tr>
                    );
                })}
            </SortableTable>
        )
    }
}

export default JobsPage;