import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { RouteComponentProps } from "react-router-dom";
import StateChangeHandler from "../../../../../components/state_change_handler/state_change_handler";
import JobDetailsSection from "../../../../../components/job_details_section/job_details_section";
import CurveTabNavigation from "../../../../../components/curve_tab_navigation/curve_tab_navigation";
import {
  BriefcaseIcon,
  EditIcon,
  EngagementIcon,
  RatingIcon,
  SkillsSummaryIcon,
  StaffIcon,
  EmailBlueIcon,
  CallBlueIcon,
} from "../../../../../icons";
import {
  IJobCompanyMatches,
  IJobOrderDetail,
  IJobTalentMatches,
  ITalent,
} from "../../../../../interfaces";
import {
  useAppDispatch,
  useAppSelector,
  selectProfileState,
  selectJobDetailsByIdState,
  getJobById,
  getTEADraftBroadCast,
  selectTextEmAllState,
} from "../../../../../redux/store";
import { AppRoutes } from "../../../../../routes";
import "./jobs_dashboard.scss";
import JobSkillSummary from "../../job-skills/job-skill-summary";
import JobDetailsPage from "../../job-order-details/job-details";
import CreateAssignmentPopup from "../../../popups/create_assignment/create_assignemt_popup";
import MatchHolder from "../../matches";
import { LoadingType, THEME } from "../../../../../enums";
import TableEmpty, {
  TableLoading,
  TableErrorHandler,
  TableFilterEmpty,
} from "../../../../../components/table_empty/table_empty";
import SortableTable, {
  TableData,
} from "../../../../../components/sortable_table/sortable_table";
import JobOrderStaffDetails from "../../job-order-details/job-staffing-details";
import EngagementDetails from "../../../../components/engagementDetails";
import RatingsTab from "../../../../components/ratingsDetails";
import { getTrafficLightEngagementMetrics } from "../../../../../redux/admin_center/system_configuration/traffic_light_engagement_metric/traffic_light_engagement_metric_thunk";
import { selectTrafficLightEngagementMetricList } from "../../../../../redux/admin_center";
import { NotificationCircle } from "../../../../../components/notification_circle/notification_circle";
import {
  getContactTypeIcon,
  getRatingBackgrounColor,
} from "../../../../../utils/helpers";
import { manageJobActions } from "../../../../../redux/job_order/universal_directory/manage_job/manage_job_reducer";
import { jobDetailsSliceActions } from "../../../../../redux/job_order/details/dashboard/job_details/job_details_reducer";
import { CreateJobOptionPopup } from "../../../popups/create_option/create_option";
import CreateJobPopup from "../../../popups/create_job/create_job";
import { getDaysAgoWithDateAMPM, payFeePlacementType } from "../../../../../variables";
import { TEADraftBroadCast } from "../../../../../interfaces/text_em_all";
import { CustomCheckBox } from "../../../../../utils";
import BroadcastToTalentPopup from "../../../../../components/broadcast_talent/broadcast_talent";
import { textEmAllSliceActions } from "../../../../../redux/textEmAll/text_Em_All_reducer";
import BroadcastToTalentErrorPopup from "../../../../../components/broadcast_talent/broadcast_talent_error";
import GrossProfitCalculatorPopup from "../../../popups/gross_profit_calculator/gross_profit_calculator_popup";
import { RemoveOptionPopup } from "../../../../components/remove_option/remove_option";

const companyMatchesTableHeader = [
  { title: "company_name", code: "company_name" },
  { title: "skills_and_certifications", code: "matched_skills" },
  { title: "engagement_rating", code: "engagement_rating" },
];

interface Props extends RouteComponentProps<any> { }
const JobsDashboardPage: React.FunctionComponent<Props> = (props) => {
  const { history, location, match } = props;
  const { params } = match;
  const dispatch = useAppDispatch();
  const profile = useAppSelector((state) => selectProfileState(state));

  const textEmAllState = useAppSelector((state) => selectTextEmAllState(state));

  const canSendText = profile?.profile?.texting && profile?.profile?.text_em_all_token !== null;
  const jobState = useAppSelector((state) =>
    selectJobDetailsByIdState(params.id)(state)
  );
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [branchId, setBranchId] = useState<string | null>(null);
  const [showAssignmentPopUp, setShowAssignmentPopUp] = useState(false);
  const [showGPCPopup, setShowGPCPopup] = useState(false);
 

  const trafficeMetrics = useAppSelector((state) =>
    selectTrafficLightEngagementMetricList(state)
  );
  const [addOption, setAddOption] = useState<{
    visible: boolean;
    type: string;
  } | null>(null);
  const [removeOption, setRemoveOption] = useState<{
    visible: boolean;
    type: string;
    value: { id: string; value: string };
  } | null>(null);
  const [editJob, setEditJob] = useState<{
    visible: boolean;
    data: IJobOrderDetail;
  } | null>(null);

  useEffect(() => {
    getJobDetails();
    return () => { };
  }, [params.id]);

  function getJobDetails() {
    if (params && params.id != null) {
      setBranchId(null);
      dispatch(getJobById({ job_id: params.id }));
      dispatch(getTrafficLightEngagementMetrics());
    }
  }

  function handleBranchChange(value: string) {
    if (value != "") {
      history.push({
        pathname: `${location.pathname}/${value}`,
        state: {
          ...(location.state as any),
          departmentId: value,
          departmentName: value,
        },
      });
    } else {
      history.push({
        pathname: `${AppRoutes.companiesDetailsPage}/${params.id}/dashboard`,
        state: {
          ...(location.state as any),
          departmentId: null,
          departmentName: null,
        },
      });
    }
  }

  /// close add option dialog
  function handleCloseAddDialog() {
    setAddOption(null);
  }

  /// Add on success add option
  function handleOnSuccessAddDialog() {
    if (addOption) {
      dispatch(
        jobDetailsSliceActions.clearJobDetailsAddOptionError({
          id: params.id,
          type: addOption?.type,
        })
      );
      dispatch(manageJobActions.clearManageJobState([]));
      setAddOption(null);
      getJobDetails();
    }
  }

  /// close add option dialog
  function handleCloseRemoveDialog() {
    setRemoveOption(null);
  }

  /// Add on success remove option
  function handleOnSuccessRemoveDialog() {
    if (removeOption) {
      dispatch(
        jobDetailsSliceActions.clearJobDetailsRemoveOptionError({
          id: params.id,
          type: removeOption?.type,
        })
      );
      setRemoveOption(null);
      getJobDetails();
    }
  }

  function getEditAction() {
    return (
      <button className="icon-btn jdp-edit-icon" onClick={handleEdit}>
        <EditIcon width={"100%"} height={"100%"} style={{ color: "#fff" }} />
      </button>
    );
  }

  function getTabList() {
    return [
      {
        title: t("skill_summary").toUpperCase(),
        icon: SkillsSummaryIcon,
        content: (
          <JobSkillSummary
            job_id={params.id}
            onAddOption={handleAddOption}
            onRemoveOption={handleRemoveOption}
            customStyle={{
              overflow: "auto",
              height: "20.3125vw",
            }}
          />
        ),
      },
      {
        title: t("engagement_details").toUpperCase(),
        icon: EngagementIcon,
        content: (
          <EngagementDetails
            id={params.id}
            source={"JOBS"}
            onSuccessCreate={getJobDetails}
            customStyle={{
              overflow: "auto",
              height: "20.3125vw",
            }}
          />
        ),
      },
      {
        title: t("job_order_details").toUpperCase(),
        icon: BriefcaseIcon,
        content: (
          <JobDetailsPage
            job_id={params.id}
            customStyle={{
              // overflow: "auto",
              height: "25.3125vw",
            }}
          />
        ),
      },
      {
        title: t("ratings").toUpperCase(),
        icon: RatingIcon,
        content: (
          <RatingsTab
            id={params.id}
            source={"job"}
            customStyle={{
              overflow: "auto",
              height: "20.3125vw",
            }}
          />
        ),
      },
      {
        title: t("staffing_representatives", {
          value: profile.profile?.agency_name,
        }).toUpperCase(),
        icon: StaffIcon,
        content: (
          <JobOrderStaffDetails
            job_id={params.id}
            onAddOption={handleAddOption}
            onRemoveOption={handleRemoveOption}
            customStyle={{
              overflow: "auto",
              height: "20.3125vw",
            }}
          />
        ),
      },
    ];
  }

  function handleEdit() {
    if (jobState.data) setEditJob({ visible: true, data: jobState.data });
  }

  ///Add user action
  function handleUpdateJobSuccess() {
    setEditJob(null);
    getJobDetails();
    dispatch(manageJobActions.clearUpdateJobState());
  }

  /// JOB Matches
  const [jobSearch, setJobSearch] = useState<string>("");
  const [jobSortedField, setJobSortedField] = useState<string | null>(null);
  function handleJobSortFieldChange(value: string) {
    if (jobSortedField && jobSortedField === value) {
      setJobSortedField(null);
    } else {
      setJobSortedField(value);
    }
  }

  /// JOB Matches table
  function getJobMatchesTable() {
    const state = jobState.talentMatches;
    if (state.loading === LoadingType.pending) {
      return <TableLoading />;
    }
    if (state.error != null && state.data?.length === 0) {
      return (
        <TableErrorHandler error={state.error} onRefresh={getJobDetails} />
      );
    }
    if (state.data != null && state.data.length === 0) {
      return (
        <TableEmpty
          title={t("no_talent_matches_found")}
          onClick={getJobDetails}
        />
      );
    }
    if (getJobFilteredList().length === 0) {
      return <TableFilterEmpty title={t("no_talent_matches_found")} />;
    }

    function getPrefferedContact(value: string) {
      return (
        <div className="pref-contact-icon">
          {getContactTypeIcon(value, "#00A4DC")}
        </div>
      );
    }
    const SelectAllCheckBox = () => {
      return (
        <CustomCheckBox
          name={""}
          title={""}
          checked={selectedItems.size === getJobFilteredList().length}
          onClick={handleMarkAllItem}
        />
      );
    };
    const talentMatchesTableHeader = [
      { title: "", code: "", sort: false, children: <SelectAllCheckBox /> },
      { title: "best_match", code: "talent_first_name" },
      { title: "skills_and_certifications", code: "matched_skills" },
      { title: "contact", code: "preferred_contact_method" },
      { title: "engagement_rating", code: "engagement_rating" },
      { title: "date_of_last_engagement", code: "date_of_last_engagement" },
    ];

    function getFlexNumber(value: number) {
      if (value === 0) return 0.3;
      return 1;
    }
    return (
      <SortableTable
        headerList={talentMatchesTableHeader}
        sortedField={jobSortedField}
        onSortChange={handleJobSortFieldChange}
        flexNumber={getFlexNumber}
      >
        {getJobFilteredList().map((doc, index) => {
          return (
            <tr key={doc.talent_id} onClick={() => { }}>
              <TableData customStyle={{ flex: getFlexNumber(0) }}>
                <CustomCheckBox
                  name={""}
                  title={""}
                  checked={selectedItems.has(doc.talent_id)}
                  onClick={() => handleMarkItem(doc)}
                />
              </TableData>
              <TableData
                isButton={true}
                onClick={() => handleTalentSelect(doc)}
                customStyle={{ flex: getFlexNumber(1) }}
              >
                <span>{`${doc.talent_first_name ?? ""} ${doc.talent_middle_name ?? ""
                  } ${doc.talent_last_name ?? ""}`}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(2) }}>
                {getJobMatchedSkillsText(doc.matched_skills)}
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(3) }}>
                <span>{getPrefferedContact(doc.preferred_contact_method)}</span>
              </TableData>
              <TableData align="center" customStyle={{ flex: getFlexNumber(4) }}>
                {bindRating(doc.engagement_rating, "TALENT")}
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(5) }}>
                <span>
                  {doc.last_engagement_date
                    ? getDaysAgoWithDateAMPM(t, doc.last_engagement_date)
                    : ""}
                </span>
              </TableData>
            </tr>
          );
        })}
      </SortableTable>
    );
  }
  function getJobFilteredList(): IJobTalentMatches[] {
    let sortedList: IJobTalentMatches[] | undefined;
    if (jobSortedField != null) {
      sortedList = [...(jobState.talentMatches.data ?? [])].sort((a, b) => {
        if (a[jobSortedField] < b[jobSortedField]) {
          return -1;
        }
        if (a[jobSortedField] > b[jobSortedField]) {
          return 1;
        }
        return 0;
      });
    }
    return (sortedList ?? jobState.talentMatches.data ?? []).filter((doc) => {
      const str = jobSearch.trim().toLowerCase();
      const talentFirstName = doc.talent_first_name
        ? doc.talent_first_name.trim().toLowerCase().includes(str)
        : false;
      const talentLastName = doc.talent_last_name
        ? doc.talent_last_name.trim().toLowerCase().includes(str)
        : false;
      const skillsFilter = doc.matched_skills
        ? doc.matched_skills.includes(str)
        : false;
      return talentFirstName || talentLastName || skillsFilter;
    });
  }
  function getJobMatchedSkillsText(value: string[]) {
    let skills = "";
    value.map((doc, index) => {
      skills = skills + (skills.length > 0 ? ", " : "") + doc;
    });
    return (
      <span title={skills}>
        {skills.length > 17 ? skills.substring(0, 16) + "..." : skills}
      </span>
    );
  }

  /// Company Matches
  const [companySearch, setCompanySearch] = useState<string>("");
  const [companySortedField, setCompanySortedField] = useState<string | null>(
    null
  );
  function handleCompanySortFieldChange(value: string) {
    if (companySortedField && companySortedField === value) {
      setCompanySortedField(null);
    } else {
      setCompanySortedField(value);
    }
  }

  function bindRating(rating: number, source: string) {
    const ratingtype: any = trafficeMetrics.filter(
      (h: any) =>
        rating >= h.range_from &&
        rating <= (h.range_to === null ? rating : h.range_to) &&
        h.category === source
    );
    return (
      <div style={{ textAlign: "center" }}>
        <NotificationCircle
          value={rating}
          onClick={() => { }}
          customStyle={{
            backgroundColor: getRatingBackgrounColor(
              ratingtype.length > 0 ? ratingtype[0].type : "LOW"
            ),
            width: "1.756vw",
            height: "1.756vw",
          }}
          textStyle={{ color: THEME.white, fontSize: "0.878vw" }}
        />
      </div>
    );
  }
  /// [Company] Matches
  function getCompanyMatchesTable() {
    const state = jobState.companyMatches;
    if (state.loading === LoadingType.pending) {
      return <TableLoading />;
    }
    if (state.error != null && state.data?.length === 0) {
      return (
        <TableErrorHandler error={state.error} onRefresh={getJobDetails} />
      );
    }
    if (state.data != null && state.data.length === 0) {
      return (
        <TableEmpty
          title={t("no_company_matches_found")}
          onClick={getJobDetails}
        />
      );
    }
    if (getCompanyFilteredList().length === 0) {
      return <TableFilterEmpty title={t("no_company_matches_found")} />;
    }

    return (
      <SortableTable
        headerList={companyMatchesTableHeader}
        sortedField={companySortedField}
        onSortChange={handleCompanySortFieldChange}
      >
        {getCompanyFilteredList().map((doc, index) => {
          return (
            <tr key={doc.company_id} onClick={() => { }}>
              <TableData
                isButton={doc.company_name ? true : false}
                onClick={() =>
                  handleCompanySelect(doc.company_id, doc.company_name)
                }
              >
                <span>{doc.company_name}</span>
              </TableData>
              <TableData>
                {getJobMatchedSkillsText(doc.matched_skills)}
              </TableData>
              <TableData align="center">
                {bindRating(doc.engagement_rating, "COMPANY")}
              </TableData>
            </tr>
          );
        })}
      </SortableTable>
    );
  }

  function getCompanyFilteredList(): IJobCompanyMatches[] {
    let sortedList: IJobCompanyMatches[] | undefined;
    if (companySortedField != null) {
      sortedList = [...(jobState.companyMatches.data ?? [])].sort((a, b) => {
        if (a[companySortedField] < b[companySortedField]) {
          return -1;
        }
        if (a[companySortedField] > b[companySortedField]) {
          return 1;
        }
        return 0;
      });
    }
    return (sortedList ?? jobState.companyMatches.data ?? []).filter((doc) => {
      const str = companySearch.trim().toLowerCase();
      const comapnyNameFilter = doc.company_name
        ? doc.company_name.trim().toLowerCase().includes(str)
        : false;
      const skillsFilter = doc.matched_skills
        ? doc.matched_skills.includes(str)
        : false;
      return comapnyNameFilter || skillsFilter;
    });
  }
  const [broadcastTalent, setBroadcastTalent] = useState<boolean>(false);
  const [broadcastTalentError, setBroadcastTalentError] =
    useState<boolean>(false);
  useEffect(() => {
    dispatch(textEmAllSliceActions.clearState());
    return () => { };
  }, []);
  useEffect(() => {
    if (
      textEmAllState &&
      textEmAllState?.error !== null &&
      textEmAllState?.loading === LoadingType.failed
    ) {
      setBroadcastTalentError(true);
    }
    if (
      textEmAllState &&
      textEmAllState?.error === null &&
      textEmAllState?.loading === LoadingType.succeeded
    ) {
      setBroadcastTalent(true);
    }
    if (
      textEmAllState &&
      textEmAllState?.error === null &&
      textEmAllState?.loading === LoadingType.idle
    ) {
      setBroadcastTalent(false);
      setBroadcastTalentError(false);

    }
  }, [textEmAllState, textEmAllState.error]);
  const [formState] = useState<TEADraftBroadCast>({
    modified_by: "",
    created_by: "",
    modified_date: 0,
    talent_ids: [],
  });
  const [showMsg, setShowMsg] = useState<boolean>(false);
  const [showErrorMsg, setShowErrorMsg] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<
    Map<string, { id: string; name: string }>
  >(new Map());

  const handleMarkItem = (value: IJobTalentMatches) => {
    const newSet = new Map(selectedItems);
    if (selectedItems.has(value.talent_id)) {
      newSet.delete(value.talent_id);
    } else {
      newSet.set(value.talent_id, {
        id: value.talent_id,
        name: value.talent_first_name + " " + value.talent_last_name,
      });
    }
    setSelectedItems(newSet);
  };
  const handleMarkAllItem = () => {
    const newSet = new Map<string, { id: string; name: string }>();
    if (selectedItems.size === getJobFilteredList().length) {
      setSelectedItems(newSet);
    } else {
      getJobFilteredList().forEach((doc) =>
        newSet.set(doc.talent_id, {
          id: doc.talent_id,
          name: doc.talent_first_name + " " + doc.talent_last_name,
        })
      );
      setSelectedItems(newSet);
    }
  };
  const handleBroadCastTalent = (forceSend?: boolean) => {

    if (selectedItems.size !== 0) {
      const talentids_list = Array.from(selectedItems.keys());
      dispatch(
        getTEADraftBroadCast({ forceSend: forceSend, data: { ...formState, talent_ids: talentids_list } })
      );
      // setBroadcastTalent(true);
      setShowMsg(false);
    } else {
      setShowMsg(true);
      setShowErrorMsg(t("please_select_talent_to_broadcast"));
      setTimeout(() => setShowErrorMsg(""), 5000);
    }
  };

  const handleClearRemoveOptionError = () => {
    dispatch(jobDetailsSliceActions.clearJobDetailsRemoveOptionError({ id: params.id, type: removeOption?.type! }));
  };

  return (
    <StateChangeHandler
      loading={jobState.loading}
      error={jobState.error}
      onRefresh={getJobDetails}
    >
      <div className="jdp-container">
        <div className="jdp-left-panel">
          <JobDetailsSection
            branchId={branchId}
            onBranchChange={handleBranchChange}
            onActionClick={(value) => {
              if (value === "assign_talent_to_job_order") {
                setShowAssignmentPopUp(true);
              }
              if (value === "gross_profit_calculator") {
                setShowGPCPopup(true);
              }
              if (value === "access_success_profile") {
                history.push({
                  pathname: `${AppRoutes.jobDetailsPage}/${params.id}/success-profile-job-posting`,
                  state: {
                    ...(location.state as any),
                    departmentId: null,
                    departmentName: null,
                  },
                });
              }
            }}
            jobOrder={jobState.data}
            handleOnClickFiled={handleOnNavClicks}
          />
        </div>
        <div className="jdp-right-panel">
          <CurveTabNavigation
            tabs={getTabList()}
            selected={tabIndex}
            onChange={(value) => setTabIndex(value)}
            actions={getEditAction()}
            customStyle={{ height: "fit-content" }}
          />
          <MatchHolder
            title={t("talent_matches").toUpperCase()}
            searchPlaceHolder={t("search_for_more_talent_matches")}
            search={jobSearch}
            onSearchChange={setJobSearch}
            onSearch={() => { }}
            showBroadCastBtn={true}
            handleBroadCastTalent={handleBroadCastTalent}
            enableBtn={canSendText ? true : false}
            showMsg={showMsg}
            showErrorMsg={showErrorMsg}
          >
            {""}
          </MatchHolder>
          <div className="jdp-job-match">{getJobMatchesTable()}</div>
          <MatchHolder
            title={t("company_matches").toUpperCase()}
            searchPlaceHolder={t("search_for_more_company_matches")}
            search={companySearch}
            onSearchChange={setCompanySearch}
            onSearch={() => { }}
            handleBroadCastTalent={() => { }}
          >
            {""}
          </MatchHolder>
          <div className="jdp-comp-match">{getCompanyMatchesTable()}</div>
        </div>
        {editJob && (
          <Portal>
            <CreateJobPopup
              visible={editJob.visible}
              job={editJob.data}
              title={t("edit_job_order")}
              successTitle={t("edit_job_order")}
              onClose={() => setEditJob(null)}
              onSuccessClose={handleUpdateJobSuccess}
              onGotoAgencyDashboard={() => { }}
              onGotoCreated={() => { }}
            />
          </Portal>
        )}
        {showAssignmentPopUp && (
          <Portal>
            <CreateAssignmentPopup
              callbackHandler={() => setShowAssignmentPopUp(false)}
              assignmentInfo={Object.create({})}
              selectedJobTalent={jobState.data}
              isFromJobs
              showModal
            />
          </Portal>
        )}
        {showGPCPopup && (
          <Portal>
            <GrossProfitCalculatorPopup
              visible={showGPCPopup}
              onClose={() => setShowGPCPopup(false)}
              onDissmiss={() => setShowGPCPopup(false)}
            />
          </Portal>
        )}
      </div>
      {addOption && (
        <Portal>
          <CreateJobOptionPopup
            data={{ job_id: params.id, job: jobState.data }}
            visible={addOption.visible}
            type={addOption.type}
            onClose={handleCloseAddDialog}
            onDissmiss={handleCloseAddDialog}
            onSuccessClose={handleOnSuccessAddDialog}
          />
        </Portal>
      )}
      {removeOption && <Portal>
        <RemoveOptionPopup
          id={params.id}
          state={jobState}
          data={removeOption.value}
          type={removeOption.type}
          sourceType={"Jobs"}
          visible={removeOption.visible}
          onClose={handleCloseRemoveDialog}
          onDissmiss={handleCloseRemoveDialog}
          onSuccessClose={handleOnSuccessRemoveDialog}
          onClearError={handleClearRemoveOptionError}
        />
      </Portal>}
      {canSendText &&
        broadcastTalentError &&
        textEmAllState &&
        textEmAllState?.error !== null && (
          <Portal>
            <BroadcastToTalentErrorPopup
              error={textEmAllState?.error}
              onClose={() => {
                setBroadcastTalentError(false);
                dispatch(textEmAllSliceActions.clearState());
              }}
              visible={broadcastTalentError}
              title={t("something_went_wrong")}
              loading={textEmAllState?.loading === LoadingType.pending}
              onForceSend={handleBroadCastTalent}
            />
          </Portal>
        )}
      {canSendText && broadcastTalent && (
        <Portal>
          <BroadcastToTalentPopup
            visible={broadcastTalent}
            title={""}
            successTitle={""}
            onClose={() => {
              setBroadcastTalent(false);
              dispatch(textEmAllSliceActions.clearState());
            }}
            onSuccessClose={() => { }}
            responseURL={textEmAllState?.response}
            loading={textEmAllState?.loading === LoadingType.pending}
          />
        </Portal>
      )}
    </StateChangeHandler>
  );
  /// Remove Option
  function handleRemoveOption(
    type: string,
    value: { id: string; value: string }
  ) {
    setRemoveOption({ visible: true, type: type, value: value });
  }
  /// Add option
  function handleAddOption(type: string) {
    setAddOption({ visible: true, type: type });
  }

  function handleOnNavClicks(type: string) {
    if (
      type === "company" &&
      jobState.data?.company_id
    ) {
      handleCompanySelect(
        jobState.data?.company_id,
        jobState.data?.company_name
      );
      return;
    }

    if (
      type === "department" &&
      jobState.data?.company_id ||
      jobState.data?.department_id &&
      !jobState.data?.contact_id_for_placement &&
      !jobState.data?.ordered_by_contact_id &&
      !jobState.data?.report_to_contact_id
    ) {
      handleDepartmentSelect(jobState.data);
      return;
    }

    if (
      type === "contact_for_placement" &&
      jobState.data?.contact_id_for_placement
    ) {
      handleContactSelect(
        jobState.data?.contact_id_for_placement,
        jobState.data?.contact_name_for_placement
      );
      return;
    }
    if (type === "order_by_contact" && jobState.data?.ordered_by_contact_id) {
      handleContactSelect(
        jobState.data?.ordered_by_contact_id,
        jobState.data?.ordered_by_contact_name
      );
      return;
    }
    if (type === "report_to_contact" && jobState.data?.report_to_contact_id) {
      handleContactSelect(
        jobState.data?.report_to_contact_id,
        jobState.data?.report_to_contact_name
      );
      return;
    }
  }
  function handleContactSelect(id: string, name: string) {
    history.push({
      pathname: `${AppRoutes.contactsDetailsPage}/${id}`,
      state: {
        id: id,
        name: name,
      },
    });
    return;
  }
  function handleJobSelect(job_id, job_title) {
    history.push({
      pathname: `${AppRoutes.jobDetailsPage}/${job_id}`,
      state: {
        id: job_id,
        name: job_title,
      },
    });
    return;
  }
  function handleCompanySelect(company_id: string, company_name: string) {
    history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${company_id}`,
      state: {
        id: company_id,
        name: company_name,
      },
    });
    return;
  }
  function handleDepartmentSelect(value: IJobOrderDetail) {
    history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.department_id}`,
      state: {
        id: value.company_id,
        name: value.company_name,
        departmentId: value.department_id,
        departmentName: value.department_name,
      },
    });
    return;
  }
  function handleTalentSelect(value: IJobTalentMatches) {
    history.push({
      pathname: `${AppRoutes.talentDetailsPage}/${value.talent_id}`,
      state: {
        id: value.talent_id,
        name: `${value.talent_first_name ?? ""} ${value.talent_middle_name ?? ""
          } ${value.talent_last_name ?? ""}`,
      },
    });
    return;
  }
};
export default JobsDashboardPage;
