import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { LoadingType, THEME } from "../../../../enums";
import { OnBoardingIcon } from "../../../../icons";
import { CustomButton } from "../../../../utils";
import { WebViewerInstance } from "@pdftron/webviewer";
import "./handbook.scss";
import PDFReader from "../../../../components/pdf_reader/pdf_reader";
import { getUploadEmployeeHandbookUrlThunk, selectEmployeeHandbookState, uploadEmployeeHandbookThunk, useAppDispatch, useAppSelector } from "../../../../redux/store";
import { employeeHandbookSliceActions } from "../../../../redux/onboarding_portal/employee_handbook/employee_handbook_reducer";
import ApiError from "../../../../components/api_error";
import { IBasicDialog, IDocumentReviewInfo, ITalentOnBoardingData } from "../../../../interfaces";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";

interface Props extends IBasicDialog {
    type: string,
    token: string,
    state: ITalentOnBoardingData,
    onSuccessClose: (type: string) => void,
}

const Handbook: React.FunctionComponent<Props> = (props) => {
    const { visible, state, title, type, token, onClose, onDissmiss, onSuccessClose } = props;
    const dispatch = useAppDispatch();
    const { uploadFile, uploadURL } = useAppSelector((state) => selectEmployeeHandbookState(state));
    const actionRef = useRef<ActionDialogHolderType>(null);
    const instance = useRef<WebViewerInstance | null>(null);
    const [endReached, setEndReached] = useState<boolean>(state.handbook_document.recruiter_completed_review ?? false);

    useEffect(() => {
        if (uploadURL.loading === LoadingType.succeeded && uploadURL.response) {
            handleUploadFile(uploadURL.response.url);
        }

    }, [uploadURL.loading])

    useEffect(() => {
        if (uploadFile.loading === LoadingType.succeeded) {
            onSuccessClose(type);
        }
        return () => {
            dispatch(employeeHandbookSliceActions.clearState())
        }
    }, [uploadFile.loading]);


    // Function to handle instance and event listener setup
    function handleOnInstance(value: WebViewerInstance) {
        instance.current = value;
        const { UI, Core } = value;
        const { documentViewer } = Core;
        // Load the document first
        UI.loadDocument(state.handbook_document.url);
        // Listen for the 'documentLoaded' event to ensure the document is fully loaded
        documentViewer.addEventListener('documentLoaded', () => {
            const pages = documentViewer.getPageCount();
            const currentPage = documentViewer.getCurrentPage();
            documentViewer.addEventListener('pageNumberUpdated', (page) => {
                const totalPages = documentViewer.getPageCount();
                if (page === totalPages) {
                    setEndReached(true);
                }
            });
            if (pages === 1 && currentPage === 1) {
                setEndReached(true); // On single-page, set true when user is on that page
            }
            UI.setFitMode(UI.FitMode.FitWidth);
        });
        UI.disableElements(['toolsHeader']);
    }

    // Set up WebViewer when URL changes
    useEffect(() => {
        if (instance.current && state.handbook_document.url) {
            const { UI, Core } = instance.current;
            const { documentViewer } = Core;
            // Load the document first
            UI.loadDocument(state.handbook_document.url);
            documentViewer.addEventListener('documentLoaded', () => {
                const pages = documentViewer.getPageCount();
                const currentPage = documentViewer.getCurrentPage();
                if (pages === 1 && currentPage === 1) {
                    setEndReached(true);
                }
            });
        }
    }, [state.handbook_document.url]);


    // useEffect(() => {
    //     console.log(instance.current, state.handbook_document.url, instance.current && state.handbook_document.url)

    //     if (instance.current && state.handbook_document.url) {

    //         const { UI, Core } = instance.current;
    //         const { documentViewer } = Core;
    //         documentViewer.addEventListener('pageNumberUpdated', (page) => {
    //             const pages = documentViewer.getPageCount();
    //             if (page === pages) setEndReached(page === pages);
    //         });

    //         UI.loadDocument(state.handbook_document.url);
    //     }
    // }, [state.handbook_document.url, instance])

    // function handleOnInstance(value: WebViewerInstance) {
    //     instance.current = value;
    //     const { UI, Core } = value;
    //     const { documentViewer } = Core;
    //     documentViewer.addEventListener('pageNumberUpdated', (page) => {
    //         const pages = documentViewer.getPageCount();
    //         documentViewer.addEventListener('pageNumberUpdated', (page) => {
    //             const pages = documentViewer.getPageCount();
    //             if (page === pages) setEndReached(page === pages);
    //         });
    //     });
    //     instance.current.UI.disableElements(['toolsHeader']);
    //     UI.loadDocument(state.handbook_document.url);
    //     UI.setFitMode(UI.FitMode.FitWidth);
    // }

    async function handleUploadFile(url: string) {
        if (instance.current) {
            const { documentViewer, annotationManager } = instance.current.Core;
            const doc = documentViewer.getDocument();
            const xfdfString = await annotationManager.exportAnnotations();
            const data = await doc.getFileData({
                // saves the document with annotations in it
                xfdfString
            });
            const arr = new Uint8Array(data);
            const blob = new Blob([arr], { type: 'application/pdf' });
            dispatch(uploadEmployeeHandbookThunk({ url: url, file: blob }));
        }
    }


    const loading = uploadURL.loading === LoadingType.pending || uploadFile.loading === LoadingType.pending;

    const error = uploadURL.error || uploadFile.error;


    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"70%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={handleClose}>
                <div className="ob-hnd-b-container">
                    <div className="hnd-b-header">
                        <FormDialogTilteHeader
                            title={title}
                            subTitle={t('please_read_and_sign_the_document')}
                            subTitleStyle={{ fontWeight: 'normal' }}
                            titleIcon={<OnBoardingIcon width={"100%"} height={"100%"} />}
                        />
                    </div>
                    <div className="hnd-b-content">
                        <PDFReader onInstanceChange={handleOnInstance} />
                        {error && (
                            <div className={"error-section"}>
                                <ApiError
                                    message={error.message}
                                    onClose={handleClearError}
                                />
                            </div>
                        )}
                    </div>
                    <div className="hnd-b-actions">
                        <div className="btn-cancel">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("cancel")}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={handleClose}
                            />
                        </div>
                        <div className="btn-new">
                            <CustomButton
                                loading={loading}
                                textStyle={{
                                    textTransform: "capitalize",
                                }}
                                name={t("ok")}
                                enable={endReached}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => loading ? undefined : handleSave()}
                            />
                        </div>
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>
    );

    ///Handle clear error
    function handleClearError() {
        dispatch(employeeHandbookSliceActions.clearState());
    }

    /// handle submit
    function handleSave() {
        dispatch(getUploadEmployeeHandbookUrlThunk({ token, talent_id: state.profile.id }));
    }


    function handleClose() {
        closePopup(onClose);
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
}

export default Handbook;