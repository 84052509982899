import {createAsyncThunk} from "@reduxjs/toolkit";
import {ContactListItem} from "../../../../../entities/ContactInfo";
import {AppError, IContactByPagePayload, IContactsByPage} from "../../../../../interfaces";
import {catchRequestError} from "../../../../../utils";
import {contactsByCompanyDepartment, contactsByCompanyDepartmentByPage} from "../../../../../apis/contacts_api";

export const getCompanyContacts = createAsyncThunk<
    Array<ContactListItem>,
    {company_id: string, department_id: string | null},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@companies/details/contacts/contact-list/get',
    async (data, thunkAPI) => {
        try {
            const res = await contactsByCompanyDepartment(data);
            return res.data as ContactListItem[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const getCompanyContactByPage = createAsyncThunk<
    IContactsByPage,
    IContactByPagePayload,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@companies/details/get-company-contacts-by-page/post',
    async (payload, thunkAPI) => {
        try {
            const res = await contactsByCompanyDepartmentByPage(payload);
            return res.data as IContactsByPage;
        } catch (error) {
            return catchRequestError(error, thunkAPI)
        }
    }
)
