import { createSelector } from "@reduxjs/toolkit";
import { selectAll, selectState } from "./four_hour_guarantee_report_reducer";
import { IRootState } from "../../../store";

const select = (state: IRootState) => state.reportCenter.reportCenterService.fourHourGuaranteeCallsReportsList;
export const selectFourHourGuaranteeCallsReportsListState = createSelector(select, selectState);
export const selectFourHourGuaranteeCallsReportsList = createSelector(
    select,
    selectAll
);
