

import {
    ICreateCompany,
    ICreateDepartment,
    ICompanySalesProfile,
    ICompanyServiceProfile,
    ICompany,
    IDepartment,
    IAddCompanySkill,
    IAddCompanyCertificate,
    IAddCompanyCredentials,
    IAddCompanyEducation,
    IAddCompanyEquipment,
    IRemoveCompanyCertificate,
    IRemoveCompanyCredentials,
    IRemoveCompanyEducation,
    IRemoveCompanyEquipment,
    IRemoveCompanySkill,
    IRemoveCompanyStaff,
    IAddCompanyStaff,
    IShift,
    IAddSkill,
    ICompanyEdocs,
    ICompaniesByPagePayload,
    ICompetitorByPagePayload
} from "../interfaces";
import { ICompanyBackOfficeInvoiceDocs } from "../interfaces/company_back_office_invoice_docs";
import { ICompanyBackOfficeSetup } from "../interfaces/company_back_office_setup";
import interceptor from "./interceptor";
import UrlBuilder from "./url_builder";

export const CompaniesAPI = {

    /// [Companies]

    getCompanies() {
        return interceptor.get(UrlBuilder.companies);
    },

    getCompaniesByPage(doc: ICompaniesByPagePayload) {
        let url = `${UrlBuilder.getCompaniesByPage}?page=${doc.page}&size=${doc.size}`;
        if (doc.search_str) {
            url += `&search_str=${doc.search_str}`;
        }
        if (doc.include_departments) {
            url += `&include_departments=${doc.include_departments}`;
        }
        if (doc.filter_ids) return interceptor.post(url, doc.filter_ids);
        return interceptor.post(url);
    },

    getCompaniesList() {
        return interceptor.get(UrlBuilder.getCompanyDropdown);
    },
    getDepartmentsList(companyId: string) {
        return interceptor.get(`${UrlBuilder.getDepartmentDropdown}?company_id=${companyId}`);
    },

    createCompany(doc: { forceAdd: boolean, value: ICreateCompany }) {
        if (doc.forceAdd) return interceptor.post(`${UrlBuilder.companies}?force_add=${doc.forceAdd}`, doc.value);
        return interceptor.post(UrlBuilder.companies, doc.value);
    },

    deleteCompany(id: string) {
        return interceptor.delete(`${UrlBuilder.companies}?company_id=${id}`);
    },

    getCompanyDirectory(branch_id?: string) {
        if (branch_id) return interceptor.get(`${UrlBuilder.companies}?branch_id=${branch_id}`);
        return interceptor.get(UrlBuilder.getCompanyDirectory);
    },

    updateCompany(doc: ICompany) {
        return interceptor.patch(UrlBuilder.companies, doc);
    },

    companyById(companyId: string) {
        return interceptor.get(`${UrlBuilder.getCompanyById}?company_id=${companyId}`);
    },

    /// [Departments]
    getDepartments(companyId?: string) {
        if (companyId) return interceptor.get(`${UrlBuilder.departments}?company_id=${companyId}`);
        return interceptor.get(UrlBuilder.departments);
    },

    getCompanyDepartments(companyId: string) {
        return interceptor.get(`${UrlBuilder.departments}?company_id=${companyId}`);
    },

    createDepartment(data: ICreateDepartment) {
        return interceptor.post(UrlBuilder.departments, data);
    },

    deleteDepartment(id: string) {
        return interceptor.delete(`${UrlBuilder.departments}?department_id=${id}`);
    },

    updateDepartment(doc: IDepartment) {
        return interceptor.patch(UrlBuilder.departments, doc);
    },

    departmentById(id: string) {
        return interceptor.get(`${UrlBuilder.getDepartmentById}?department_id=${id}`);
    },

    getDepartmentStructure(companyId: string, parentDeptId?: string) {
        if(parentDeptId) return interceptor.get(`${UrlBuilder.getDepartmentsStructure}?company_id=${companyId}&department_id=${parentDeptId}`);
        return interceptor.get(`${UrlBuilder.getDepartmentsStructure}?company_id=${companyId}`);
    },

    /// [Business sector]

    getBusinessSectors() {
        return interceptor.get(UrlBuilder.businessSector);
    },

    ///[Sales Profile]
    getCompanySalesProfile(doc: { companyId: string, departmentId?: string | null }) {
        if (doc.departmentId && doc.departmentId !== null && doc.departmentId !== "")
            return interceptor.get(`${UrlBuilder.companySalesProfile}?company_id=${doc.companyId}&department_id=${doc.departmentId}`);
        else
            return interceptor.get(`${UrlBuilder.companySalesProfile}?company_id=${doc.companyId}`);
    },
    saveCompanySalesProfile(id: string, data: ICompanySalesProfile) {
        return interceptor.patch(`${UrlBuilder.companySalesProfile}?company_id=${id}`, data);
    },

    ///[Service Profile]
    getCompanyServiceProfile(doc: { companyId: string, departmentId?: string | null }) {
        if (doc.departmentId && doc.departmentId !== null && doc.departmentId !== "")
            return interceptor.get(`${UrlBuilder.companyServiceProfile}?company_id=${doc.companyId}&department_id=${doc.departmentId}`);
        else
            return interceptor.get(`${UrlBuilder.companyServiceProfile}?company_id=${doc.companyId}`);
    },
    saveCompanyServiceProfile(data: ICompanyServiceProfile) {
        return interceptor.patch(`${UrlBuilder.companyServiceProfile}`, data);
    },

    getCompetitors() {
        return interceptor.get(`${UrlBuilder.getAllCompetitors}`);
    },

    getCompanyShifts(doc: { companyId: string, departmentId?: string | null }) {
        if (doc.departmentId && doc.departmentId !== null && doc.departmentId !== "")
            return interceptor.get(`${UrlBuilder.companyShifts}?company_id=${doc.companyId}&department_id=${doc.departmentId}`);
        else
            return interceptor.get(`${UrlBuilder.companyShifts}?company_id=${doc.companyId}`);
    },
    saveCompanyShift(data: IShift) {
        return interceptor.post(`${UrlBuilder.companyShifts}`, data);
    },
    updateCompanyShift(data: IShift) {
        return interceptor.patch(`${UrlBuilder.companyShifts}`, data);
    },
    deleteCompanyShift(id: string) {
        return interceptor.delete(`${UrlBuilder.companyShifts}?company_shift_id=${id}`);
    },

    ///[Companies---> CompanyDetails ---> Company summnary --> Company overview]

    getCompanyProfessionalSkills(doc: { companyId: string, departmentId?: string }) {
        if (doc.departmentId) return interceptor.get(`${UrlBuilder.companySkills}?company_id=${doc.companyId}&department_id=${doc.departmentId}`);
        return interceptor.get(`${UrlBuilder.companySkills}?company_id=${doc.companyId}`);
    },
    getCompanyCredentials(doc: { companyId: string, departmentId?: string }) {
        if (doc.departmentId) return interceptor.get(`${UrlBuilder.companyCredentials}?company_id=${doc.companyId}&department_id=${doc.departmentId}`);
        return interceptor.get(`${UrlBuilder.companyCredentials}?company_id=${doc.companyId}`);
    },
    getCompanyCertification(doc: { companyId: string, departmentId?: string }) {
        if (doc.departmentId) return interceptor.get(`${UrlBuilder.companyCertification}?company_id=${doc.companyId}&department_id=${doc.departmentId}`);
        return interceptor.get(`${UrlBuilder.companyCertification}?company_id=${doc.companyId}`);
    },
    getCompanyEducation(doc: { companyId: string, departmentId?: string }) {
        if (doc.departmentId) return interceptor.get(`${UrlBuilder.companyEducationRequirements}?company_id=${doc.companyId}&department_id=${doc.departmentId}`);
        return interceptor.get(`${UrlBuilder.companyEducationRequirements}?company_id=${doc.companyId}`);
    },
    getCompanyRequiredEquipment(doc: { companyId: string, departmentId?: string }) {
        if (doc.departmentId) return interceptor.get(`${UrlBuilder.companyEquipments}?company_id=${doc.companyId}&department_id=${doc.departmentId}`);
        return interceptor.get(`${UrlBuilder.companyEquipments}?company_id=${doc.companyId}`);
    },

    ///[Companies---> CompanyDetails ---> Alpha omega staffing representatives]

    getCompanyStaff(doc: { companyId: string, role: string, departmentId?: string }) {
        if (doc.departmentId) return interceptor.get(`${UrlBuilder.companyStaffs}?company_id=${doc.companyId}&role=${doc.role}&department_id=${doc.departmentId}`);
        return interceptor.get(`${UrlBuilder.companyStaffs}?company_id=${doc.companyId}&role=${doc.role}`);
    },

    addCompanyStaff(data: IAddCompanyStaff) {
        return interceptor.post(UrlBuilder.companyStaffs, data);
    },

    deleteCompanyStaff(doc: IRemoveCompanyStaff) {
        return interceptor.delete(UrlBuilder.companyStaffs, { data: doc });
    },

    /// [Companies] ---> Details --->  skills
    getCompanySkills(doc: { companyId: string, departmentId?: string }) {
        if (doc.departmentId) {
            return interceptor.get(`${UrlBuilder.companySkills}?company_id=${doc.companyId}&department_id=${doc.departmentId}`);
        }
        return interceptor.get(`${UrlBuilder.companySkills}?company_id=${doc.companyId}`);
    },
    addCompanySkill(doc: { companyId: string, departmentId?: string, value: IAddSkill }) {
        if (doc.departmentId) {
            return interceptor.post(`${UrlBuilder.companySkills}?company_id=${doc.companyId}&department_id=${doc.departmentId}`, doc.value);
        }
        return interceptor.post(`${UrlBuilder.companySkills}?company_id=${doc.companyId}`, doc.value);
    },

    deleteCompanySkill(doc: { companyId: string, departmentId?: string, value: IAddSkill }) {
        if (doc.departmentId) {
            return interceptor.delete(`${UrlBuilder.companySkills}?company_id=${doc.companyId}&department_id=${doc.departmentId}`, { data: doc.value });
        }
        return interceptor.delete(`${UrlBuilder.companySkills}?company_id=${doc.companyId}`, { data: doc.value });
    },

    getAllSkillSet() {
        return interceptor.get(UrlBuilder.skillSet);
    },
    getAllCertifications() {
        return interceptor.get(UrlBuilder.certifications);
    },
    getAllCredentials() {
        return interceptor.get(UrlBuilder.credentials);
    },
    getAllEducationRequirement() {
        return interceptor.get(UrlBuilder.educationRequirements);
    },
    getAllRequiredEquipment() {
        return interceptor.get(UrlBuilder.equipments);
    },


    addSkillSet(doc: IAddCompanySkill) {
        return interceptor.post(UrlBuilder.companySkills, doc);
    },
    addCertifications(doc: IAddCompanyCertificate) {
        return interceptor.post(UrlBuilder.companyCertification, doc);
    },
    addCredentials(doc: IAddCompanyCredentials) {
        return interceptor.post(UrlBuilder.companyCredentials, doc);
    },
    addEducationRequirement(doc: IAddCompanyEducation) {
        return interceptor.post(UrlBuilder.companyEducationRequirements, doc);
    },
    addRequiredEquipment(doc: IAddCompanyEquipment) {
        return interceptor.post(UrlBuilder.companyEquipments, doc);
    },

    deleteSkillSet(doc: IRemoveCompanySkill) {
        return interceptor.delete(UrlBuilder.companySkills, { data: doc });
    },
    deleteCertifications(doc: IRemoveCompanyCertificate) {
        return interceptor.delete(UrlBuilder.companyCertification, { data: doc });
    },
    deleteCredentials(doc: IRemoveCompanyCredentials) {
        return interceptor.delete(UrlBuilder.companyCredentials, { data: doc });
    },
    deleteEducationRequirement(doc: IRemoveCompanyEducation) {
        return interceptor.delete(UrlBuilder.companyEducationRequirements, { data: doc });
    },
    deleteRequiredEquipment(doc: IRemoveCompanyEquipment) {
        return interceptor.delete(UrlBuilder.companyEquipments, { data: doc });
    },

    ///competitors
    getAllCompetitors() {
        return interceptor.get(UrlBuilder.getAllCompetitors);
    },

    getCompetitorsByPage(doc: ICompetitorByPagePayload) {
        let url = `${UrlBuilder.getCompetitorsByPage}?page=${doc.page}&size=${doc.size}&include_inactive=${doc.include_inactive}`;
        if (doc.search_str) {
            url += `&search_str=${doc.search_str}`;
        }
        if(doc.users) return interceptor.post(url, doc.users)
        return interceptor.post(url);
    },


    /// Company Meta Data
    getCompanyMetaData(data) {
        return interceptor.get(`${UrlBuilder.companyMetaData}?company_id=${data['companyId']}&contact=${data['contact']}`);
    },

    // Back Office

    getBackOfficeSetup(doc: { company_id?: string, department_id?: string }) {
        if (doc.department_id) {
            return interceptor.get(`${UrlBuilder.getcompaniesBackOfficeSetup}?department_id=${doc.department_id}`);
        }
        return interceptor.get(`${UrlBuilder.getcompaniesBackOfficeSetup}?company_id=${doc.company_id}`);
    },
    updateBackOfficeSetup(doc: ICompanyBackOfficeSetup) {
        if (doc.department_id) {
            return interceptor.patch(`${UrlBuilder.getcompaniesBackOfficeSetup}`, doc);
        }
        return interceptor.patch(`${UrlBuilder.getcompaniesBackOfficeSetup}`, doc);
    },
    getBackOfficeInvoiceDocs(company_id: string) {
        return interceptor.get(`${UrlBuilder.getcompaniesBackOfficeInvoiceDocs}?company_id=${company_id}`);
    },
    updateBackOfficeInvoiceDocs(doc: ICompanyBackOfficeInvoiceDocs) {
        return interceptor.patch(`${UrlBuilder.getcompaniesBackOfficeInvoiceDocs}?company_id=${doc.company_id}`, doc);
    },

    getCompanyInvoices(doc: { company_id?: string, department_id?: string }) {
        let url = `${UrlBuilder.companyInvoices}`;
        if (doc.department_id) {
            url += `?department_id=${doc.department_id}`;
        }
        if (doc.company_id) {
            url += `?company_id=${doc.company_id}`;
        }
        return interceptor.get(url);
    },
    getCompanyEdocs(doc: { company_id?: string, department_id?: string }) {
        let url = `${UrlBuilder.companyEdocs}?company_id=${doc.company_id}`;
        if (doc.department_id) {
            url += `&department_id=${doc.department_id}`;
        }
        return interceptor.get(url);

    },
    postCompanyEdocs(doc: ICompanyEdocs) {
        return interceptor.post(UrlBuilder.companyEdocs, doc);
    },
    mailInvoices(invoice_ids: string[]) {
        return interceptor.post(`${UrlBuilder.emailInvoices}`, invoice_ids);
    },
    mailEDocs(email_id: string, edoc_ids: string[]) {
        return interceptor.post(`${UrlBuilder.emailEdocs}?email_id=${email_id}`, edoc_ids);
    },
}