import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../../components/dialog_wrapper/dialog_wrapper";
import CustomFormSelect from "../../../../../components/form_selector/form_select";
import { LoadingType, THEME } from "../../../../../enums";
import { RiskmanagIcon, LinkIcon } from "../../../../../icons";
import { useAppDispatch } from "../../../../../redux/store";
import { FormInput, mapOptions, RegxPattern, CustomButton } from "../../../../../utils";
import { US_states } from "../../../../../utils/States_US";
import { AppError } from "../../../../../interfaces";
import ApiError from "../../../../../components/api_error";
import './risk_management_popup.scss';

interface Props {
    onClose: () => void;
    onDismiss: () => void;
    visible: boolean;
    category: any;
    category_id: number;
    reduxMapping: any;
    errorState: AppError | null | undefined;
    methodValue?: {
        id: string,
        agency_id: string,
        branch_id: string,
        insurance_company_name: string,
        street_address: string,
        city: string,
        state: string,
        zip_code: string,
        contact_name: string,
        website: string,
        linkedin_url: string,
        twitter_url: string,
        facebook_url: string,
        pinterest_url: string,
        phone: string,
        is_deleted: string
    };
}

const getFormData = (value) => {
    if (value) {
        return value;
    }
    return {
        insurance_company_name: "",
        contact_name: "",
        street_address: "",
        city: "",
        state: "",
        zip_code: "",
        website: "",
        linkedin_url: "",
        twitter_url: "",
        facebook_url: "",
        pinterest_url: ""
    }
}

const SCRiskManagementForm: React.FunctionComponent<Props> = (props) => {
    const { onClose, onDismiss, visible, category, category_id, reduxMapping, methodValue, errorState } = props;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean | undefined>(false);
    const [formState, setFormState] = useState(getFormData(methodValue));

    function getMapping() {
        return reduxMapping[category_id];
    }
    
    useEffect(() => {
        let state = getMapping().state;
        if (state?.create?.loading === LoadingType.succeeded || state?.update?.loading === LoadingType.succeeded) {
            dispatch(category.methods.get() || category.methods.update());
            dispatch(category.clearCreateState());
            dispatch(category.clearUpdateState());
            setTimeout(() => (onClose()), 1000);
        }
    }, [getMapping().state?.create?.loading, getMapping()?.state?.update?.loading])

    function handleInput(fieldId: string, value: any, parent?: string) {
        if (parent) {
            setFormState({
                ...formState,
                [parent]: { ...formState[parent], [fieldId]: value },
            });
        } else {
            setFormState({ ...formState, [fieldId]: value });
        }
    }

    function _isFormValid() {
        const { insurance_company_name } = formState;
        if (insurance_company_name === '') return false;

        return true;
    }

    function handleUpdate() {
        setCheckValidationOnSubmit(true);
        if(formState.id) {
            if (_isFormValid()) {
                dispatch(category.methods.update({
                    'id': formState.id.trim(),
                    'agency_id': formState.agency_id,
                    'branch_id': formState.branch_id,
                    'insurance_company_name': formState.insurance_company_name.trim(),
                    'contact_name': formState?.contact_name.trim(),
                    'website': formState?.website.trim(),
                    'street_address': formState?.street_address.trim(),
                    'city': formState?.city.trim(),
                    'state': formState?.state.trim(),
                    'zip_code': formState?.zip_code.trim(),
                    'social_media': {
                        linkedin_url: formState.linkedin_url,
                        twitter_url: formState.twitter_url,
                        facebook_url: formState.facebook_url,
                        pinterest_url: formState.pinterest_url,
                    },
                    'phone': formState.phone,
                    'is_deleted': formState.is_deleted
                }));
                setCheckValidationOnSubmit(false);
            }
        } else {
            if (_isFormValid()) {
                dispatch(category.methods.create({
                    'insurance_company_name': formState.insurance_company_name.trim(),
                    'contact_name': formState?.contact_name.trim(),
                    'website': formState?.website.trim(),
                    'street_address': formState?.street_address.trim(),
                    'city': formState?.city.trim(),
                    'state': formState?.state.trim(),
                    'zip_code': formState?.zip_code.trim(),
                    'social_media': {
                        linkedin_url: formState.linkedin_url,
                        twitter_url: formState.twitter_url,
                        facebook_url: formState.facebook_url,
                        pinterest_url: formState.pinterest_url,
                    }
                }));
                setCheckValidationOnSubmit(false);
            }
        }
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    const handleClearError = () => {
        dispatch(category.clearCreateState());
        dispatch(category.clearUpdateState());
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={500}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <DialogWrapper onClose={() => {
                handleClearError();
                closePopup(onClose);
            }}>
                <div className="ac-sc-rm-dialog-header">
                    <div className="dialog-header">
                        <FormDialogTilteHeader
                            title={formState.id ? t(category.updateLabel) : t(category.addLabel)}
                            titleIcon={<RiskmanagIcon width={"100%"} height={"100%"} style={{ color: THEME.talentOptionTextColor }} />}
                        />
                    </div>
                    <div className="dialog-content">
                        <FormInput
                            id={"insurance_company_name"}
                            onChange={(id, val) => (handleInput(id, val))}
                            type={"text"}
                            value={formState.insurance_company_name}
                            label={t("insurance_company_name")}
                            placeholder=""
                            required={true}
                            validations={formValidators}
                            checkValidationOnSubmit={checkValidationOnSubmit}
                        />
                        <div className="row-content">
                            <div className="form-input-algnt">
                                <FormInput
                                    id={"street_address"}
                                    onChange={(id, val) => (handleInput(id, val))}
                                    type={"text"}
                                    value={formState.street_address}
                                    label={t("street_address")}
                                    placeholder=""
                                    validations={formValidators}
                                />
                            </div>
                            <div className="form-input-algnt">
                                <FormInput
                                    id={"city"}
                                    onChange={(id, val) => (handleInput(id, val))}
                                    type={"text"}
                                    value={formState.city}
                                    label={t("city")}
                                    placeholder=""
                                    validations={formValidators}
                                />
                            </div>
                            <div className="form-input-algnt">
                                <CustomFormSelect
                                    customStyle={{ width: "-webkit-fill-available" }}
                                    value={formState.state}
                                    name="state"
                                    label={t("state")}
                                    placeholder={t("select_placeholder")}
                                    list={mapOptions(US_states, "name", "code")}
                                    onChange={(value) => handleInput("state", value)}
                                    validations={formValidators}
                                />
                            </div>
                            <div>
                                <FormInput
                                    id={"zip_code"}
                                    onChange={(id, val) => (handleInput(id, val))}
                                    type={"zip_code"}
                                    value={formState.zip_code}
                                    label={t("zip_code")}
                                    placeholder=""
                                    validations={formValidators}
                                    maxLength={5}
                                />
                            </div>
                        </div>
                        <div className="row-content">
                            <div className="form-input-algnt">
                                <FormInput
                                    id={"contact_name"}
                                    onChange={(id, val) => (handleInput(id, val))}
                                    type={"text"}
                                    value={formState.contact_name}
                                    label={t("contact_name")}
                                    placeholder=""
                                    validations={formValidators}
                                />
                            </div>
                            <div className="form-input-algnt">
                                <FormInput
                                    id={"website"}
                                    onChange={(id, val) => (handleInput(id, val))}
                                    type={"text"}
                                    value={formState.website}
                                    label={t("website")}
                                    placeholder=""
                                    validations={formValidators}
                                />
                            </div>
                            <div className="form-input-algnt">
                                <FormInput
                                    id={"facebook_url"}
                                    onChange={(id, val) => (handleInput(id, val))}
                                    type={"text"}
                                    value={formState.facebook_url}
                                    label={t("facebook")}
                                    pattern={RegxPattern.url}
                                    placeholder=""
                                    labelIcon={<LinkIcon width={"0.833vw"} height={"0.833vw"} />}
                                    validations={formValidators}
                                />
                            </div>
                            <div>
                                <FormInput
                                    id={"linkedin_url"}
                                    onChange={(id, val) => (handleInput(id, val))}
                                    type={"text"}
                                    value={formState.linkedin_url}
                                    label={t("linked_in")}
                                    pattern={RegxPattern.url}
                                    placeholder=""
                                    labelIcon={<LinkIcon width={"0.833vw"} height={"0.833vw"} />}
                                    validations={formValidators}
                                />
                            </div>
                        </div>
                        <div className="row-content">
                            <div className="form-input-algnt">
                                <FormInput
                                    id={"twitter_url"}
                                    onChange={(id, val) => (handleInput(id, val))}
                                    type={"text"}
                                    value={formState.twitter_url}
                                    label={t("X")}
                                    pattern={RegxPattern.url}
                                    placeholder=""
                                    labelIcon={<LinkIcon width={"0.833vw"} height={"0.833vw"} />}
                                    validations={formValidators}
                                />
                            </div>
                            <div className="form-input-algnt">
                                <FormInput
                                    id={"pinterest_url"}
                                    onChange={(id, val) => (handleInput(id, val))}
                                    type={"text"}
                                    value={formState.pinterest_url}
                                    label={t("pinterest")}
                                    pattern={RegxPattern.url}
                                    placeholder=""
                                    labelIcon={<LinkIcon width={"0.833vw"} height={"0.833vw"} />}
                                    validations={formValidators}
                                />
                            </div>
                        </div>
                        {errorState && <div className={"error-section"}>
                            <ApiError message={errorState?.message} onClose={handleClearError} />
                        </div>}
                    </div>
                    <div className="dialog-footer">
                        <div className="btn-cancel">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('cancel')}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => closePopup(onClose)}
                            />
                        </div>
                        <div className="btn-save">
                            <CustomButton
                                textStyle={{ textTransform: 'capitalize' }}
                                name={formState.id ? t('update') : t('save')}
                                enable={true}
                                loading={getMapping().state.create.loading == LoadingType.pending || getMapping().state.update.loading == LoadingType.pending}
                                backgroundColor={THEME.buttonColor1}
                                onClick={handleUpdate}
                            />
                        </div>
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>
    )

    function formValidators(value: { text: string; field: string }) {
        switch (value.field) {
            case "insurance_company_name": {
                if (value.text === "") return t("validators.required");
                if (RegxPattern.username.test(value.text) === false)
                    return t("validators.enterValidName");
                return null;
            }
            case "zip_code": {
                if (RegxPattern.zipCode.test(value.text) === false)
                    return t("validators.enterValidZipCode");
                return null;
            }
            case "website":
            case "linkedin_url":
            case "twitter_url":
            case "facebook_url":
            case "pinterest_url": {
                if (value.text === "") return t("");
                if (RegxPattern.url.test(value.text) === false)
                    return t("validators.enterValidUrl");
                return null;
            }
            default: {
                return null;
            }
        }
    }
}

export default SCRiskManagementForm;