import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterEmailConfiguration, AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { AdminCenterAPI } from "../../../../apis";

export const getEmailConfigurationList = createAsyncThunk<
    AdminCenterEmailConfiguration,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/system_configuration/email_configuration/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getEmailConfigList();
            return res.data as AdminCenterEmailConfiguration;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createEmailConfigurationList = createAsyncThunk<
    string,
    { 'email': string, 'password': string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
        '@admin_center/system_configuration/email_configuration/post',
        async (data, thunkAPI) => {
            try {
                const res = await AdminCenterAPI.createEmailConfigList(data);
                return res.data as string;
            } catch (error) {
                return catchRequestError(error, thunkAPI);
            }
        }
    );

export const updateEmailConfigurationList = createAsyncThunk<
    string,
    AdminCenterEmailConfiguration,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
        '@admin_center/system_configuration/email_configuration/update',
        async (data, thunkAPI) => {
            try {
                const res = await AdminCenterAPI.updateEmailConfigList(data);
                return res.data as string;
            } catch (error) {
                return catchRequestError(error, thunkAPI);
            }
        }
    );