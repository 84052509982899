import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../../components/action_dialog_holder/action_dialog_holder";
import { LoadingType, THEME } from "../../../../../enums";
import { SystemWideIcon } from "../../../../../icons";
import { useAppDispatch } from "../../../../../redux/store";
import { FormInput, CustomButton } from "../../../../../utils";
import { AppError } from "../../../../../interfaces";
import './system_wide_add_popup.scss'
import ApiError from "../../../../../components/api_error";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../../components/dialog_wrapper/dialog_wrapper";

interface Props {
    onClose: () => void;
    onDismiss: () => void;
    visible: boolean;
    category: any;
    category_id: number;
    reduxMapping: any;
    exceptionList: Array<number>;
    errorState: AppError | null | undefined;
    methodValue?: {
        id: string;
        value: string;
        description: string;
        sequence_number: number;
    };
}

const getFormData = (value) => {
    if (value) {
        return value
    }
    return {
        id: "",
        value: "",
        description: "",
        sequence_number: 0
    }
}

const SystemWideAddPopup: React.FunctionComponent<Props> = (props) => {
    const { onClose, onDismiss, visible, category, category_id, reduxMapping, exceptionList, methodValue, errorState } = props;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [formState, setFormState] = useState(getFormData(methodValue));

    function getMapping() {
        return reduxMapping[category_id];
    }

    useEffect(() => {
        let state = getMapping().state;
        if (state?.create?.loading === LoadingType.succeeded || state?.update?.loading === LoadingType.succeeded) {
            dispatch(category.methods.get() || category.methods.update());
            dispatch(category.clearCreateState());
            dispatch(category.clearUpdateState());
            setTimeout(() => (onClose()), 1000);
        }
    }, [getMapping().state?.create?.loading, getMapping()?.state?.update?.loading])

    function handleInput(id, value) {
        setFormState({ ...formState, [id]: value })
    }

    function handleSave() {
        const { id, value, description, sequence_number } = formState;
        if (id) {
            if (description || sequence_number) {
                dispatch(category.methods.update({
                    id,
                    value: value.trim(),
                    description: description ? description.trim() : '',
                    sequence_number
                }));
            } else {
                dispatch(category.methods.update({
                    id,
                    value: value.trim()
                }));
            }
        } else {
            if (description) {
                dispatch(category.methods.create({
                    value: value.trim(),
                    description: description.trim()
                }));
            } else {
                dispatch(category.methods.create({
                    value: value.trim()
                }));
            }
        }
    }

    const handleClearError = () => {
        dispatch(category.clearCreateState());
        dispatch(category.clearUpdateState());
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={500}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <DialogWrapper onClose={() => {
                handleClearError();
                closePopup(onClose);
            }}>
                <div className="dialog-container dialog-container-2">
                    <div className="dialog-header">
                        <FormDialogTilteHeader
                            title={formState.id ? t(category.updateLabel) : t(category.buttonLabel)}
                            titleIcon={<SystemWideIcon width={"100%"} height={"100%"} style={{ color: THEME.talentOptionTextColor }} />}
                        />
                    </div>
                    <div className="dialog-content">
                        <FormInput
                            id={"value"}
                            onChange={(id, val) => (handleInput(id, val))}
                            type={"text"}
                            value={formState?.value}
                            label={t(category?.inputLabel)}
                            placeholder=""
                            required={true}
                        />
                        {exceptionList.includes(category_id) && <FormInput
                            id={"description"}
                            onChange={(id, val) => (handleInput(id, val))}
                            type={"text"}
                            value={formState?.description}
                            label={t(category?.inputLabel2)}
                            placeholder=""
                        />}
                        {errorState && <div className={"error-section"}>
                            <ApiError message={errorState?.message} onClose={handleClearError} />
                        </div>}
                    </div>
                    <div className="dialog-footer">
                        <div className="btn-cancel">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('cancel')}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => {
                                    handleClearError();
                                    closePopup(onClose);
                                }}
                            />
                        </div>
                        <div className="btn-save">
                            <CustomButton
                                textStyle={{ textTransform: 'capitalize' }}
                                name={formState.id ? t('update') : t('save')}
                                enable={formState?.value !== "" && true}
                                loading={getMapping().state.create.loading == LoadingType.pending || getMapping().state.update.loading == LoadingType.pending}
                                backgroundColor={THEME.buttonColor1}
                                onClick={handleSave}
                            />
                        </div>
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>
    )

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}

export default SystemWideAddPopup;