import { useTranslation } from "react-i18next";
import { Switch, Route, RouteComponentProps } from "react-router-dom";
import PageNavHeader from "../../components/page_header/page_nav_header";
import { AppRoutes, getReportCenterNavHeaderProps } from "../../routes";
import ReportCenterPage from "./pages/dashboard/dashboard";
import InDevelopment from "../../others/in_development";
import SalesCallSummaryReport from "./pages/sales_and_marketing/sales_call_summary_report/sales_call_summary_report";
import MarkettingSummaryReport from "./pages/sales_and_marketing/marketing_summary_report/marketing_summary_report";
import JobOrdersReport from "./pages/job_orders/open_job_orders_report/open_job_orders_report";
import JobOrdersHistoryReport from "./pages/job_orders/job_orders_history_report/job_orders_history_report";
import ArrivalCheckCallsReport from "./pages/service/arrival_check_calls_report/arrival_check_calls_report";
import EndOfFirstDaysCallsReport from "./pages/service/end_of_first_days_calls_report/end_of_first_days_calls_report";
import EndOfSecondDaysCallsReport from "./pages/service/end_of_second_days_calls_report/end_of_second_days_calls_report";
import FridayCallsReports from "./pages/service/friday_calls_reports/friday_calls_reports";
import PayCheckRegister from "./pages/payroll_tax/paycheck_register/paycheck_register";
import NewHireReport from "./pages/payroll_tax/new_hire_report/new_hire_report";
import InvoiceRegister from "./pages/finance_accounting/invoice_register/invoice_register";
import PayrollJournal from "./pages/payroll_tax/payroll_journal/payroll_journal";
import PaymentRegister from "./pages/payroll_tax/payment_register/payment_register";
import CashReceipts from "./pages/finance_accounting/cash_receipts/cash_receipts";
import PayrollCashRequiredPage from "./pages/payroll_tax/payroll_cash_req/payroll_cash_req";
import ARAgingReport from "./pages/finance_accounting/ar_aging_report/ar_aging_report";
import GrossProfitReportPage from "./pages/finance_accounting/gross_profit/gross_profit_report";
import PayrollDeductions from "./pages/payroll_tax/payroll_deductions/payroll_deductions";
import RCWCcode from "./pages/payroll_tax/work_comp_code/rc_work_comp_code";
import TaxByEmployee from "./pages/payroll_tax/tax_by_employee/tax_by_employee";
import EmployerTaxReport from "./pages/payroll_tax/employer_tax/employer_tax_report";
import CompaniesBilled from "./pages/companies/companies_billed/companies_billed";
import EmployeeAssignmentReport from "./pages/talent/employee_assignment_report/employee_assignment_report";
import AvailabilityReport from "./pages/talent/availability_report/availability_report";
import HolidayReport from "./pages/payroll_tax/holiday_report/holiday_report";
import VacationReport from "./pages/payroll_tax/vacation_report/vacation_report";
import TerminationReport from "./pages/payroll_tax/termination_report/termination_report";
import MasterTaxReportsTabNav from "./master_tax_report_nav";
import FourHourGuaranteeCallsReports from "./pages/service/four_hour_guarantee_report/four_hour_guarantee_report";
import JobConfirmationCallsReport from "./pages/service/job_confirmation_calls_report/job_confirmation_calls_report";
import ThirtyDayCallsReports from "./pages/service/thirty_day_calls_report/thirty_day_calls_report";
import SixtyDayCallsReports from "./pages/service/sixty_day_calls_report/sixty_day_calls_report";
import NinetyDayCallsReport from "./pages/service/ninety_day_calls_report/ninety_day_calls_report";


interface Props extends RouteComponentProps<any> { }

export const ReportCenterNavigation: React.FunctionComponent<Props> = (props: Props) => {
    const { history, location, match } = props;
    const { t } = useTranslation();
    const headerOptions = getReportCenterNavHeaderProps(location, match, t);

    return (
        <PageNavHeader data={headerOptions} history={history} location={location}>
            <Switch>
                <Route path={AppRoutes.reportCenterTalentAvailabilityReport} component={AvailabilityReport} />
                <Route path={AppRoutes.reportCenterTalentAssignmentReport} component={InDevelopment} />
                <Route path={AppRoutes.reportCenterSCSRPage} component={SalesCallSummaryReport} />
                <Route path={AppRoutes.reportCenterMSRPage} component={MarkettingSummaryReport} />
                <Route path={AppRoutes.reportCenterOJORPage} component={JobOrdersReport} />
                <Route path={AppRoutes.reportCenterJOHRPage} component={JobOrdersHistoryReport} />
                <Route path={AppRoutes.reportCenterACCRPage} component={ArrivalCheckCallsReport} />
                <Route path={AppRoutes.reportCenterEOFDCRPage} component={EndOfFirstDaysCallsReport} />
                <Route path={AppRoutes.reportCenterEOSDCRPage} component={EndOfSecondDaysCallsReport} />
                <Route path={AppRoutes.reportCenterFCRPage} component={FridayCallsReports} />
                <Route path={AppRoutes.reportCenterFHGRPage} component={FourHourGuaranteeCallsReports} />
                <Route path={AppRoutes.reportCenterJCCRPage} component={JobConfirmationCallsReport} />
                <Route path={AppRoutes.reportCenterTDRPage} component={ThirtyDayCallsReports} />
                <Route path={AppRoutes.reportCenterSDRPage} component={SixtyDayCallsReports} />
                <Route path={AppRoutes.reportCenterNDRPage} component={NinetyDayCallsReport} />
                <Route path={AppRoutes.reportPayCheckRegister} component={PayCheckRegister} />
                <Route path={AppRoutes.reportCenterNewHireReportPage} component={NewHireReport} />
                <Route path={AppRoutes.terminationReport} component={TerminationReport} />
                <Route path={AppRoutes.reportCenterInvoiceRegisterPage} component={InvoiceRegister} />
                <Route path={AppRoutes.reportCenterCashReceiptsPage} component={CashReceipts} />
                <Route path={AppRoutes.reportCenterARAgingReportPage} component={ARAgingReport} />
                <Route path={AppRoutes.reportCenterPayrollJournalPage} component={PayrollJournal} />
                <Route path={AppRoutes.reportCenterPayrollDeductionsPage} component={PayrollDeductions} />
                <Route path={AppRoutes.reportCenterPaymentRegisterPage} component={PaymentRegister} />
                <Route path={AppRoutes.reportCenterPayrollCashPage} component={PayrollCashRequiredPage} />
                <Route path={AppRoutes.reportCenterEmployeeAssignmentReportPage} component={EmployeeAssignmentReport} />
                <Route path={AppRoutes.reportCenterGrossProfitPage} component={GrossProfitReportPage} />
                <Route path={AppRoutes.reportCenterWorkCompCode} component={RCWCcode} />
                <Route path={AppRoutes.reportCenterTaxByEmployee} component={TaxByEmployee} />
                <Route path={AppRoutes.reportCenterEmployerTax} component={EmployerTaxReport} />
                <Route path={AppRoutes.reportCenterCompaniesBilled} component={CompaniesBilled} />
                <Route path={AppRoutes.reportCenterEmpHoliday} component={HolidayReport} />
                <Route path={AppRoutes.reportCenterVacation} component={VacationReport} />
                <Route path={AppRoutes.masterTaxReports} component={MasterTaxReportsTabNav} />

                <Route path={'/'} component={ReportCenterPage} />
            </Switch>
        </PageNavHeader>
    );
}

