import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType, ServiceCallsTypes } from "../../../../enums";
import { AppError, IServiceCall } from "../../../../interfaces";
import * as thunk from "./service_calls_thunk";

export interface ServiceCallsListState {
    [ServiceCallsTypes.arrival_calls]: IServiceCall[],
    [ServiceCallsTypes.end_of_first_day_calls]: IServiceCall[],
    [ServiceCallsTypes.end_of_second_day_calls]: IServiceCall[],
    [ServiceCallsTypes.friday_calls]: IServiceCall[],
    [ServiceCallsTypes.four_hour_guarantee]: IServiceCall[],
    [ServiceCallsTypes.end_of_assignment_call]: IServiceCall[],
    [ServiceCallsTypes.incomplete_calls]: IServiceCall[],
    [ServiceCallsTypes.job_confirmation_calls]: IServiceCall[],
    [ServiceCallsTypes.thirty_day_calls]: IServiceCall[],
    [ServiceCallsTypes.sixty_day_calls]: IServiceCall[],
    [ServiceCallsTypes.ninety_day_calls]: IServiceCall[],
    error?: AppError | null,
    loading: LoadingType,
}



const initialState: ServiceCallsListState = {
    [ServiceCallsTypes.arrival_calls]: [],
    [ServiceCallsTypes.end_of_first_day_calls]: [],
    [ServiceCallsTypes.end_of_second_day_calls]: [],
    [ServiceCallsTypes.friday_calls]: [],
    [ServiceCallsTypes.four_hour_guarantee]: [],
    [ServiceCallsTypes.end_of_assignment_call]: [],
    [ServiceCallsTypes.incomplete_calls]: [],
    [ServiceCallsTypes.job_confirmation_calls]: [],
    [ServiceCallsTypes.thirty_day_calls]: [],
    [ServiceCallsTypes.sixty_day_calls]: [],
    [ServiceCallsTypes.ninety_day_calls]: [],
    error: null,
    loading: LoadingType.idle,
}


export const serviceCallsListSlice = createSlice({
    name: 'serviceCallsList',
    initialState: initialState,
    reducers: {
        clearServiceCallsListError(state) {
            return { ...state, error: null };
        },
        clearServiceCallsListState(state) {
            return { ...state, loading: LoadingType.idle, error: null };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getDashboardServiceCallsList.pending,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.pending,
                    error: null,
                })
            )
            .addCase(
                thunk.getDashboardServiceCallsList.rejected,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.failed,
                    error: action.payload,
                })
            )
            .addCase(
                thunk.getDashboardServiceCallsList.fulfilled,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.succeeded,
                    error: null,
                    ...action.payload,
                })
            )
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearServiceCallsListError, clearServiceCallsListState } = serviceCallsListSlice.actions;
export const selectState = (state: ServiceCallsListState) => state;
export const selectLoading = (state: ServiceCallsListState) => state.loading;
export const selectError = (state: ServiceCallsListState) => state.error;


