import { combineReducers } from "@reduxjs/toolkit";
import { arrivalCheckCallsReportListSlice } from "./arrival_check_calls_report/arrival_check_calls_report_reducer";
import { endOFDCReportListSlice } from "./end_of_1st_days_calls_report/end_of_1st_days_calls_report_reducer";
import { endOSDCReportListSlice } from "./end_of_2nd_days_calls_report/end_of_2nd_days_calls_report_reducer";
import { fridayCallsReportsListSlice } from "./friday_calls_reports/friday_calls_reports_reducer";
import { fourHourGuaranteeCallsReportsListSlice } from "./four_hour_guarantee_report/four_hour_guarantee_report_reducer";
import { jobConfirmationCallsReportsListSlice } from "./job_confirmation_calls_report/job_confirmation_calls_report_reducer";
import { ninetyDayCallsReportsListSlice } from "./ninety_day_calls_report/ninety_day_calls_report_reducer";
import { sixtyDayCallsReportsListSlice } from "./sixty_day_calls_report/sixty_day_calls_report_reducer";
import { thirtyDayCallsReportsListSlice } from "./thirty_day_calls_report/thirty_day_calls_report_reducer";

export const reportCenterServiceReducers = combineReducers({
    [arrivalCheckCallsReportListSlice.name]: arrivalCheckCallsReportListSlice.reducer,
    [endOFDCReportListSlice.name]: endOFDCReportListSlice.reducer,
    [endOSDCReportListSlice.name]: endOSDCReportListSlice.reducer,
    [fridayCallsReportsListSlice.name]: fridayCallsReportsListSlice.reducer,
    [fourHourGuaranteeCallsReportsListSlice.name]: fourHourGuaranteeCallsReportsListSlice.reducer,
    [jobConfirmationCallsReportsListSlice.name]: jobConfirmationCallsReportsListSlice.reducer,
    [ninetyDayCallsReportsListSlice.name]: ninetyDayCallsReportsListSlice.reducer,
    [sixtyDayCallsReportsListSlice.name]: sixtyDayCallsReportsListSlice.reducer,
    [thirtyDayCallsReportsListSlice.name]: thirtyDayCallsReportsListSlice.reducer,
});

export * from "./arrival_check_calls_report/arrival_check_calls_report_selector";
export * from "./arrival_check_calls_report/arrival_check_calls_report_thunk";

export * from "./end_of_1st_days_calls_report/end_of_1st_days_calls_report_selector";
export * from "./end_of_1st_days_calls_report/end_of_1st_days_calls_report_thunk";

export * from "./end_of_2nd_days_calls_report/end_of_2nd_days_calls_report_selector";
export * from "./end_of_2nd_days_calls_report/end_of_2nd_days_calls_report_thunk";

export * from "./friday_calls_reports/friday_calls_reports_selector";
export * from "./friday_calls_reports/friday_calls_reports_thunk";

export * from "./four_hour_guarantee_report/four_hour_guarantee_report_selector";
export * from "./four_hour_guarantee_report/four_hour_guarantee_report_thunk";

export * from "./job_confirmation_calls_report/job_confirmation_calls_report_selector";
export * from "./job_confirmation_calls_report/job_confirmation_calls_report_thunk";

export * from "./ninety_day_calls_report/ninety_day_calls_report_selector";
export * from "./ninety_day_calls_report/ninety_day_calls_report_thunk";

export * from "./sixty_day_calls_report/sixty_day_calls_report_selector";
export * from "./sixty_day_calls_report/sixty_day_calls_report_thunk";

export * from "./thirty_day_calls_report/thirty_day_calls_report_selector";
export * from "./thirty_day_calls_report/thirty_day_calls_report_thunk";
