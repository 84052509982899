import { createAsyncThunk } from "@reduxjs/toolkit";
import { TalentAPI,  } from "../../../../apis";
import { AppError, ITalent, ITalentsByPage, ITalentsByPagePayload, } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getTalentList = createAsyncThunk<
    Array<ITalent>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/universal-directory/talent/get',
    async (_, thunkAPI) => {
        try {
            const res = await TalentAPI.getTalents();
            if(typeof res.data === 'string') return [];
            return res.data as ITalent[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const getTalentsByPage = createAsyncThunk<
    ITalentsByPage,
    ITalentsByPagePayload,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talents/universal-directory/get-talents-by-page/post',
    async (payload, thunkAPI) => {
        try {
            const res = await TalentAPI.getTalentsByPage(payload);
            return res.data as ITalentsByPage;
        } catch (error) {
            return catchRequestError(error, thunkAPI)
        }
    }
)