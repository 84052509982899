import { useEffect, useState } from 'react';
import SimpleTabbar from '../../../../../components/simple_tab_bar/simple_tab_bar';
import TabNavigation from '../../../../../components/tab_navigation/tab_navigation';
import { THEME } from '../../../../../enums';
import { IDepartmentStructure, ISkillOption } from '../../../../../interfaces';
import { useAppDispatch } from '../../../../../redux/store';
import CompanyOverView from './company_overview/company_overview';
import './company_summery.scss';
import Department from './departments/departments';



interface Props {
    isDepartment: boolean,
    onAddDepartment: (department_id?: string, type?: string) => void,
    onRemoveDepartment: (value: { id: string, value: string }) => void,
    onAddOption: (type: string) => void,
    onRemoveOption: (type: string, value: ISkillOption) => void,
    onDepartmentClick: (value: IDepartmentStructure, parent_department_id?: string) => void,
}

const CompanySummery: React.FunctionComponent<Props> = (props) => {
    const dispatch = useAppDispatch();
    const [tabIndex, setTabIndex] = useState<number>(0);

    // Load the tab index from localStorage when the component mounts
    useEffect(() => {
        const savedTabIndex = localStorage.getItem('selectedTabIndex');
        if (savedTabIndex) {
            setTabIndex(Number(savedTabIndex));
        }
    }, []);

    // Store the tab index in localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('selectedTabIndex', tabIndex.toString());
    }, [tabIndex]);

    return (
        <div className="cd-cs-container">
            {props.isDepartment === false ? <SimpleTabbar
                tabs={getTabList()}
                selected={tabIndex}
                onChange={(value) => setTabIndex(value)}
            /> :
                <SimpleTabbar
                    tabs={getDeptTabList()}
                    selected={tabIndex}
                    onChange={(value) => setTabIndex(value)}
                />
            }
        </div>
    );


    function getTabList() {
        return [
            {
                title: 'company_overview',
                content: <CompanyOverView
                    onAddOption={props.onAddOption}
                    onRemoveOption={props.onRemoveOption}
                />,
            },
            {
                title: 'departments',
                content: <Department
                    onAddDepartment={props.onAddDepartment}
                    onRemove={props.onRemoveDepartment}
                    onDepartmentClick={props.onDepartmentClick}
                />,
            },
        ];
    }

    function getDeptTabList() {
        return [
            {
                title: 'department_overview',
                content: <CompanyOverView
                    onAddOption={props.onAddOption}
                    onRemoveOption={props.onRemoveOption}
                />,
            },
            {
                title: 'departments',
                content: <Department
                    onAddDepartment={props.onAddDepartment}
                    onRemove={props.onRemoveDepartment}
                    onDepartmentClick={props.onDepartmentClick}
                    isDeptSummary={true}
                />,
            },
        ];
    }


}

export default CompanySummery;