import { IIMyCallDeckByPagePayload } from "../interfaces/my_call_deck";
import interceptor from "./interceptor";
import UrlBuilder from "./url_builder";

export const MyCallDeckAPI = {
  getAllMyCallDeck() {
    return interceptor.get(UrlBuilder.myCallDeck);
  },
  getAllMyCallDeckListByPage(doc: IIMyCallDeckByPagePayload) {
    let url = `${UrlBuilder.myCallDeckListByPage}?page=${doc.page}&size=${doc.size}`;
    if (doc.search_str) {
      url += `&search_str=${doc.search_str}`;
    }
    if (doc.filter_ids) return interceptor.post(url, doc.filter_ids);
    return interceptor.post(url);
  },
 
};
