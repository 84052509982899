import { IPayrollOption } from "./company"

export interface IJobOrder {
    id: string,
    company_name: string,
    department_name: string,
    company_id: string,
    department_id: string,
    job_title: string,
    rating: number,
    job_status: string,
    regular_pay_rate: number,
    regular_bill_rate: number,
    wc_code: string | null,
    requested: number,
    placed: number,
    over_under_placed: number,
    open_orders: number,
    account_managers?: string[],
    recruiters?: string[],
    engagement_rating: number,
    engagement_level: string,
}

export interface IJobOrderDetail {
    id: string,
    company_id: string | null,
    department_id: string | null,
    job_title: string,
    is_deleted: boolean,
    regular_pay_rate: number,
    regular_bill_rate: number,
    overtime_pay_rate: number | null,
    overtime_bill_rate: number | null,
    double_time_pay_rate: number | null,
    double_time_bill_rate: number | null,
    status: string,
    placement_type: string,
    job_order_source: string,
    wc_code: string | null,
    branch_id: string | null,
    agency_id: string | null,
    rating: number,
    requested: number,
    placed: number,
    over_under_placed: number,
    start_date: number | null,
    end_date: number | null,
    order_date: number | null;
    account_manager: string | null,
    recruiter: string | null,
    logo: string | null,
    attach_timecard: boolean,
    bill_to_attn: string | null,
    work_comp_code: string | null,
    contact_id_for_placement: string | null,
    ordered_by_contact_id: string | null,
    report_to_contact_id: string | null,
    company_name: string,
    department_name: string,
    company_address: string,
    contact_name_for_placement: string,
    ordered_by_contact_name: string,
    report_to_contact_name: string,
    street_address: string,
    city: string,
    state: string,
    zip_code: string,
    success_profile_question_answers: ISuccessProfileQA[],
    placement_type_id: string | null,
    job_order_source_id: string | null,
    placement_type_value: string,
    job_order_source_value: string,
    service_calls: string[],
    triple_time_pay_rate: number | null,
    triple_time_bill_rate: number | null,
    account_managers: Array<string>,
    recruiters: Array<string>,
    open_orders?: number,
    open_under_placed: 0,
    certifications: Array<string>,
    credentials: Array<string>,
    education: Array<string>,
    professional_skills: Array<string>,
    safety_equipment: Array<string>,
    job_status: string,
    shift_name: string | null,
    start_time: number | null,
    end_time: number | null,
    break_in_minutes: number | null,
    estimated_shift_hours: number | null,
    hourly_pay_type?: number,
    salary_pay_type?: number,
    fee_type_flat_rate?: number,
    fee_type_percentage?: number,
    fee_amount?: number,
    pay_type?: string,
    fee_type?: string,
    engagement_rating?: number,
    engagement_level?: string,
    work_schedule: {
        FRIDAY: boolean,
        FRIDAY_END_TIME: number | null,
        FRIDAY_START_TIME: number | null,
        MONDAY: boolean,
        MONDAY_END_TIME: number | null,
        MONDAY_START_TIME: number | null,
        SATURDAY: boolean,
        SATURDAY_END_TIME: number | null,
        SATURDAY_START_TIME: number | null,
        SUNDAY: boolean,
        SUNDAY_END_TIME: number | null,
        SUNDAY_START_TIME: number | null,
        THURSDAY: boolean,
        THURSDAY_END_TIME: number | null,
        THURSDAY_START_TIME: number | null,
        TUESDAY: boolean,
        TUESDAY_END_TIME: number | null,
        TUESDAY_START_TIME: number | null,
        WEDNESDAY: boolean,
        WEDNESDAY_END_TIME: number | null,
        WEDNESDAY_START_TIME: number | null,
    },

}
export class IUpdateJobOrderDetail {
    id: string;
    company_id: string | null;
    department_id: string | null;
    job_title: string;
    is_deleted: boolean;
    regular_pay_rate: string;
    regular_bill_rate: string;
    overtime_pay_rate: string | null;
    overtime_bill_rate: string | null;
    double_time_pay_rate: string | null;
    double_time_bill_rate: string | null;
    status: string;
    placement_type: string;
    job_order_source: string;
    wc_code: string | null;
    branch_id: string | null;
    agency_id: string | null;
    rating: number;
    requested: number;
    placed: number;
    over_under_placed: number;
    start_date: number | null;
    end_date: number | null;
    order_date: number | null;
    account_manager: string | null;
    recruiter: string | null;
    logo: string | null;
    attach_timecard: boolean;
    bill_to_attn: string | null;
    work_comp_code: string | null;
    contact_id_for_placement: string | null;
    ordered_by_contact_id: string | null;
    report_to_contact_id: string | null;
    company_name: string;
    department_name: string;
    company_address: string;
    contact_name_for_placement: string;
    ordered_by_contact_name: string;
    report_to_contact_name: string;
    street_address: string;
    city: string;
    state: string;
    zip_code: string;
    success_profile_question_answers: ISuccessProfileQA[];
    placement_type_id: string | null;
    job_order_source_id: string | null;
    placement_type_value: string;
    job_order_source_value: string;
    service_calls: string[];
    triple_time_pay_rate: number | null;
    triple_time_bill_rate: number | null;
    account_managers: Array<string>;
    recruiters: Array<string>;
    open_orders?: number;
    open_under_placed: 0;
    certifications: Array<string>
    credentials: Array<string>;
    education: Array<string>;
    professional_skills: Array<string>;
    safety_equipment: Array<string>;
    job_status: string;
    shift_name: string | null;
    start_time: number | null;
    end_time: number | null;
    break_in_minutes: number | null;
    estimated_shift_hours: number | null;
    engagement_rating?: number;
    engagement_level?: string;
    hourly_pay_type?: number;
    salary_pay_type?: number;
    fee_type_flat_rate?: number;
    fee_type_percentage?: number;
    fee_amount?: number;
    pay_type?: string;
    fee_type?: string;
    work_schedule: {
        FRIDAY: boolean,
        FRIDAY_END_TIME: string | undefined,
        FRIDAY_START_TIME: string | undefined,
        MONDAY: boolean,
        MONDAY_END_TIME: string | undefined,
        MONDAY_START_TIME: string | undefined,
        SATURDAY: boolean,
        SATURDAY_END_TIME: string | undefined,
        SATURDAY_START_TIME: string | undefined,
        SUNDAY: boolean,
        SUNDAY_END_TIME: string | undefined,
        SUNDAY_START_TIME: string | undefined,
        THURSDAY: boolean,
        THURSDAY_END_TIME: string | undefined,
        THURSDAY_START_TIME: string | undefined,
        TUESDAY: boolean,
        TUESDAY_END_TIME: string | undefined,
        TUESDAY_START_TIME: string | undefined,
        WEDNESDAY: boolean,
        WEDNESDAY_END_TIME: string | undefined,
        WEDNESDAY_START_TIME: string | undefined,
    };
}

export interface ICreateJob {
    id?: string,
    company_id: string | null,
    department_id: string | null,
    job_title: string,
    regular_pay_rate: number,
    regular_bill_rate: number,
    overtime_pay_rate: number | null,
    overtime_bill_rate: number | null,
    double_time_pay_rate: number | null,
    double_time_bill_rate: number | null,
    status: string,
    placement_type: string,
    job_order_source: string,
    wc_code: string | null,
    branch_id?: string | null,
    agency_id?: string | null,
    rating: number,
    requested: number,
    placed: number,
    over_under_placed: number,
    start_date: number | null,
    end_date: number | null,
    account_manager: string | null,
    recruiter?: string | null,
    attach_timecard: boolean,
    bill_to_attn: string | null,
    work_comp_code: string | null,
    contact_id_for_placement: string | null,
    ordered_by_contact_id: string | null,
    report_to_contact_id: string | null,
    company_name: string,
    department_name: string,
    company_address: string,
    contact_name_for_placement: string,
    ordered_by_contact_name: string,
    report_to_contact_name: string,
    street_address: string,
    city: string,
    state: string,
    zip_code: string,
    success_profile_question_answers: ISuccessProfileQA[],
    placement_type_id: string | null,
    job_order_source_id: string | null,
    placement_type_value: string,
    job_order_source_value: string,
    service_calls: string[],
    triple_time_pay_rate: number | null,
    triple_time_bill_rate: number | null,
    account_managers: Array<string>,
    recruiters: Array<string>,
    open_orders?: number,
    open_under_placed: 0,
    certifications: Array<string>,
    credentials: Array<string>,
    education: Array<string>,
    professional_skills: Array<string>,
    safety_equipment: Array<string>,
    job_status: string,
    shift_name: string | null,
    start_time: number | null,
    end_time: number | null,
    break_in_minutes: number | null,
    estimated_shift_hours: number | null,
    hourly_pay_type?: number,
    salary_pay_type?: number,
    fee_type_flat_rate?: number,
    fee_type_percentage?: number,
    fee_amount?: number,
    pay_type?: string,
    fee_type?: string,
}


export interface ICreateJobForm {
    id?: string,
    company_id: string | null,
    department_id: string | null,
    job_title: string,
    regular_pay_rate: number,
    regular_bill_rate: number,
    overtime_pay_rate: number | null,
    overtime_bill_rate: number | null,
    double_time_pay_rate: number | null,
    double_time_bill_rate: number | null,
    status: string,
    placement_type: string,
    job_order_source: string,
    wc_code: string | null,
    branch_id?: string | null,
    agency_id?: string | null,
    rating: number,
    requested: number,
    placed: number,
    over_under_placed: number,
    start_date: number | null,
    end_date: number | null,
    order_date: number | null;
    account_manager: string | null,
    recruiter?: string | null,
    attach_timecard: boolean,
    bill_to_attn: string | null,
    work_comp_code: string | null,
    contact_id_for_placement: string | null,
    ordered_by_contact_id: string | null,
    report_to_contact_id: string | null,
    company_name: string,
    department_name: string,
    company_address: string,
    contact_name_for_placement: string,
    ordered_by_contact_name: string,
    report_to_contact_name: string,
    street_address: string,
    city: string,
    state: string,
    zip_code: string,
    success_profile_question_answers: { [key: string]: ISuccessProfileQA },
    placement_type_id: string | null,
    job_order_source_id: string | null,
    placement_type_value: string,
    job_order_source_value: string,
    service_calls: { [key: string]: boolean },
    triple_time_pay_rate: number | null,
    triple_time_bill_rate: number | null,
    account_managers: Array<string>,
    recruiters: Array<string>,
    open_orders?: number,
    open_under_placed: 0,
    certifications: Array<string>,
    credentials: Array<string>,
    education: Array<string>,
    professional_skills: Array<string>,
    safety_equipment: Array<string>,
    job_status: string,
    shift_name: string | null,
    start_time: number | null,
    end_time: number | null,
    break_in_minutes: number | null,
    estimated_shift_hours: number | null,
    hourly_pay_type?: number,
    salary_pay_type?: number,
    fee_type_flat_rate?: number,
    fee_type_percentage?: number,
    fee_amount?: number,
    pay_type?: string,
    fee_type?: string,
}

export interface IAddJobStaff {
    job_id: string,
    user_id_list: Array<string>,
    role: string,
}
export interface IRemoveJobStaff {
    job_id: any,
    user_id: string,
    role: string,
}


export interface ISuccessProfileQA {
    question_id: string,
    answer: string,
    question_value: string
}

export interface IJobServiceProfile {
    id: string,
    job_id: string,
    company_id: string,
    department_id: string,
    onboarding_steps: string[] | null,
    check_delivery: string[] | null,
    service_calls: string[] | null,
    dh_service_calls: string[];
    time_clock: boolean,
    mileage_rate: number | null,
    payroll_options: IPayrollOption,
    is_deleted: boolean,
    shift_name: string | null,
    start_time: number | null,
    end_time: number | null,
    break_in_minutes: number | null,
    estimated_shift_hours: number | null
}

export interface IJobServiceUpdateProfile {
    id: string,
    job_id: string,
    company_id: string,
    department_id: string,
    onboarding_steps: string[] | null,
    check_delivery: string[] | null,
    service_calls: string[] | null,
    dh_service_calls: string[];
    time_clock: boolean,
    mileage_rate: string | null,
    payroll_options: IPayrollOption,
    is_deleted: boolean,
    shift_name: string | null,
    start_time: string | null,
    end_time: string | null,
    break_in_minutes: number | null,
    estimated_shift_hours: number | null
}
export interface IJobSkillSet {
    id: string,
    skill_set: string,
    sector_id: string,
}
export interface IAddJobSkill {
    job_id: string,
    skill_id_list: Array<string>,
}
export interface IRemoveJobSkill {
    job_id: string,
    skill_id: string,
}

export interface IJobCredential {
    id: string,
    credential: string,
    sector_id: string,
}
export interface IAddJobCredentials {
    job_id: string,
    credential_id_list: Array<string>,
}
export interface IRemoveJobCredentials {
    job_id: string,
    credential_id: string,
}


/// Certifications
export interface IJobCertification {
    id: string,
    certification: string,
    sector_id: string,
}

export interface IAddJobCertificate {
    job_id: string,
    certification_id_list: Array<string>,
}
export interface IRemoveJobCertificate {
    job_id: string,
    certification_id: string,
}

/// Education

export interface IJobEducationRequirement {
    id: string,
    education_requirement: string,
    sector_id: string,
}

export interface IAddJobEducation {
    job_id: string,
    education_id_list: Array<string>,
}
export interface IRemoveJobEducation {
    job_id: string,
    education_id: string,
}


/// Equipment
export interface IJobRequiredEquipment {
    id: string,
    required_equipment: string,
    sector_id: string,
}

export interface IAddJobEquipment {
    job_id: string,
    equipment_id_list: Array<string>,
}
export interface IRemoveJobEquipment {
    job_id: string,
    equipment_id: string,
}
export interface IJobOrderSuccessProfile {
    id: string,
    job_id: string,
    company_id: string,
    department_id: string,
    answers: ISuccessProfileQA[],
    is_deleted: boolean
}

export interface IJobTalentMatches {
    talent_id: string,
    talent_first_name: string,
    talent_middle_name: string,
    talent_last_name: string,
    email: string,
    phone: string,
    engagement_rating: number,
    preferred_contact_method: string,
    matched_skills: Array<string>
    last_engagement_date: number | null,
}

export interface IJobCompanyMatches {
    company_id: string,
    company_name: string,
    matched_skills: Array<string>,
    engagement_rating: number
}


export interface IJobsList {
    id: string,
    name: string
}

export interface IJobOrdersByPage {
    items: IJobOrder[];
    total: number,
    page: number,
    size: number,
    pages: number
}
export interface IJobOrdersByPagePayload {
    page: number,
    size: number,
    filter_ids?: {
        acc_manager_ids?: Array<string>,
        recruiter_ids?: Array<string>,
        statuses?: Array<string>
    }
    company_id?: string | null;
    branch_id?: string,
    contact_id?: string | null,
    department_id?: string | null,
    search_str?: string,
}