import { createAsyncThunk } from "@reduxjs/toolkit";
import { CompaniesAPI,  } from "../../../../apis";
import { AppError, ICompaniesByPage, ICompaniesByPagePayload, ICompany, IDepartment, } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getCompaniesList = createAsyncThunk<
    Array<ICompany | IDepartment>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@companies/universal-directory/companies/get',
    async (_, thunkAPI) => {
        try {
            const res = await CompaniesAPI.getCompanyDirectory();
            if(typeof res.data === 'string') return [];
            return res.data as  Array<ICompany | IDepartment>;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const getCompaniesByPage = createAsyncThunk<
    ICompaniesByPage,
    ICompaniesByPagePayload,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@companies/universal-directory/get-companies-by-page/post',
    async (payload, thunkAPI) => {
        try {
            const res = await CompaniesAPI.getCompaniesByPage(payload);
            return res.data as ICompaniesByPage;
        } catch (error) {
            return catchRequestError(error, thunkAPI)
        }
    }
)