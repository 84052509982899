import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./four_hour_guarantee_report_thunk";
import { IReportCenterService } from "../../../../interfaces/report_center_service";

export interface FourHourGuaranteeCallsReportsListState extends EntityState<IReportCenterService> {
    error?: AppError | null,
    loading: LoadingType,  
}

const fourHourGuaranteeCallsReportsListAdapter: EntityAdapter<IReportCenterService> = createEntityAdapter<IReportCenterService>({
    selectId: (doc) => doc.id,
})

const initialState: FourHourGuaranteeCallsReportsListState = fourHourGuaranteeCallsReportsListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const fourHourGuaranteeCallsReportsListSlice = createSlice({
    name: 'fourHourGuaranteeCallsReportsList',
    initialState: initialState,
    reducers: {
        clearFourHourGuaranteeCallsReportsListError(state) {
            return { ...state, error: null };
        },
        clearFourHourGuaranteeCallsReportsListState(state) {
            return fourHourGuaranteeCallsReportsListAdapter.removeAll({ ...state, loading: LoadingType.idle, error: null });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getFourHourGuaranteeCallsReportsList.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getFourHourGuaranteeCallsReportsList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getFourHourGuaranteeCallsReportsList.fulfilled, (state, action) => fourHourGuaranteeCallsReportsListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearFourHourGuaranteeCallsReportsListError, clearFourHourGuaranteeCallsReportsListState } = fourHourGuaranteeCallsReportsListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = fourHourGuaranteeCallsReportsListAdapter.getSelectors();
export const selectState = (state: FourHourGuaranteeCallsReportsListState) => state;


