import RenderInput from "../../../../../components/render_input/render_input";
import { FormInputOption, ServiceCall, ICreateJobForm, IDepartmentsList, WorkCompCode, JobOrderPlacement } from "../../../../../interfaces";
import { WorkCompConfiguration } from "../../../../../interfaces/work_comp_configuration";
import './step_six.scss';

interface Props {
    serviceCallsList: ServiceCall[],
    // workCompCodesList: WorkCompCode[],
    workCompCodesList: WorkCompConfiguration[],
    placementTypeList: JobOrderPlacement[],
    formState: ICreateJobForm,
    handleFieldChange: (id: string, value: any, parent?: string | undefined) => void,
    validators: ((value: {
        text: string;
        field: string;
    }) => string | null) | undefined,
}

const StepSixForm: React.FunctionComponent<Props> = (props) => {
    const { serviceCallsList, placementTypeList, workCompCodesList, formState, handleFieldChange, validators } = props;
    const updatedCompaniesList = workCompCodesList?.reduce<{ label: string; value: string }[]>((acc, wCCode) => {
        if (!acc.some(item => item.label === wCCode.work_comp_code)) {
            acc.push({ label: wCCode.work_comp_code, value: wCCode.id });
        }
        return acc;
    }, []);

    const returnPSRSDHMatchedList = placementTypeList.some(
        (placement) =>
          placement.id.includes(formState?.placement_type_id!) &&
          ['Professional Search', 'Retained Search', 'Direct Hire'].includes(placement.title)
      );
    const serviceCalls = returnPSRSDHMatchedList
        ? serviceCallsList.filter((serviceCall) =>
            ['Arrival Calls', '30 Day calls', '60 Day calls', '90 Day calls'].includes(serviceCall.service_call)
        )
        : serviceCallsList.filter((serviceCall) =>
            [
                'Arrival Calls',
                '4 Hour Guarantee',
                'End of 1st Day Calls',
                'End of 2nd Day Calls',
                'Friday Calls',
                'End of Assignment Call',
                'Job Confirmation Calls',
            ].includes(serviceCall.service_call)
        );
    const formOptions: (FormInputOption | FormInputOption[])[] = [
        serviceCalls.map((doc, index) => {
            return {
                field: doc.id,
                parentField: 'service_calls',
                type: "switch",
                label: doc.service_call,
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: '',
                labelPosition: "right",
            } as FormInputOption;
        }),
        [
            {
                field: "start_date",
                type: "date",
                label: 'what_is_the_expected_start_date',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: '',
                setHoursToZero: true,
            },
            {
                field: "requested",
                type: "number",
                label: 'how_many_people_are_requested_for_this_job_order',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: '',
                min: 0,
                step: 1,
                
            },

        ],
        [
            {
                field: "end_date",
                type: "date",
                label: 'what_is_the_projected_end_date_for_this_job_order',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: '',
                setHoursToZero: true,
            },
            // {
            //     field: "work_comp_code",
            //     type: "selector",
            //     label: 'whats_the_work_comp_code_for_this_job_order',
            //     secure: false,
            //     required: false,
            //     placeholder: 'select',
            //     keyboardtype: '',
            //     multi: false,
            //     selectorList: updatedCompaniesList.map(doc => ({label: doc.label, value: doc.value}))
                
            // },
        ],
    ]

    return (
        <div className="jb-f-stp-six">
            {formOptions.map((doc, index) => {
                if (Array.isArray(doc)) {
                    return (
                        <div key={index + 'row'} className="jb-f-row">
                            {doc.map((subDoc, subIndex) => {
                                return (
                                    <RenderInput
                                        key={index + subIndex}
                                        doc={subDoc}
                                        index={subIndex}
                                        formState={formState}
                                        inputValue={(subDoc.type === "switch" && subDoc.parentField) ? formState[subDoc.parentField][subDoc.field] : undefined}
                                        handleFieldChange={handleFieldChange}
                                        formValidators={validators}
                                        className={"jb-f-input-holder"}
                                    />
                                );
                            })}
                        </div>
                    );
                }

                return (
                    <RenderInput
                        key={index}
                        doc={doc}
                        index={index}
                        formState={formState}
                        inputValue={(doc.type === "switch" && doc.parentField) ? formState[doc.parentField][doc.field] : undefined}
                        handleFieldChange={handleFieldChange}
                        formValidators={validators}
                        className={"jb-f-input-holder"}
                    />
                )
            })}
        </div>
    )
}

export default StepSixForm;