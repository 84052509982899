export enum ServiceCallsTypes {
    arrival_calls = "arrival_calls",
    end_of_first_day_calls = "end_of_first_day_calls",
    end_of_second_day_calls = "end_of_second_day_calls",
    friday_calls = "friday_calls",
    four_hour_guarantee = "four_hour_guarantee",
    end_of_assignment_call = "end_of_assignment_call",
    incomplete_calls = "incomplete_calls",
    job_confirmation_calls = "job_confirmation_calls",
    thirty_day_calls = "thirty_day_calls",
    sixty_day_calls = "sixty_day_calls",
    ninety_day_calls = "ninety_day_calls"
}