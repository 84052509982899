import { createSelector } from "@reduxjs/toolkit";
import { selectAll, selectState } from "./job_confirmation_calls_report_reducer";
import { IRootState } from "../../../store";

const select = (state: IRootState) => state.reportCenter.reportCenterService.jobConfirmationCallsReportsList;
export const selectJobConfirmationCallsReportsListState = createSelector(select, selectState);
export const selectJobConfirmationCallsReportsList = createSelector(
    select,
    selectAll
);
