import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import { AppError, IAssignmentsByPage } from "../../../../../interfaces";
import * as thunk from './assignment_list_thunk';
export interface SingleTalentAssignmentState {
    error?: AppError | null;
    loading: LoadingType | null;
    response: IAssignmentsByPage | null;
}

const initialState: SingleTalentAssignmentState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}


export const talentAssignmentSlice = createSlice({
    name: 'assignmentsList',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getTalentAssignmentsListByPageById.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getTalentAssignmentsListByPageById.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, response: null, }))
            .addCase(thunk.getTalentAssignmentsListByPageById.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const { } = talentAssignmentSlice.actions;
export const selectState = (state: SingleTalentAssignmentState) => state;

