import { createAsyncThunk } from "@reduxjs/toolkit";
import { AssignmentsAPI } from "../../../../../apis";
import { AppError, IAssignmentApiQuery, IAssignmentsByPage } from "../../../../../interfaces";
import { catchRequestError } from "../../../../../utils";

export const getJoborderAssignmentsListByPageById = createAsyncThunk<
    IAssignmentsByPage,
    IAssignmentApiQuery,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/get-assignments-by-job-order-by-page/post',
    async (payload, thunkAPI) => {
        try {
            const res = await AssignmentsAPI.getAllAssignmentsByPage(payload)
            if (typeof res.data === 'string') return [];
            return res.data as IAssignmentsByPage;
        } catch (error) {
            return catchRequestError(error, thunkAPI)
        }
    }
)