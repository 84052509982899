import { ArrowUpward } from "@material-ui/icons";
import { Rating } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import CustomFormSelect, {
  CustomMultiFormSelect,
} from "../../../../components/form_selector/form_select";
import SearchBar from "../../../../components/search_bar";
import SortableTable, {
  TableData,
} from "../../../../components/sortable_table/sortable_table";
import TableEmpty, {
  TableErrorHandler,
  TableFilterEmpty,
  TableLoading,
} from "../../../../components/table_empty/table_empty";
import { LoadingType, TALENT_STATUS, THEME } from "../../../../enums";
import { RatingIcon } from "../../../../icons";
import { Assignment, IAssignmentsByPage } from "../../../../interfaces";
import {
  selectAssignmentsList,
  selectAssignmentsListState,
} from "../../../../redux/assignments/universal_directory/assignments_list/assignments_list_selector";
import { getAssignmentsByPage } from "../../../../redux/assignments/universal_directory/assignments_list/assignments_list_thunk";
import {
  getCertificationsList,
  getCredentialsList,
  getEducationList,
  getSkillSetList,
  postSnackbarMessage,
  useAppDispatch,
  useAppSelector,
  selectRoleUsersListState,
  selectRecruiterRoleUsersList,
  selectAccountManagerRoleUsersList,
  getRoleUsersList,
  selectTextEmAllState,
  selectProfileState,
  getTEADraftBroadCast,
} from "../../../../redux/store";
import { AppRoutes } from "../../../../routes";
import {
  convertNumberToMoney,
  currencyConversion,
  CustomButton,
  CustomCheckBox,
  enforceUSFormat,
  getTableAccessIcon,
  getTextEmAllIcon,
} from "../../../../utils";
import { statusOptions } from "../../../../variables";
import "./universal_directory.scss";
import { TEADraftBroadCast } from "../../../../interfaces/text_em_all";
import { Portal } from "react-portal";
import BroadcastToTalentPopup from "../../../../components/broadcast_talent/broadcast_talent";
import { textEmAllSliceActions } from "../../../../redux/textEmAll/text_Em_All_reducer";
import BroadcastToTalentErrorPopup from "../../../../components/broadcast_talent/broadcast_talent_error";
import Pagination from "../../../components/pagination/pagination";

interface Props extends RouteComponentProps<any> { }

const UniversalDirectory: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [showInactive, setShowInactive] = useState(false);
  const [sortedField, setSortedField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const dispatch = useAppDispatch();
  const textEmAllState = useAppSelector((state) => selectTextEmAllState(state));
  const profileState = useAppSelector((state) => selectProfileState(state));
  const canSendText =
    profileState?.profile?.texting &&
    profileState?.profile?.text_em_all_token !== null;

  const assignmentListState = useAppSelector((state) =>
    selectAssignmentsListState(state)
  );

  const getAssignments = (pageNum?: number, pageSize?: number) => {
    dispatch(getAssignmentsByPage({ page: pageNum ?? currentPage, size: pageSize ?? size, filter_ids: { acc_manager_ids: accountManagers, recruiter_ids: recruiters, statuses: status }, include_inactive: showInactive, search_str: search }));
    setCurrentPage(pageNum ?? currentPage);
  }

  const { loading, error } = useAppSelector((state) =>
    selectAssignmentsListState(state)
  );
  const roleUsersListState = useAppSelector((state) =>
    selectRoleUsersListState(state)
  );
  const accountManagersList = useAppSelector((state) =>
    selectAccountManagerRoleUsersList(state)
  );
  const recruitersList = useAppSelector((state) =>
    selectRecruiterRoleUsersList(state)
  );
  const [assignmentsList, setAssignmentsList] = useState<IAssignmentsByPage | null>(null);
  const [accountManagers, setAccountManagers] = useState<string[]>([]);
  const [recruiters, setRecruiters] = useState<string[]>([]);
  const [status, setStatus] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [resetPage, setResetPage] = useState<boolean>(false);
  const [size, setSize] = useState(25)

  useEffect(() => {
    dispatch(getSkillSetList());
    dispatch(getCertificationsList());
    dispatch(getCredentialsList());
    dispatch(getEducationList());
    dispatch(getRoleUsersList());
  }, []);

  useEffect(() => {
    getAssignments();
    return () => { };
  }, [accountManagers, recruiters, status, showInactive]);

  useEffect(() => {
    if (resetPage || search?.length === 0) return getAssignments(1)
    return () => {
      setResetPage(false)
    }
  }, [resetPage, search])

  useEffect(() => {
    if (error && error !== null && assignmentsList?.items?.length !== 0) {
      dispatch(postSnackbarMessage(error.message));
    }
  }, [error]);

  useEffect(() => {
    if (assignmentListState?.assignmentsByPage?.loading === LoadingType.succeeded && assignmentListState?.assignmentsByPage?.response) {
      setAssignmentsList(assignmentListState?.assignmentsByPage?.response)
      return () => { };
    }
  }, [assignmentListState?.assignmentsByPage?.loading]);

  const getFilteredList = () => {
    let list: Assignment[] = [...assignmentsList?.items! ?? []];
    let sortedList: Assignment[] | undefined;

    if (sortedField !== null) {
      sortedList = [...list].sort((a, b) => {
        const valueA =
          a[sortedField] !== null && a[sortedField] !== undefined
            ? typeof a[sortedField] == typeof "1"
              ? a[sortedField].trim().toLowerCase()
              : a[sortedField]
            : "";
        const valueB =
          b[sortedField] !== null
            ? typeof b[sortedField] == typeof "1"
              ? b[sortedField].trim().toLowerCase()
              : b[sortedField]
            : "";
        if (sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }

    return sortedList ?? list
  };

  const handleSortFieldChange = (value: string) => {
    if (sortedField && sortedField === value) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  };

  const handleAssignmentSelect = (value: Assignment): void => {
    props.history.push({
      pathname: `${AppRoutes.assignmentsDetailsPage}/${value.id}`,
      state: {
        id: value.id,
        name: value.talent_name + " - " + value.job_title,
      },
    });
  };

  const getRatingCell = (value: number) => {
    return <Rating name="read-only" value={value} readOnly />;
  };

  function handleJobTitleSelect(value: Assignment) {
    props.history.push({
      pathname: `${AppRoutes.jobDetailsPage}/${value.job_order_id}`,
      state: {
        id: value.job_order_id,
        name: value.job_title,
      },
    });
    return;
  }

  function handleCompanySelect(value: Assignment) {
    props.history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
      state: {
        id: value.company_id,
        name: value.company_name,
      },
    });
    return;
  }

  function handleDepartmentSelect(value: Assignment) {
    props.history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.department_id}`,
      state: {
        id: value.company_id,
        name: value.company_name,
        departmentId: value.department_id,
        departmentName: value.department_name,
      },
    });
    return;
  }

  function handleTalentSelect(value: Assignment) {
    props.history.push({
      pathname: `${AppRoutes.talentDetailsPage}/${value.talent_id}`,
      state: {
        id: value.talent_id,
        name: value.talent_name,
      },
    });
    return;
  }

  function getStatus(status: string) {
    if (status === TALENT_STATUS.active)
      return <span style={{ color: THEME.buttonColor17 }}>{t("active")}</span>;
    else if (status === TALENT_STATUS.inactive)
      return <span style={{ color: "#b1b1b1" }}>{t("in_active")}</span>;
    else return <span>{""}</span>;
  }
  const [broadcastTalent, setBroadcastTalent] = useState<boolean>(false);
  const [broadcastTalentError, setBroadcastTalentError] =
    useState<boolean>(false);
  useEffect(() => {
    dispatch(textEmAllSliceActions.clearState());
    return () => { };
  }, []);
  useEffect(() => {
    if (
      textEmAllState &&
      textEmAllState?.error !== null &&
      textEmAllState?.loading === LoadingType.failed
    ) {
      setBroadcastTalentError(true);
    }
    if (
      textEmAllState &&
      textEmAllState?.error === null &&
      textEmAllState?.loading === LoadingType.succeeded
    ) {
      setBroadcastTalent(true);
    }
    if (
      textEmAllState &&
      textEmAllState?.error === null &&
      textEmAllState?.loading === LoadingType.idle
    ) {
      setBroadcastTalent(false);
      setBroadcastTalentError(false);

    }
  }, [textEmAllState, textEmAllState.error]);
  const [formState] = useState<TEADraftBroadCast>({
    modified_by: "",
    created_by: "",
    modified_date: 0,
    talent_ids: [],
  });
  const [showMsg, setShowMsg] = useState<boolean>(false);
  const [showErrorMsg, setShowErrorMsg] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<
    Map<string, { id: string; name: string }>
  >(new Map());

  const handleMarkItem = (value: Assignment) => {
    const newSet = new Map(selectedItems);
    if (selectedItems.has(value.id)) {
      newSet.delete(value.id);
    } else {
      newSet.set(value.id, { id: value.talent_id, name: value.talent_name });
    }
    setSelectedItems(newSet);
  };
  const handleMarkAllItem = () => {
    const newSet = new Map<string, { id: string; name: string }>();
    if (selectedItems.size === getFilteredList().length) {
      setSelectedItems(newSet);
    } else {
      getFilteredList().forEach((doc) =>
        newSet.set(doc.id, { id: doc.talent_id, name: doc.talent_name })
      );
      setSelectedItems(newSet);
    }
  };
  const handleBroadCastTalent = (forceSend?: boolean) => {
    if (selectedItems.size !== 0) {
      const talentids_list = Array.from(selectedItems.values());
      dispatch(
        getTEADraftBroadCast({ forceSend: forceSend, data: { ...formState, talent_ids: talentids_list.map(ids => ids.id) } })
      );
      // setBroadcastTalent(true);
      setShowMsg(false);
    } else {
      setShowMsg(true);
      setShowErrorMsg(t("please_select_talent_to_broadcast"));
      setTimeout(() => setShowErrorMsg(""), 5000);
    }
  };

  const handlePageChange = (pageNum: number, size: number) => {
    getAssignments(resetPage ? 1 : pageNum, size)
    setSize(size ? size : assignmentsList?.size!)
  };

  const handleSearch = () => {
    setResetPage(true)
  }

  return (
    <div className="cps-ud-container contacts-ud assignments-ud">
      <div className="cps-ud-table-header">
        <div className="cps-ud-table-header-left">
          <div className="account-manager-selector">
            <CustomMultiFormSelect
              label={t("account_manager")}
              name={"account_manager"}
              list={accountManagersList.map((doc) => ({
                label: `${doc.first_name ?? ""} ${doc.last_name ?? ""}`,
                value: doc.id,
              }))}
              onChange={setAccountManagers}
              required={false}
              placeholder={t("select")}
              value={accountManagers}
              customStyle={{ width: "-webkit-fill-available" }}
            />
          </div>
          <div className="recruiter-selector">
            <CustomMultiFormSelect
              label={t("recruiter")}
              name={"recruiter"}
              list={recruitersList.map((doc) => ({
                label: `${doc.first_name ?? ""} ${doc.last_name ?? ""}`,
                value: doc.id,
              }))}
              onChange={setRecruiters}
              required={false}
              placeholder={t("select")}
              value={recruiters}
              customStyle={{ width: "-webkit-fill-available" }}
            />
          </div>
          <div className="company-status-selector">
            <CustomMultiFormSelect
              label={t("status")}
              name={"company_status"}
              list={[...statusOptions]}
              onChange={setStatus}
              required={false}
              placeholder={t("all")}
              value={status}
              customStyle={{ width: "-webkit-fill-available" }}
            />
          </div>
          <div className="asnmt-ud-table-search-bar">
            <SearchBar
              value={search}
              onChange={(value) => setSearch(value)}
              onSearch={handleSearch}
              enable={search?.length >= 2}
              loading={assignmentListState?.loading === LoadingType.pending && search?.length !== 0}
            />
          </div>
          <div className="deparment-toggle">
            <CustomCheckBox
              name={"department"}
              title={t("include_inactive_assignments")}
              checked={showInactive}
              onClick={() => setShowInactive(!showInactive)}
            />
          </div>
          <div className="total-count">
            <span>
              {`${t("total_count")}: `}
              <span className="total-count-number">
                {assignmentsList?.total!}
              </span>
            </span>
          </div>
        </div>
        <div className={"broadcast_btn_div"}>
          <CustomButton
            leftIcon={getTextEmAllIcon()}
            loading={false}
            textStyle={{ textTransform: "capitalize" }}
            name={t("broadcast_to_talent")}
            enable={canSendText ? true : false}
            onClick={handleBroadCastTalent}
          />

          {showMsg && <span className="select_talent_msg">{showErrorMsg}</span>}
        </div>
      </div>
      <div className="cps-ud-table">
        {assignmentListState.loading === LoadingType.pending ? (
          <TableLoading />
        ) : (
          <TableBody
            assignmentListState={assignmentListState?.assignmentsByPage}
            getFilteredList={getFilteredList}
            sortedField={sortedField}
            handleSortFieldChange={handleSortFieldChange}
            // onRetry={handleRetry}
            assignments={assignmentsList?.items}
            handleAssignmentSelect={handleAssignmentSelect}
            handleCompanySelect={handleCompanySelect}
            handleDepartmentSelect={handleDepartmentSelect}
            handleJobTitleSelect={handleJobTitleSelect}
            handleTalentSelect={handleTalentSelect}
            sortDirection={sortDirection}
            handleMarkItem={handleMarkItem}
            handleMarkAllItem={handleMarkAllItem}
            selectedItems={selectedItems}
            getAssignments={getAssignments}
            getRatingCell={getRatingCell}
            getStatus={getStatus}
          />
        )}
      </div>
      <div className="as-table-footer">
        <Pagination
          totalPages={assignmentsList?.pages!}
          onPageChange={handlePageChange}
          curPageNum={currentPage}
          totalCount={assignmentsList?.total!}
          size={assignmentsList?.size!}
          loadingState={assignmentListState?.assignmentsByPage?.loading}
        />
      </div>
      {canSendText &&
        broadcastTalentError &&
        textEmAllState &&
        textEmAllState?.error !== null && (
          <Portal>
            <BroadcastToTalentErrorPopup
              error={textEmAllState?.error}
              onClose={() => {
                setBroadcastTalentError(false);
                dispatch(textEmAllSliceActions.clearState());
              }}
              visible={broadcastTalentError}
              title={t("something_went_wrong")}
              loading={textEmAllState?.loading === LoadingType.pending}
              onForceSend={handleBroadCastTalent}
            />
          </Portal>
        )}
      {canSendText && broadcastTalent && (
        <Portal>
          <BroadcastToTalentPopup
            visible={broadcastTalent}
            title={""}
            successTitle={""}
            onClose={() => {
              setBroadcastTalent(false);
              dispatch(textEmAllSliceActions.clearState());
            }}
            onSuccessClose={() => { }}
            responseURL={textEmAllState?.response}
            loading={textEmAllState?.loading === LoadingType.pending}
          />
        </Portal>
      )}
    </div>
  );
};

const TableBody = ({
  assignmentListState,
  getFilteredList,
  sortDirection,
  handleMarkAllItem,
  handleMarkItem,
  selectedItems,
  assignments,
  handleSortFieldChange,
  getAssignments,
  handleAssignmentSelect,
  handleTalentSelect,
  handleCompanySelect,
  handleDepartmentSelect,
  handleJobTitleSelect,
  getRatingCell,
  sortedField,
  getStatus,
}) => {
  const { t } = useTranslation();

  const SelectAllCheckBox = () => {
    return (
      <CustomCheckBox
        name={""}
        title={""}
        checked={selectedItems.size === getFilteredList().length}
        onClick={handleMarkAllItem}
      />
    );
  };
  const tableHeader = [
    { title: "", code: "", sort: false, children: <SelectAllCheckBox /> },
    { title: "access_assignment", code: "access_assignment" },
    { title: "talent", code: "talent_name" },
    { title: "status", code: "status" },
    { title: "company", code: "company_name" },
    { title: "department", code: "department_name" },
    { title: "job_title", code: "job_title" },
    { title: "performance_rating", code: "performance_rating" },
    { title: "regular_pay_rate", code: "regular_pay_rate" },
    { title: "regular_bill_rate", code: "regular_bill_rate" },
    // { title: "wc_code", code: "wc_code" },
    // { title: "", code: "" },
  ];
  if (assignmentListState.loading === LoadingType.pending)
    return <TableLoading />;
  if (assignmentListState.error !== null && assignments?.length === 0) {
    return (
      <TableErrorHandler
        error={assignmentListState.error}
        onRefresh={getAssignments}
      />
    );
  }
  if (assignments?.length === 0) {
    return (
      <TableEmpty title={t("no_assignments_found")} onClick={getAssignments} />
    );
  }
  if (getFilteredList().length === 0) {
    return <TableFilterEmpty title={t("no_assignments_found")} />;
  }

  return (
    <SortableTable
      headerList={tableHeader}
      sortedField={sortedField}
      onSortChange={handleSortFieldChange}
      flexNumber={getFlexNumber}
      isAsc={sortDirection}
    >
      {getFilteredList().map((doc) => {
        return (
          <tr key={doc.id}>
            <TableData customStyle={{ flex: getFlexNumber(0) }}>
              <CustomCheckBox
                name={""}
                title={""}
                checked={selectedItems.has(doc.id)}
                onClick={() => handleMarkItem(doc)}
              />
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(1) }}>
              {getTableAccessIcon(() => handleAssignmentSelect(doc))}
            </TableData>
            <TableData
              customStyle={{ flex: getFlexNumber(2) }}
              isButton={true}
              onClick={() => handleTalentSelect(doc)}
            >
              <span>{doc.talent_name}</span>
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(3) }}>
              <span>{getStatus(doc.status.toUpperCase())}</span>
            </TableData>
            <TableData
              customStyle={{ flex: getFlexNumber(4) }}
              isButton={doc.company_name ? true : false}
              onClick={() => handleCompanySelect(doc)}
            >
              <span>{doc.company_name ?? ""}</span>
            </TableData>
            <TableData
              customStyle={{ flex: getFlexNumber(5) }}
              isButton={true}
              onClick={() => handleDepartmentSelect(doc)}
            >
              <span>{doc.department_name ?? t("corporate")}</span>
            </TableData>
            <TableData
              customStyle={{ flex: getFlexNumber(6) }}
              isButton={doc.job_title ? true : false}
              onClick={() => handleJobTitleSelect(doc)}
            >
              <span>{doc.job_title ?? ""}</span>
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(7) }}>
              <span>{getRatingCell(doc.performance_rating)}</span>
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(8) }}>
              <span>
                {doc.regular_pay_rate
                  ? currencyConversion(doc.regular_pay_rate)
                  : "$0.00"}
              </span>
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(9) }}>
              <span>
                {doc.regular_bill_rate
                  ? currencyConversion(doc.regular_bill_rate)
                  : "$0.00"}
              </span>
            </TableData>
            {/* <TableData customStyle={{ flex: getFlexNumber(9) }}><span>{doc.wc_code ?? ""}</span></TableData> */}
          </tr>
        );
      })}
    </SortableTable>
  );

  function getFlexNumber(value: number) {
    if (value === 0) return 0.3;
    if (value === 1) return 1;
    if (value === 2) return 2;
    if (value === 3) return 1;
    if (value === 4) return 2;
    if (value === 5) return 2;
    if (value === 6) return 2;
    if (value === 7) return 2;
    if (value === 8) return 2;
    if (value === 9) return 2;
    if (value === 10) return 1;
    return 1;
  }
};

export default UniversalDirectory;
