import { createAsyncThunk } from "@reduxjs/toolkit";
import { JobOrderAPI,  } from "../../../../apis";
import { AppError, IJobOrder, IJobOrdersByPage, IJobOrdersByPagePayload, } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getJobsListThunk = createAsyncThunk<
    Array<IJobOrder>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/universal-directory/jobs/get',
    async (_, thunkAPI) => {
        try {
            const res = await JobOrderAPI.getJobs();
            if(typeof res.data === 'string') return [];
            return res.data as  Array<IJobOrder>;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const getJobsByPage = createAsyncThunk<
    IJobOrdersByPage,
    IJobOrdersByPagePayload,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/universal-directory/get-jobs-by-page/post',
    async (payload, thunkAPI) => {
        try {
            const res = await JobOrderAPI.getJobsByPage(payload);
            return res.data as IJobOrdersByPage;
        } catch (error) {
            return catchRequestError(error, thunkAPI)
        }
    }
)