import { useEffect, useState } from "react";
import LeftSideNav from '../components/left_side_nav/left_side_nav';
import TopNavBar from '../components/nav_menu/top_navbar';
import './main_page_holder.scss';
import { Portal } from "react-portal";
import { selectProfileState } from '../redux/profile/profile_selector';
import { getProfile, profileLogout } from "../redux/profile/profile_thunk";
import { useAppDispatch, useAppSelector } from '../redux/store';
import { LoadingType, ProfilePictureType } from "../enums";
import UploadProfile from "../components/image_crop/image_crop_popup";
import { useRef } from "react";
import {
    ActionDialogHolder,
    ActionDialogHolderType
} from "../components/action_dialog_holder/action_dialog_holder";
import CloseIcon from "../../assets/icons/close-btn2.svg";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../utils";
import { THEME } from "../enums";
import { changePassword } from "../redux/forgot_password/forgot_password_thunk";
import { selectForgotPasswordState } from "../redux/forgot_password/forgot_password_selector";
import { TickGreenIcon } from "../icons";
import preferences from "../preferences";
import { FormInput } from "../utils/form_input";

interface Props {
    history?: any,
    location?: any,
    children: any,
};
const MainPageHolder = (props: Props) => {

    const dispatch = useAppDispatch();

    const [showCreate, setShowCreate] = useState(false);
    const [newProfileURL, setNewProfileURL] = useState<String | null>('');
    const [sentRequest, setSentRequest] = useState(false);
    const [showUpdatePassword, setShowUpdatePassword] = useState(false);
    const [expanded, setExpanded] = useState(true);
    const { profile, loading } = useAppSelector((state) => selectProfileState(state));


    useEffect(() => {
        const st = preferences.getNavExpandState();
        if (st) {
            setExpanded(JSON.parse(st));
        }
    }, [])


    function handleProfileUpdate() {
        setShowCreate(true);
    }

    function updateProfileURL() {
        setSentRequest(true);
        dispatch(getProfile());
    }

    useEffect(() => {
        if (loading === LoadingType.succeeded && sentRequest) {
            setSentRequest(false);
            setNewProfileURL(profile?.picture_url ? profile.picture_url : '');
        }

    }, [loading]);

    function extractDomain(email: string): string | null {
        const parts = email.split('@');
        return parts.length === 2 ? parts[1] : null;
    }

    function handleLeftNavExpand() {
        preferences.setNavExpandState(!expanded);
        setExpanded(!expanded);
    }

    return (
        <div className="mp-container">
            <div className="mp-nav">
                <TopNavBar
                    handleProfileUpdate={handleProfileUpdate}
                    profileURL={newProfileURL ? newProfileURL : profile?.picture_url}
                    handlePasswordUpdate={() => setShowUpdatePassword(true)}
                    domain={extractDomain(profile?.email ?? "")}
                />
            </div>
            <div className="mp-swither">
                <div className="mp-side-menu" style={{ minWidth: expanded ? '13.0208vw' : '4vw' }}>
                    <LeftSideNav
                        roles={profile?.roles ? profile?.roles.reduce((a, v) => ({ ...a, [`${v.role}`]: v }), {}) : {}}
                        expanded={expanded}
                        onToggleExpanded={handleLeftNavExpand}
                        domain={extractDomain(profile?.email ?? "")}
                    />
                </div>
                <div className="mp-wrapper">
                    {props.children}
                </div>
            </div>
            {showCreate &&
                <Portal>
                    <UploadProfile
                        onClose={() => setShowCreate(false)}
                        onDismiss={() => setShowCreate(false)}
                        visible={showCreate}
                        onApprove={updateProfileURL}
                        objectId={profile?.id}
                        objectType={ProfilePictureType.systemUser}
                    />
                </Portal>
            }
            {showUpdatePassword &&
                <Portal>
                    <UpdatePassword
                        onClose={() => setShowUpdatePassword(false)}
                        onDismiss={() => setShowUpdatePassword(false)}
                        visible={showCreate}
                        onApprove={updateProfileURL}
                    />
                </Portal>
            }
        </div>
    )
}

const UpdatePassword = ({ visible, onClose, onDismiss, onApprove }) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [password, setPassword] = useState({ password: '', confirmPassword: '' });
    const [isValid, setIsValid] = useState(false);
    const [sentRequest, setSentRequest] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [showSuccessPage, setShowSuccessPage] = useState(false);

    const changePasswordState = useAppSelector((state) => selectForgotPasswordState(state));

    function handleInput(id, value) {
        let newData = { ...password, [id]: value.trim() }
        setPassword(newData);
        if (newData.password && newData.confirmPassword && newData.password === newData.confirmPassword)
            setIsValid(true);
        else
            setIsValid(false);
    }

    function handleChangePassword() {
        dispatch(changePassword(password.password));
        setSentRequest(true);
    }


    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    useEffect(() => {
        if (changePasswordState.changePassword.loading === LoadingType.succeeded && sentRequest) {
            setSentRequest(false);
            setSuccessMessage(changePasswordState.changePassword.response);
            setPassword({ ...password, password: '', confirmPassword: '' });
            setShowSuccessPage(true);
            setTimeout(() => (dispatch(profileLogout())), 2000);
        }
    }, [changePasswordState.changePassword.loading])

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={500}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <div className="password-update-dialog-container">
                <div className="password-update-dialog-header">
                    <h5>{t("update_password")}</h5>
                    <img style={{ "cursor": "pointer" }} src={CloseIcon} alt="close_btn" onClick={() => closePopup(onClose)} />
                </div>
                {showSuccessPage ?
                    <div className="password-update-success">
                        <TickGreenIcon />
                        <h6>{successMessage}</h6>
                    </div> :
                    <><div className="password-update-dialog-content">
                        <FormInput
                            id={"password"}
                            onChange={(id, val) => (handleInput(id, val))}
                            type={"password"}
                            value={password.password}
                            label={t("new_password")}
                            placeholder=""
                        />
                        <div style={password.password.length > 5 ? { color: "green" } : { color: "red" }}>{t("password_update_message")}</div>
                        <FormInput
                            id={"confirmPassword"}
                            onChange={(id, val) => (handleInput(id, val))}
                            type={"password"}
                            value={password.confirmPassword}
                            label={t("confirm_password")}
                            placeholder=""
                        />
                        <div className="password-valid-message">{password.confirmPassword && !isValid ? t("password_not_match") : null}</div>
                    </div>
                        {sentRequest && changePasswordState.changePassword.error ?
                            <p style={{ color: THEME.red }}>{changePasswordState.changePassword.error?.response?.detail?.error}</p> : <></>}
                        <div className="password-update-dialog-footer">
                            <div className="update-button">
                                <CustomButton
                                    textStyle={{ textTransform: 'capitalize' }}
                                    enable={isValid && password.password.length > 5}
                                    loading={changePasswordState.changePassword.loading === LoadingType.pending}
                                    name={t("update")}
                                    padding={12}
                                    fontSize={15}
                                    backgroundColor={THEME.buttonColor1}
                                    onClick={handleChangePassword}
                                />
                            </div>
                        </div></>}
            </div>
        </ActionDialogHolder>
    )
}

export default MainPageHolder;