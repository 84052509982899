export class Assignment {
  agency_id: string;
  assignment_end_reason_id: string;
  branch_id: string;
  company_id: string;
  company_name: string;
  contact_id: string | null;
  contact_name: string | null;
  department_id: string;
  department_name: string;
  double_time_bill_rate: number | string | null;
  double_time_pay_rate: number | string | null;
  end_date: number | null;
  id: string;
  is_deleted: false;
  job_order_id: string;
  job_title: string;
  mark_up_factor: number;
  overtime_bill_rate: number | string | null;
  overtime_pay_rate: number | string | null;
  performance_rating: number;
  regular_bill_rate: number | string | null;
  regular_pay_rate: number | string | null;
  service_calls: string[];
  shift_break: string;
  shift_end: number | string | null;
  shift_hours: number | null;
  shift_name: string;
  shift_start: number | string | null;
  start_date: number | null;
  status: string;
  engagement_rating?: number;
  engagement_level?: string;
  talent_id: string;
  talent_name: string;
  triple_time_bill_rate: number | null;
  triple_time_pay_rate: number | null;
  wc_code: string | null;
  wc_code_id: string | null;
  work_schedule: {
    FRIDAY: boolean,
    FRIDAY_END_TIME?: number | null,
    FRIDAY_START_TIME?: number | null,
    MONDAY: boolean,
    MONDAY_END_TIME?: number | null,
    MONDAY_START_TIME?: number | null,
    SATURDAY: boolean,
    SATURDAY_END_TIME?: number | null,
    SATURDAY_START_TIME?: number | null,
    SUNDAY: boolean,
    SUNDAY_END_TIME?: number | null,
    SUNDAY_START_TIME?: number | null,
    THURSDAY: boolean,
    THURSDAY_END_TIME?: number | null,
    THURSDAY_START_TIME?: number | null,
    TUESDAY: boolean,
    TUESDAY_END_TIME?: number | null,
    TUESDAY_START_TIME?: number | null,
    WEDNESDAY: boolean,
    WEDNESDAY_END_TIME?: number | null,
    WEDNESDAY_START_TIME?: number | null,
  };
  talent_profile_url?: string | null;
  account_managers: Array<string>;
  recruiters: Array<string>;
  direct_hire?: boolean;
  hourly_pay_type?: number;
  salary_pay_type?: number;
  fee_type_flat_rate?: number;
  fee_type_percentage?: number;
  fee_amount?: number;
  pay_type?: string;
  fee_type?: string;
}
export class UpdateAssignment {
  agency_id: string;
  assignment_end_reason_id: string;
  branch_id: string;
  company_id: string;
  company_name: string;
  contact_id: string | null;
  contact_name: string | null;
  department_id: string;
  department_name: string;
  double_time_bill_rate: string | null;
  double_time_pay_rate: string | null;
  end_date: number | null;
  id: string;
  is_deleted: false;
  job_order_id: string;
  job_title: string;
  mark_up_factor: number;
  overtime_bill_rate: string | null;
  overtime_pay_rate: string | null;
  performance_rating: number;
  regular_bill_rate: string | null;
  regular_pay_rate: string | null;
  service_calls: string[];
  shift_break: string;
  shift_end: string | null;
  shift_hours: number | null;
  shift_name: string;
  shift_start: string | null;
  start_date: number | null;
  status: string;
  engagement_rating?: number;
  engagement_level?: string;
  talent_id: string;
  talent_name: string;
  triple_time_bill_rate: number | null;
  triple_time_pay_rate: number | null;
  wc_code: string | null;
  wc_code_id: string | null;
  work_schedule: {
    FRIDAY: boolean,
    FRIDAY_END_TIME: string | null,
    FRIDAY_START_TIME: string | null,
    MONDAY: boolean,
    MONDAY_END_TIME: string | null,
    MONDAY_START_TIME: string | null,
    SATURDAY: boolean,
    SATURDAY_END_TIME: string | null,
    SATURDAY_START_TIME: string | null,
    SUNDAY: boolean,
    SUNDAY_END_TIME: string | null,
    SUNDAY_START_TIME: string | null,
    THURSDAY: boolean,
    THURSDAY_END_TIME: string | null,
    THURSDAY_START_TIME: string | null,
    TUESDAY: boolean,
    TUESDAY_END_TIME: string | null,
    TUESDAY_START_TIME: string | null,
    WEDNESDAY: boolean,
    WEDNESDAY_END_TIME: string | null,
    WEDNESDAY_START_TIME: string | null,
  };
  talent_profile_url?: string | null;
  account_managers: Array<string>;
  recruiters: Array<string>;
  direct_hire?: boolean;
  hourly_pay_type?: number;
  salary_pay_type?: number;
  fee_type_flat_rate?: number;
  fee_type_percentage?: number;
  fee_amount?: number;
  pay_type?: string;
  fee_type?: string;
}

export interface ICreateAssignment {
  talent_id: string;
  company_id: string;
  department_id: string;
  job_order_id: string;
  contact_id: string;
  status: string;
  performance_rating: number;
  agency_id?: string;
  branch_id?: string;
  is_deleted: false;
  company_name: string;
  department_name: string;
  job_title: string;
  talent_name: string;
  contact_name: string;
  pay_rate: number;
  bill_rate: number;
  wc_code: number;
  direct_hire?: boolean;
  hourly_pay_type?: number;
  salary_pay_type?: number;
  fee_type_flat_rate?: number;
  fee_type_percentage?: number;
  fee_amount?: number;
  pay_type?: string;
  fee_type?: string;
}
export interface IAssignmentList {
  id: string,
  talent_id: string,
  job_order_id: string,
  job_title: string,
  talent_name: string
  company_id: string,
  company_name: string,
}

export interface IAssignmentApiQuery {
  page: number;
  size: number;
  include_inactive?: boolean;
  company_id?: string | null;
  department_id?: string | null;
  contact_id?: string | null;
  talent_id?: string | null;
  branch_id?: string | null;
  job_order_id?: string | null;
  search_str?: string;
}

/// [Staffs]
export interface ICreateAssignmentStaff {
  assignment_id: string;
  user_id_list: Array<string>;
  role: string;
}

export interface IDeleteAssignmentStaff {
  assignment_id: string;
  user_id: string;
  role: string;
}

export interface IAssignmentsByPage {
  items: Assignment[];
  total: number,
  page: number,
  size: number,
  pages: number
}
export interface IAssignmentsByPagePayload {
  page: number,
  size: number,
  filter_ids?: {
      acc_manager_ids?: Array<string>,
      recruiter_ids?: Array<string>,
      statuses?: Array<string>
  }
  include_inactive?: boolean,
  search_str?: string,
}
