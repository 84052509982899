import { RouteComponentProps } from 'react-router-dom';
import TitleCardPage from '../title_card/title_card_page';
import { AssignmentIcon, CompaniesIcon, PayrollPercentIcon, JobOrderIcon, PayrollIcon, SalesmarketIcon, ServiceIcon, TalentIcon } from '../../../../icons';
import { AppRoutes } from '../../../../routes';
import { useState } from 'react';
import { Portal } from 'react-portal';
import InfoTextPopup from '../../popups/infoTextPopup/info_text_popup';
import { useTranslation } from 'react-i18next';
import popupInfoContent from '../../popup_info_text';
import './dashboard.scss';

interface Props extends RouteComponentProps<any> { }

interface InfoTextPopupProps {
    paycheckRegister: boolean;
    payrollJournal: boolean;
    paymentRegister: boolean;
    payrollCashRequired: boolean;
    newHireReport: boolean;
    terminationReport: boolean;
    garnishmentReport: boolean;
    garnishmentsSubmitalReport: boolean;
    payrollDeductions: boolean;
    invoiceRegister: boolean;
    cashReceipts: boolean;
    aRAgingReport: boolean;
    grossProfitReport: boolean;

}

const ReportCenterPage: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const [infoTextPopup, setInfoTextPopup] = useState<InfoTextPopupProps | null>()
    const [selectedInfoTextPopup, setSelectedInfoTextPopup] = useState<string>("")
    const handleOpenInfoTextPopup = (title) => {
        setSelectedInfoTextPopup(title)
        switch (title) {
            case "paycheck_register":
                return setInfoTextPopup({ ...infoTextPopup!, paycheckRegister: true })
            case "payroll_journal":
                return setInfoTextPopup({ ...infoTextPopup!, payrollJournal: true })
            case "payment_register":
                return setInfoTextPopup({ ...infoTextPopup!, paymentRegister: true })
            case "payroll_cash_required":
                return setInfoTextPopup({ ...infoTextPopup!, payrollCashRequired: true })
            case "new_hire_report":
                return setInfoTextPopup({ ...infoTextPopup!, newHireReport: true })
            case "termination_report":
                return setInfoTextPopup({ ...infoTextPopup!, terminationReport: true })
            case "garnishment_report":
                return setInfoTextPopup({ ...infoTextPopup!, garnishmentReport: true })
            case "garnishments_submital_report":
                return setInfoTextPopup({ ...infoTextPopup!, garnishmentsSubmitalReport: true })
            case "payroll_deductions":
                return setInfoTextPopup({ ...infoTextPopup!, payrollDeductions: true })
            case "invoice_register":
                return setInfoTextPopup({ ...infoTextPopup!, invoiceRegister: true })
            case "cash_receipts":
                return setInfoTextPopup({ ...infoTextPopup!, cashReceipts: true })
            case "ar_aging_report":
                return setInfoTextPopup({ ...infoTextPopup!, aRAgingReport: true })
            case "gross_profit_report":
                return setInfoTextPopup({ ...infoTextPopup!, grossProfitReport: true })
            default:
                return
        }
    }
    const handleSelectedInfoTextPopupState = () => {
        switch (selectedInfoTextPopup) {
            case "paycheck_register":
                return infoTextPopup?.paycheckRegister
            case "payroll_journal":
                return infoTextPopup?.payrollJournal
            case "payment_register":
                return infoTextPopup?.paymentRegister
            case "payroll_cash_required":
                return infoTextPopup?.payrollCashRequired
            case "new_hire_report":
                return infoTextPopup?.newHireReport
            case "termination_report":
                return infoTextPopup?.terminationReport
            case "garnishment_report":
                return infoTextPopup?.garnishmentReport
            case "garnishments_submital_report":
                return infoTextPopup?.garnishmentsSubmitalReport
            case "payroll_deductions":
                return infoTextPopup?.payrollDeductions
            case "invoice_register":
                return infoTextPopup?.invoiceRegister
            case "cash_receipts":
                return infoTextPopup?.cashReceipts
            case "ar_aging_report":
                return infoTextPopup?.aRAgingReport
            case "gross_profit_report":
                return infoTextPopup?.grossProfitReport
            default:
                return
        }
    }
    const handleSelectedPopupInfoText = () => {
        switch (selectedInfoTextPopup) {
            case "paycheck_register":
                return popupInfoContent.paycheckRegisterInfo
            case "payroll_journal":
                return popupInfoContent.payrollJournalInfo
            case "payment_register":
                return popupInfoContent.paymentRegisterInfo
            case "payroll_cash_required":
                return popupInfoContent.payrollCashRequiredInfo
            case "new_hire_report":
                return popupInfoContent.newHireReportInfo
            case "termination_report":
                return popupInfoContent.terminationReport
            case "garnishment_report":
                return popupInfoContent.garnishmentReportInfo
            case "garnishments_submital_report":
                return popupInfoContent.garnishmentsSubmitalReportInfo
            case "payroll_deductions":
                return popupInfoContent.payrollDeductionsInfo
            case "invoice_register":
                return popupInfoContent.invoiceRegisterInfo
            case "cash_receipts":
                return popupInfoContent.cashReceiptsInfo
            case "ar_aging_report":
                return popupInfoContent.aRAgingReportInfo
            case "gross_profit_report":
                return popupInfoContent.grossProfitReportInfo
            default:
                return
        }
    }
    const cardsList = [
        {
            title: "sales_and_marketing",
            icon: SalesmarketIcon,
            list: [
                { title: 'sales_call_summary_report', showIcon: false, route: AppRoutes.reportCenterSCSRPage },
                { title: 'marketing_summary_report', showIcon: false, route: AppRoutes.reportCenterMSRPage }
            ]
        },
        {
            title: "companies",
            icon: CompaniesIcon,
            list: [
                { title: 'companies_billed', showIcon: false, route: AppRoutes.reportCenterCompaniesBilled },
            ]
        },
        {
            title: "talent",
            icon: TalentIcon,
            list: [
                { title: 'availability_report', showIcon: false, route: AppRoutes.reportCenterTalentAvailabilityReport },
                { title: 'employee_assignment_report', showIcon: false, route: AppRoutes.reportCenterEmployeeAssignmentReportPage }
            ]

        },
        {
            title: "payroll_tax",
            icon: PayrollPercentIcon,
            list: [
                { title: 'paycheck_register', showIcon: true, route: AppRoutes.reportPayCheckRegister, onclick: () => handleOpenInfoTextPopup("paycheck_register") },
                { title: 'payroll_journal', showIcon: true, route: AppRoutes.reportCenterPayrollJournalPage, onclick: () => handleOpenInfoTextPopup("payroll_journal") },
                { title: 'payment_register', showIcon: true, route: AppRoutes.reportCenterPaymentRegisterPage, onclick: () => handleOpenInfoTextPopup("payment_register") },
                { title: 'payroll_cash_required', showIcon: true, route: AppRoutes.reportCenterPayrollCashPage, onclick: () => handleOpenInfoTextPopup("payroll_cash_required") },
                { title: 'new_hire_report', showIcon: true, route: AppRoutes.reportCenterNewHireReportPage, onclick: () => handleOpenInfoTextPopup("new_hire_report") },
                { title: 'termination_report', showIcon: true, route: AppRoutes.terminationReport, onclick: () => handleOpenInfoTextPopup("termination_report") },
                // { title: 'garnishment_report', showIcon: true, route: "", onclick: () => handleOpenInfoTextPopup("garnishment_report") },
                // { title: 'garnishments_submital_report', showIcon: true, route: "", onclick: () => handleOpenInfoTextPopup("garnishments_submital_report") },
                { title: 'payroll_deductions', showIcon: true, route: AppRoutes.reportCenterPayrollDeductionsPage, onclick: () => handleOpenInfoTextPopup("payroll_deductions") },
                { title: 'workcomp_class_code', showIcon: false, route: AppRoutes.reportCenterWorkCompCode},
                { title: 'employee_holiday_report', showIcon: false, route: AppRoutes.reportCenterEmpHoliday },
                { title: 'employee_vacation_report', showIcon: false, route: AppRoutes.reportCenterVacation },
                { title: 'tax_by_employee', showIcon: false, route: AppRoutes.reportCenterTaxByEmployee },
                { title: 'employer_taxes', showIcon: false, route: AppRoutes.reportCenterEmployerTax },
                { title: 'master_tax_report', showIcon: false, route: AppRoutes.masterTaxReports },

                // { title: 'suta_report', showIcon: false, route: "" },
                // { title: '941_report', showIcon: false, route: "" },
                // { title: '940_report', showIcon: false, route: "" },
                // { title: 'w2_report', showIcon: false, route: "" }
            ]

        },
        {
            title: "service",
            icon: ServiceIcon,
            list: [
                // { title: 'job_order_confirmation_calls_report', showIcon: false, route: "" },
                { title: 'arrival_check_calls_report', showIcon: false, route: AppRoutes.reportCenterACCRPage },
                { title: 'end_first_day_calls_report', showIcon: false, route: AppRoutes.reportCenterEOFDCRPage },
                { title: 'end_second_day_calls_report', showIcon: false, route: AppRoutes.reportCenterEOSDCRPage },
                { title: 'friday_calls_report', showIcon: false, route: AppRoutes.reportCenterFCRPage },
                { title: 'four_hour_guarantee_report', showIcon: false, route: AppRoutes.reportCenterFHGRPage },
                { title: 'job_confirmation_calls_report', showIcon: false, route: AppRoutes.reportCenterJCCRPage },
                { title: 'ninety_day_calls_report', showIcon: false, route: AppRoutes.reportCenterNDRPage },
                { title: 'sixty_day_calls_report', showIcon: false, route: AppRoutes.reportCenterSDRPage },
                { title: 'thirty_day_calls_report', showIcon: false, route: AppRoutes.reportCenterTDRPage }
            ]

        },
        {
            title: "job_orders",
            icon: JobOrderIcon,
            list: [
                { title: 'open_job_orders_report', showIcon: false, route: AppRoutes.reportCenterOJORPage },
                { title: 'job_orders_history_report', showIcon: false, route: AppRoutes.reportCenterJOHRPage }
            ]

        },
        {
            title: "assignments",
            icon: AssignmentIcon,
            list: [{
                title: 'employee_assignment_report', showIcon: false, route: AppRoutes.reportCenterEmployeeAssignmentReportPage
            }
            ]
        },
        {
            title: "finance_accounting",
            icon: PayrollIcon,
            list: [
                { title: 'invoice_register', showIcon: true, route: AppRoutes.reportCenterInvoiceRegisterPage, onclick: () => handleOpenInfoTextPopup("invoice_register") },
                { title: 'cash_receipts', showIcon: true, route: AppRoutes.reportCenterCashReceiptsPage, onclick: () => handleOpenInfoTextPopup("cash_receipts") },
                { title: 'ar_aging_report', showIcon: true, route: AppRoutes.reportCenterARAgingReportPage, onclick: () => handleOpenInfoTextPopup("ar_aging_report") },
                { title: 'gross_profit_report', showIcon: true, route: AppRoutes.reportCenterGrossProfitPage, onclick: () => handleOpenInfoTextPopup("gross_profit_report") },
                { title: 'companies_billed', showIcon: false, route: AppRoutes.reportCenterCompaniesBilled },
            ]

        }
    ]
    return (
        <div className="report-center-container">
            {cardsList.map((card, index) => (
                <div key={index} className='title-flex-card'>
                    <TitleCardPage
                        title={card.title}
                        titleIcon={card.icon}
                        titleList={card.list}
                    />
                </div>
            ))}
            {handleSelectedInfoTextPopupState() &&
                <Portal>
                    <InfoTextPopup
                        visible={handleSelectedInfoTextPopupState()!}
                        onClose={() => setInfoTextPopup(null)}
                        onDismiss={() => setInfoTextPopup(null)}
                        title={t(selectedInfoTextPopup)}
                        textContent={handleSelectedPopupInfoText()!}
                    />
                </Portal>}
        </div>
    )
}

export default ReportCenterPage;
