import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../../components/action_dialog_holder/action_dialog_holder";
import { LoadingType, THEME } from "../../../../../enums";
import { Jobsicon } from "../../../../../icons";
import { useAppDispatch } from "../../../../../redux/store";
import { FormInput, CustomButton } from "../../../../../utils";
import { AppError } from "../../../../../interfaces";
import ApiError from "../../../../../components/api_error";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../../components/dialog_wrapper/dialog_wrapper";

interface Props {
    onClose: () => void;
    onDismiss: () => void;
    visible: boolean;
    category: any;
    category_id: number;
    reduxMapping: any;
    errorState: AppError | null | undefined;
    methodValue?: {
        id: string;
        title: string,
        job_order_source: string,
        description: string,
        sequence_number: number,
    };
}

const getFormData = (value) => {
    if (value) {
        return value
    }
    return {
        id: "",
        title: "",
        job_order_source: "",
        description: "",
        sequence_number: 0,
    }
}

const SCJobsPopup: React.FunctionComponent<Props> = (props) => {
    const { onClose, onDismiss, visible, category, category_id, reduxMapping, methodValue, errorState } = props;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [formState, setFormState] = useState(getFormData(methodValue));

    function getMapping() {
        return reduxMapping[category_id];
    }

    useEffect(() => {
        let state = getMapping().state;
        if (state.create.loading === LoadingType.succeeded || state?.update?.loading === LoadingType.succeeded) {
            dispatch(category.methods.get() || category.methods.update());
            dispatch(category.clearCreateState());
            dispatch(category.clearUpdateState());
            setTimeout(() => (onClose()), 1000);
        }
    }, [getMapping().state.create.loading, getMapping()?.state?.update?.loading])

    function handleInput(id, value) {
        setFormState({ ...formState, [id]: value })
    }

    function handleUpdate() {
        const { id, description, title, job_order_source, sequence_number } = formState;
        if (id) {
            dispatch(category.methods.update({
                id,
                value: job_order_source.trim(),
                description: description?.trim(),
                sequence_number: sequence_number
            }));
        }
        else {
            if (category_id === 4) {
                dispatch(category.methods.create({
                    value: job_order_source.trim(),
                    description: description.trim()
                }));
            }
            else {
                return (
                    dispatch(category.methods.create({
                        value: title.trim(),
                        description: description.trim()
                    }))
                )
            }
        }
    }

    const handleClearError = () => {
        dispatch(category.clearCreateState());
        dispatch(category.clearUpdateState());
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={500}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <DialogWrapper onClose={() => {
                handleClearError();
                closePopup(onClose);
            }}>
                <div className="dialog-container dialog-container-2">
                    <div className="dialog-header">
                        <FormDialogTilteHeader
                            title={formState.id ? t(category.updateLabel) : t(category.buttonLabel)}
                            titleIcon={<Jobsicon width={"100%"} height={"100%"} style={{ color: THEME.talentOptionTextColor }} />}
                        />
                    </div>
                    <div className="dialog-content">
                        <FormInput
                            id={category_id === 4 ? "job_order_source" : "title"}
                            onChange={(id, val) => (handleInput(id, val))}
                            type={"text"}
                            value={category_id === 4 ? formState?.job_order_source : formState?.title}
                            label={t(category.inputLabel)}
                            placeholder=""
                            required={true}
                        />
                        <FormInput
                            id={"description"}
                            onChange={(id, val) => (handleInput(id, val))}
                            type={"text"}
                            value={formState?.description}
                            label={t(category.inputLabel2)}
                            placeholder=""
                            required={true}
                        />
                        {errorState && <div className={"error-section"}>
                            <ApiError message={errorState?.message} onClose={handleClearError} />
                        </div>}
                        <div className="dialog-footer">
                            <div className="btn-cancel">
                                <CustomButton
                                    loading={false}
                                    textStyle={{ textTransform: 'capitalize' }}
                                    name={t('cancel')}
                                    enable={true}
                                    backgroundColor={THEME.defaultHighLightColor}
                                    onClick={() => {
                                        handleClearError();
                                        closePopup(onClose);
                                    }}
                                />
                            </div>
                            <div className="btn-save">
                                <CustomButton
                                    textStyle={{ textTransform: 'capitalize' }}
                                    name={formState.id ? t('update') : t('save')}
                                    enable={((category_id === 4 ? formState?.job_order_source !== "" : formState?.title !== "") && formState?.description !== "") && true}
                                    loading={getMapping()?.state?.create?.loading == LoadingType.pending || getMapping().state.update.loading == LoadingType.pending}
                                    backgroundColor={THEME.buttonColor1}
                                    onClick={handleUpdate}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>
    );
};

export default SCJobsPopup;