import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../components/action_dialog_holder/action_dialog_holder";
import ApiError from "../../../components/api_error";
import CustomFormSelect from "../../../components/form_selector/form_select";
import TableEmpty, { TableLoading } from "../../../components/table_empty/table_empty";
import { LoadingType, ROLE, THEME } from "../../../enums";
import { ProfileRole, ToggleRoleData } from "../../../interfaces";
import { getAdminCenterUserManagementUsers, selectManageUserToggleRoleState, selectUsersList, selectUsersListState, toggleUserRole } from "../../../redux/admin_center";
import { manageUserActions } from "../../../redux/admin_center/user_management/manage_user/manage_user_reducer";
import { postSnackbarMessage, useAppDispatch, useAppSelector } from "../../../redux/store";
import { CustomButton } from "../../../utils";
import './action.scss';
import { FormDialogTilteHeader } from "../../../components/dialog_wrapper/dialog_wrapper";
import { CloseIcon } from "../../../icons";


const rolesList = [
    { label: 'administrator', value: ROLE.administrator },
    { label: 'super_user', value: ROLE.superUser },
    { label: 'branch_manager', value: ROLE.branchManager },
    { label: 'account_manager', value: ROLE.accountManager },
    { label: 'recruiter', value: ROLE.recruiter },
    { label: 'call_deck_owner', value: ROLE.callDeskOwner },
    { label: 'front_office_coordinator', value: ROLE.frontOfficeCoordinator },
]
interface AddRoleActionProps {
    visible: boolean,
    userId: string,
    userRoles: Array<string>,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,
}

export const AddRoleAction = (props: AddRoleActionProps) => {
    const {
        visible,
        userRoles,
        userId,
        onClose,
        onDissmiss,
        onSuccessClose,
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { loading, error, response } = useAppSelector((state) => selectManageUserToggleRoleState(state));
    const [role, setRole] = useState<string | null>(null);
    const actionRef = useRef<ActionDialogHolderType>(null);

    useEffect(() => {
        if (loading == LoadingType.succeeded) {
            dispatch(postSnackbarMessage(response ?? null));
            closePopup(onSuccessClose);
        }
        return () => { }
    }, [loading])

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={430}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <div className="add-role-container">
                <div className="add-role-header">
                    <div className="title-txt">
                        <span>{`${t('add_role')}`}</span>
                    </div>
                </div>
                <div className="add-role-content">
                    <CustomFormSelect
                        customStyle={{ width: '-webkit-fill-available' }}
                        name={'role'}
                        placeholder={t('select_role')}
                        value={role}
                        list={rolesList.filter(doc => !userRoles.includes(doc.value))}
                        onChange={(value) => setRole(value)}
                    />
                    {error && <div className={"error-section"}>
                        <ApiError message={error.message} onClose={handleClearError} />
                    </div>}
                </div>
                <div className="add-role-action-holder">
                    <div className="add-role-action">


                        <div className="btn-no">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('cancel')}
                                enable={true}
                                backgroundColor={THEME.secondaryColor4}
                                onClick={() => closePopup(onClose)}
                            />
                        </div>
                        <div className="btn-yes">
                            <CustomButton
                                loading={loading == LoadingType.pending}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('add')}
                                enable={role != null}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={addNewRole}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ActionDialogHolder>

    );

    function handleClearError() {
        dispatch(manageUserActions.clearToggleRoleState());
    }

    function addNewRole() {
        if (role != null) {
            const credentials: ToggleRoleData = {
                role: role,
                add: true,
                user_id: userId,
            };
            dispatch(toggleUserRole(credentials));
        }
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}

/// Add fixed role action 
interface AddFixedRoleActionProps {
    headerTitle: string,
    visible: boolean,
    selectedRole: string,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,
    titleIcon: React.ReactNode,
}

export const AddFixedRoleAction = (props: AddFixedRoleActionProps) => {
    const {
        headerTitle,
        visible,
        selectedRole,
        onClose,
        onDissmiss,
        onSuccessClose,
        titleIcon
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const usersState = useAppSelector((state) => selectUsersListState(state));
    const usersList = useAppSelector((state) => selectUsersList(state));
    const { loading, error, response } = useAppSelector((state) => selectManageUserToggleRoleState(state));
    const [userId, setUserId] = useState<string | null>(null);
    const actionRef = useRef<ActionDialogHolderType>(null);

    useEffect(() => {
        dispatch(getAdminCenterUserManagementUsers());
        return () => { }
    }, [])

    useEffect(() => {
        if (loading == LoadingType.succeeded) {
            dispatch(postSnackbarMessage(response ?? null));
            closePopup(onSuccessClose);
        }
        return () => { }
    }, [loading])

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={'30%'}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <div className="add-role-container">
                <div className="add-role-header">
                    <div>
                        <FormDialogTilteHeader
                            title={headerTitle}
                            titleIcon={titleIcon}
                        />
                    </div>
                    <div>
                        <button className="icon-btn close-action" onClick={() => {
                            handleClearError();
                            closePopup(onClose);
                        }}>
                            <CloseIcon width={"1.2vw"} height={"1.2vw"} />
                        </button>
                    </div>
                </div>
                <div className="add-role-content">
                    {getDialogBody()}
                </div>
                <div className="add-role-action-holder">
                    <div className="add-role-action">
                        <div className="btn-no">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('cancel')}
                                enable={true}
                                backgroundColor={THEME.secondaryColor4}
                                onClick={() => {
                                    handleClearError();
                                    closePopup(onClose);
                                }}
                            />
                        </div>
                        <div className="btn-yes">
                            <CustomButton
                                loading={loading == LoadingType.pending}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('add')}
                                enable={userId != null}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={addNewRole}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ActionDialogHolder>

    );

    /// get dialog body
    function getDialogBody() {
        if (usersState.loading === LoadingType.pending) {
            return (<TableLoading />);
        }
        if (usersList.length === 0) {
            return (
                <TableEmpty title={t('no_users_found')} onClick={() => dispatch(getAdminCenterUserManagementUsers())} />
            );
        }

        return (
            <div className="add-role-users">
                <CustomFormSelect
                    customStyle={{ width: '-webkit-fill-available' }}
                    name={'user_email'}
                    value={userId}
                    placeholder={t('select')}
                    list={usersList.map(doc => ({ label: doc.email, value: doc.id }))}
                    onChange={(value) => setUserId(value)}
                />
                {error && <div className={"error-section"}>
                    <ApiError message={error.message} onClose={handleClearError} />
                </div>}
            </div>
        )
    }

    function handleClearError() {
        dispatch(manageUserActions.clearToggleRoleState());
    }

    function addNewRole() {
        if (selectedRole != null && userId != null) {
            const credentials: ToggleRoleData = {
                role: selectedRole,
                add: true,
                user_id: userId,
            };
            dispatch(toggleUserRole(credentials));
        }
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}