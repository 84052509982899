import interceptor from "./interceptor";
import UrlBuilder from "./url_builder";
import { SalesCallsDataParam } from "../interfaces/sales_call";
import { IHolidayReportParam, IMTExtract, IReportCenterBOPayload, IReportCenterDownloadUrlParams } from "../interfaces/report_center";

const getDownloadQueryParams = (params: IReportCenterDownloadUrlParams) => {
  let url = params.start_date && params.end_date ? `?start_date=${params.start_date}&end_date=${params.end_date}&download=${params.download}` : `?download=${params.download}`;
  if (params.company_id) {
    if (params.department_id) {
      url = url + "&company_id=" + params.company_id + "&department_id=" + params.department_id;
    }
    else {
      url = url + "&company_id=" + params.company_id;
    }
  }
  return url;
};

export const ReportCenterAPI = {

  /// sales and marketing

  getSalesCallSummaryReportGetByUsers(salesCallFilteredUsers: SalesCallsDataParam) {
    return interceptor.post(
      `${UrlBuilder.salesCallSummaryReport}?upcoming=${salesCallFilteredUsers.my_upcoming_calls}&start_date=${salesCallFilteredUsers.start_date}&end_date=${salesCallFilteredUsers.end_date}`
    );
  },
  getSalesCallSummaryReportDownloadUrl(upcoming: boolean, start_date: number, end_date: number, download: boolean, users?: Array<string>) {
    if (users?.length !== 0) {
      return interceptor.post(`${UrlBuilder.salesCallSummaryReport}?upcoming=${upcoming}&start_date=${start_date}&end_date=${end_date}&download=${download}`, users);
    }
    return interceptor.post(`${UrlBuilder.salesCallSummaryReport}?upcoming=${upcoming}&start_date=${start_date}&end_date=${end_date}&download=${download}`);
  },
  getMarketingSummaryReportGetByUsers(is_completed: boolean, start_date: number, end_date: number) {
    return interceptor.post(
      `${UrlBuilder.marketingSummaryReport}?is_completed=${is_completed}&start_date=${start_date}&end_date=${end_date}`
    );
  },

  getMarketingSummaryReportDownloadUrl(is_completed: boolean, start_date: number, end_date: number, download: boolean, users?: Array<string>) {
    if (users?.length !== 0) {
      return interceptor.post(`${UrlBuilder.marketingSummaryReport}?is_completed=${is_completed}&start_date=${start_date}&end_date=${end_date}&download=${download}`, users);
    }
    return interceptor.post(`${UrlBuilder.marketingSummaryReport}?is_completed=${is_completed}&start_date=${start_date}&end_date=${end_date}&download=${download}`);
  },

  /// job orders

  getOpenJobOrdersReport() {
    return interceptor.post(UrlBuilder.openJobOrdersReport);
  },

  getJobOrdersHistoryReport(start_date: number, end_date: number) {
    return interceptor.post(
      `${UrlBuilder.jobOrdersHistoryReport}?start_date=${start_date}&end_date=${end_date}`
    );
  },

  /// service

  getServiceRCArrivalCheckCallsReport(start_date: number, end_date: number) {
    return interceptor.post(
      `${UrlBuilder.serviceCallsRCArrivalCheckCallsReport}?start_date=${start_date}&end_date=${end_date}`
    );
  },

  getServiceRCEndOFDCReport(start_date: number, end_date: number) {
    return interceptor.post(
      `${UrlBuilder.serviceCallsRCEndofFirstDaysCallsReport}?start_date=${start_date}&end_date=${end_date}`
    );
  },

  getServiceRCEndOSDCReport(start_date: number, end_date: number) {
    return interceptor.post(
      `${UrlBuilder.serviceCallsRCEndofSecondDaysCallsReport}?start_date=${start_date}&end_date=${end_date}`
    );
  },

  getServiceRCFridayCallsReports(start_date: number, end_date: number) {
    return interceptor.post(
      `${UrlBuilder.serviceCallsRCFridayCallsReport}?start_date=${start_date}&end_date=${end_date}`
    );
  },

  getServiceFourHourGuaranteeRCCallsReports(start_date: number, end_date: number) {
    return interceptor.post(
      `${UrlBuilder.serviceCallsRCFourHourGuaranteeCallsReport}?start_date=${start_date}&end_date=${end_date}`
    );
  },

  getServiceJobConfirmationRCCallsReports(start_date: number, end_date: number) {
    return interceptor.post(
      `${UrlBuilder.serviceCallsRCJobConfirmationCallsReport}?start_date=${start_date}&end_date=${end_date}`
    );
  },

  getServiceThirtyDayRCCallsReports(start_date: number, end_date: number) {
    return interceptor.post(
      `${UrlBuilder.serviceCallsRCThirtyDayCallsReport}?start_date=${start_date}&end_date=${end_date}`
    );
  },
  
  getServiceSixtyDayRCCallsReports(start_date: number, end_date: number) {
    return interceptor.post(
      `${UrlBuilder.serviceCallsRCSixtyDayCallsReport}?start_date=${start_date}&end_date=${end_date}`
    );
  },

  getServiceNintyDayRCCallsReports(start_date: number, end_date: number) {
    return interceptor.post(
      `${UrlBuilder.serviceCallsRCNinetyDayCallsReport}?start_date=${start_date}&end_date=${end_date}`
    );
  },

  //payroll & tax

  getPaycheckRegister(doc: IReportCenterBOPayload) {
    let url = `${UrlBuilder.paycheckRegister}?start_date=${doc.start_date}&end_date=${doc.end_date}`;

    if (doc.is_check_date) {
      url += `&is_check_date=${doc.is_check_date}`;
    }
    return interceptor.get(url);
  },

  getPayrollJournal(doc: IReportCenterBOPayload) {
    let url = `${UrlBuilder.payrollJournal}?start_date=${doc.start_date}&end_date=${doc.end_date}`;

    if (doc.is_check_date) {
      url += `&is_check_date=${doc.is_check_date}`;
    }
    return interceptor.get(url);

  },
  getNewHireReport(start_date: number, end_date: number) {
    return interceptor.get(
      `${UrlBuilder.newHireReport}?start_date=${start_date}&end_date=${end_date}`
    );
  },
  getTerminationReport(start_date: number, end_date: number) {
    return interceptor.get(
      `${UrlBuilder.terminationReport}?start_date=${start_date}&end_date=${end_date}`
    );
  },
  getInvoiceRegister(start_date: number, end_date: number) {
    return interceptor.get(
      `${UrlBuilder.invoiceRegister}?start_date=${start_date}&end_date=${end_date}`
    );
  },
  getCashReceipts(start_date: number, end_date: number) {
    return interceptor.get(
      `${UrlBuilder.cashReceipts}?start_date=${start_date}&end_date=${end_date}`
    );
  },
  getARAgingReport(as_of_date: number) {
    return interceptor.post(
      `${UrlBuilder.aRAgingReport}?as_of_date=${as_of_date}`
    );
  },
  getARAgingReportInvoiceList(company_id: string, end_date: number, aging_type: string) {
    return interceptor.get(
      `${UrlBuilder.aRAgingReportInvoiceList}?company_id=${company_id}&end_date=${end_date}&aging_type=${aging_type}`
    );
  },

  getTaxByEmployee(doc: IReportCenterBOPayload) {
    let url = `${UrlBuilder.taxByEmployee}?start_date=${doc.start_date}&end_date=${doc.end_date}&talent_id=${doc.talent_id}`;

    if (doc.is_check_date) {
      url += `&is_check_date=${doc.is_check_date}`;
    }
    return interceptor.get(url);
  },
  getPayrollDeductions(doc: IReportCenterBOPayload) {
    let url = `${UrlBuilder.payrollDeductions}?start_date=${doc.start_date}&end_date=${doc.end_date}`;

    if (doc.is_check_date) {
      url += `&is_check_date=${doc.is_check_date}`;
    }
    return interceptor.get(url);
  },
  getPaymentRegister(doc: IReportCenterBOPayload) {
    let url = `${UrlBuilder.paymentRegister}?start_date=${doc.start_date}&end_date=${doc.end_date}`;

    if (doc.is_check_date) {
      url += `&is_check_date=${doc.is_check_date}`;
    }
    return interceptor.get(url);
  },
  getPayrollCashReq(doc: IReportCenterBOPayload) {
    let url = `${UrlBuilder.payrollCashRequired}?start_date=${doc.start_date}&end_date=${doc.end_date}`;

    if (doc.is_check_date) {
      url += `&is_check_date=${doc.is_check_date}`;
    }
    return interceptor.get(url);
  },
  getGrossProfitData(start_date: number, end_date: number) {
    return interceptor.get(
      `${UrlBuilder.grossProfitReport}?start_date=${start_date}&end_date=${end_date}`
    );
  },
  getRCWCcodeData(doc: IReportCenterBOPayload) {
    let url = `${UrlBuilder.rcWcCode}?start_date=${doc.start_date}&end_date=${doc.end_date}`;

    if (doc.is_check_date) {
      url += `&is_check_date=${doc.is_check_date}`;
    }
    return interceptor.get(url);
  },
  getEmployerTax(doc: IReportCenterBOPayload) {
    let url = `${UrlBuilder.rcEmployerTax}?start_date=${doc.start_date}&end_date=${doc.end_date}`;

    if (doc.is_check_date) {
      url += `&is_check_date=${doc.is_check_date}`;
    }
    return interceptor.get(url);
  },


  getReportDownloadURL(doc: IReportCenterBOPayload) {
    let url = doc.as_of_date ? `${doc.baseUrl}?as_of_date=${doc.as_of_date}&download=${doc.download}` : `${doc.baseUrl}?start_date=${doc.start_date}&end_date=${doc.end_date}&download=${doc.download}`;
    if (doc.is_check_date) {
      url += `&is_check_date=${doc.is_check_date}`;
    }
    if (doc.company_id) {
      url += `&company_id=${doc.company_id}`;
    }
    if (doc.department_id) {
      url += `&department_id=${doc.department_id}`;
    }
    if (doc.talent_id) {
      url += `&talent_id=${doc.talent_id}`;
    }
    if (doc.companies) return interceptor.post(url, doc.companies)
    if (doc.downloadAR) {
      if (doc.as_of_date) return interceptor.post(url, doc.downloadAR.account_managers)
      return interceptor.post(url, doc.downloadAR);
    }
    return interceptor.get(url);
  },

  getReportStartEndDate(start_and_end?: boolean) {
    if (start_and_end) return interceptor.get(`${UrlBuilder.recentlyClosedWeek}?start_and_end=${start_and_end}`)
    return interceptor.get(UrlBuilder.recentlyClosedWeek);
  },

  //companies
  getCompaniesBilledList(doc: IReportCenterBOPayload) {
    let url = `${UrlBuilder.companiesBilled}?start_date=${doc.start_date}&end_date=${doc.end_date}`;

    if (doc.is_invoice_date) {
      url += `&invoice_date=${doc.is_invoice_date}`;
    }
    return interceptor.post(url);
  },

  //talent
  getAvailabilityReport(start_date: number, end_date: number) {
    return interceptor.post(
      `${UrlBuilder.availabilityReport}?start_date=${start_date}&end_date=${end_date}`
    );
  },
  getEmployeeAssignmentReport(start_date: number, end_date: number) {
    return interceptor.post(
      `${UrlBuilder.employeeAssignmentReport}?start_date=${start_date}&end_date=${end_date}`
    );
  },

  getHolidayReport(doc: IHolidayReportParam) {
    return interceptor.get(
      `${UrlBuilder.holidayReport}?start_date=${doc.start_date}&end_date=${doc.end_date}&hours_worked=${doc.hours_worked}&earned_hours=${doc.earned_hours}&include_overtime=${doc.include_overtime}`
    );
  },
  getVacationReport() {
    return interceptor.get(UrlBuilder.vacationReport);
  },

  postMasterTaxImport(doc: IMTExtract) {
    return interceptor.post(UrlBuilder.mtExtract, doc);
  },
  getMasterTaxReportsDirectory(year: number, quarter: string) {
    let url = `${UrlBuilder.mtExtractFiles}`;
    if (year) {
      url += `?year=${year}`;
    }
    if (quarter) {
      if(!year){
        url += `?quarter=${quarter}`;
      }
      else{
        url += `&quarter=${quarter}`;
      }
    }
    
    return interceptor.get(url);
  },
  getMasterTaxFileView(file_path: string) {
    return interceptor.get(`${UrlBuilder.mtExtractFileView}?file_path=${file_path}`);
  }
};
