import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import { AppError, IAssignmentsByPage } from "../../../../../interfaces";
import * as thunk from './assignment_list_thunk';
export interface ContactAssignmentState {
    error?: AppError | null;
    loading: LoadingType | null;
    response: IAssignmentsByPage | null;
}

const initialState: ContactAssignmentState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}


export const contactAssignmentSlice = createSlice({
    name: 'assignmentsList',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getContactAssignmentsListByPageById.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getContactAssignmentsListByPageById.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, response: null, }))
            .addCase(thunk.getContactAssignmentsListByPageById.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const { } = contactAssignmentSlice.actions;
export const selectState = (state: ContactAssignmentState) => state;

