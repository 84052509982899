import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { RouteComponentProps } from "react-router-dom";
import { DashboardAPI } from "../../../../apis";
import {
  ActionDialogHolder,
  ActionDialogHolderType,
} from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, {
  FormDialogTilteHeader,
} from "../../../../components/dialog_wrapper/dialog_wrapper";
import SortableTable, {
  TableData,
} from "../../../../components/sortable_table/sortable_table";
import TableEmpty, {
  TableErrorHandler,
  TableFilterEmpty,
  TableLoading,
} from "../../../../components/table_empty/table_empty";
import { LoadingType, THEME } from "../../../../enums";
import {
  ApplicationReceivedIcon,
  LockOpenIcon,
  PaperPlaneIcon,
} from "../../../../icons";
import { OnlineApplicationReceived } from "../../../../interfaces/online_application_received";
import { getOnlineApplicationReceivedDetails } from "../../../../redux/dashboard/metrics/online_applications_received_details/online_applications_received_thunk";
import {
  selectOnlineApplicationsReceivedEntities,
  selectOnlineApplicationsReceivedList,
  selectOnlineApplicationsReceivedListState,
} from "../../../../redux/dashboard/metrics/online_applications_received_list/online_applications_received_list_selector";
import { getOnlineApplicationsReceivedArchive, getOnlineApplicationsReceivedImport, getOnlineApplicationsReceivedList } from "../../../../redux/dashboard/metrics/online_applications_received_list/online_applications_received_list_thunk";
import {
  getCertificationsList,
  getCredentialsList,
  getEducationList,
  getSkillSetList,
  postSnackbarMessage,
  selectProfileState,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { AppRoutes } from "../../../../routes";
import {
  CustomButton,
  CustomCheckBox,
  getTableAccessIcon,
} from "../../../../utils";
import { getDateString } from "../../../../variables";
import "./universal_directory.scss";
import SendInterviewCalendarInvitePopup from "../../popups/send_interview_calendar_invite/send_interview_calendar_invite";
import SearchBar from "../../../../components/search_bar";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import { clearOnlineApplicationReceivedArchiveState, clearOnlineApplicationReceivedImportState } from "../../../../redux/dashboard/metrics/online_applications_received_list/online_applications_received_list_reducer";
import ApiError from "../../../../components/api_error";

interface Props extends RouteComponentProps<any> {
  isArchiveDirectory: boolean;
}

const OnlineApplicationsReceivedUniversalDirectory: React.FunctionComponent<
  Props
> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const applicationsState = useAppSelector((state) =>
    selectOnlineApplicationsReceivedListState(state)
  );
  const applications = useAppSelector((state) =>
    selectOnlineApplicationsReceivedList(state)
  );
  const { loading, error } = useAppSelector((state) =>
    selectOnlineApplicationsReceivedListState(state)
  );

  const [sortedField, setSortedField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [search, setSearch] = useState("");
  const [showImportModal, setShowImportModal] = useState<boolean>(false);
  const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false);
  const [asyncLoading, setAsyncLoading] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
  const actionRef = useRef<ActionDialogHolderType>(null);
  const [showInvite, setShowInvite] = useState(false);
  const [selectedRow, setSelectedRow] = useState<OnlineApplicationReceived | null>(null);
  const profileState = useAppSelector((state) => selectProfileState(state));
  const currentLoginUserName = `${profileState.profile?.first_name ?? ''} ${profileState.profile?.last_name ?? ''}`;
  const importLoadingSuccessState = applicationsState?.import?.loading === LoadingType.succeeded;
  const archiveLoadingSuccessState = applicationsState?.archive?.loading === LoadingType.succeeded;

 
  const tableHeader = [
    { title: "", code: "" },
    { title: "access_application", code: "access_application" },
    { title: "name", code: "talent_name" },
    { title: "applied_for", code: "applied_for" },
    { title: "status", code: "status" },
    { title: "ssn_hash", code: "last_4_digits_of_ssn" },
    { title: "date_applied", code: "date_of_submission" },
    ...(props.isArchiveDirectory ? [] : [{ title: "send_interview_calendar_invite", code: "send" }])
  ];

  useEffect(() => {
    setSelectedItems(new Set());
    setSearch("");
}, [props.isArchiveDirectory]);

  const getApps = () => dispatch(getOnlineApplicationsReceivedList());

  const getFilteredList = () => {
    let list: OnlineApplicationReceived[] = [
      ...applications.filter((i) => i.is_archived === props.isArchiveDirectory),
    ];
    if (sortedField != null) {
      list.sort((a, b) => {
        const valueA =
          a[sortedField] != null && a[sortedField] != undefined
            ? typeof a[sortedField] == typeof "1"
              ? a[sortedField].trim().toLowerCase()
              : a[sortedField]
            : "";
        const valueB =
          b[sortedField] != null
            ? typeof b[sortedField] == typeof "1"
              ? b[sortedField].trim().toLowerCase()
              : b[sortedField]
            : "";
        if (sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }

    // return list;
    return (list).filter((doc) => {
      const str = search.trim().toLowerCase();
      const nameFilter = `${doc.contact_information?.first_name ?? ''} ${doc.contact_information?.last_name ?? ''}`
        ? `${doc.contact_information?.first_name ?? ''} ${doc.contact_information?.last_name ?? ''}`.trim().toLowerCase().includes(str)
        : false;
      const status = doc.status
        ? doc.status.trim().toLowerCase().includes(str)
        : false;
      const ssn = doc.contact_information.last_4_digits_of_ssn
        ? doc.contact_information.last_4_digits_of_ssn.trim().toLowerCase().includes(str)
        : false;
      return nameFilter || status || ssn
    });
  };

  const handleSortFieldChange = (value: string) => {
    if (sortedField && sortedField === value) {
      // setSortedField(null);
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  };

  const handleApplicationSelect = (value: OnlineApplicationReceived): void => {

    dispatch(getOnlineApplicationReceivedDetails(value.id));
    props.history.push({
      pathname: `${AppRoutes.onlineApplicationsReceived}/details/${value.id}`,
      state: {
        id: value.id,
        name:
          value.contact_information.first_name +
          " " +
          value.contact_information.last_name,
      },
    });
  };

  function handleJobSelect(value: OnlineApplicationReceived) {
    props.history.push({
      pathname: `${AppRoutes.jobDetailsPage}/${value.job_id}`,
      state: {
        id: value.job_id,
        name: value.applied_for ?? "",
      },
    });
    return;
  }

  useEffect(() => {
    getApps();
    dispatch(getSkillSetList());
    dispatch(getCertificationsList());
    dispatch(getCredentialsList());
    dispatch(getEducationList());
  }, []);

  useEffect(() => {
    if (error != null && applications.length != 0) {
      dispatch(postSnackbarMessage(error.message));
    }
  }, [error]);

  function getSSNInfo(value: string) {
    return (
      <div className="ssn-holder">
        <div className="ssn-number">
          <span>{value}</span>
        </div>
        {/* <button className="loc-open-icon">
          <LockOpenIcon
            width={"100%"}
            height={"100%"}
            style={{ color: "#00A4DC" }}
          />
        </button> */}
      </div>
    );
  }

  const getTable = () => {
    if (loading === LoadingType.pending) return <TableLoading />;
    if (error != null && applications.length === 0) {
      return <TableErrorHandler error={error} onRefresh={getApps} />;
    }
    if (applications.length === 0) {
      return (
        <TableEmpty title={t("No Applications found")} onClick={getApps} />
      );
    }
    if (getFilteredList().length === 0) {
      return <TableFilterEmpty title={t("No Applications found")} />;
    }
    if (getFilteredList().length !== 0) {
      return (
        <SortableTable
          headerList={tableHeader}
          sortedField={sortedField}
          onSortChange={handleSortFieldChange}
          flexNumber={getFlexNumber}
          isAsc={sortDirection}
        >
          {getFilteredList().map((doc) => {
            return (
              <tr key={doc.id}>
                <TableData customStyle={{ flex: getFlexNumber(0) }}>
                  <CustomCheckBox
                    name={""}
                    title={""}
                    checked={selectedItems.has(doc.id)}
                    onClick={() => {
                      const newSet = new Set(selectedItems);
                      if (selectedItems.has(doc.id)) {
                        newSet.delete(doc.id);
                      } else {
                        newSet.add(doc.id);
                      }
                      setSelectedItems(newSet);
                      setSelectedRow(doc)
                    }}
                  />
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(1) }}>
                  {getTableAccessIcon(() => handleApplicationSelect(doc))}
                </TableData>
                <TableData
                  customStyle={{ flex: getFlexNumber(2) }}
                  isButton
                  onClick={() => handleApplicationSelect(doc)}
                >
                  <span>
                    {doc.contact_information?.first_name +
                      " " +
                      doc.contact_information?.last_name}
                  </span>
                </TableData>
                <TableData
                  customStyle={{ flex: getFlexNumber(3) }}
                  isButton
                  onClick={() => handleJobSelect(doc)}
                >
                  <span>{doc.applied_for ?? ""}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(4) }}>
                  <span>{getStatus(doc.status)}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(5) }}>
                  <span>
                    {doc.contact_information.last_4_digits_of_ssn
                      ? getSSNInfo(doc.contact_information.last_4_digits_of_ssn)
                      : ""}
                  </span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(6) }}>
                  <span>{getDateString(doc.date_of_submission)}</span>
                </TableData>
                {!props.isArchiveDirectory && <TableData customStyle={{ flex: getFlexNumber(7) }}>
                  <PaperPlaneIcon
                    height={20}
                    width={20}
                    style={{ cursor: doc.status.trim() === "Internal Interview Scheduled" ? "" : "pointer", color: doc.status.trim() === "Internal Interview Scheduled" ? "#ccc" : "" }}
                    onClick={() => {
                      if (doc.status.trim() !== "Internal Interview Scheduled") {
                        setSelectedRow(doc);
                        setShowInvite(true);
                      }
                    }}

                  />
                </TableData>}
              </tr>
            );
          })}
        </SortableTable>
      );
    }
  };

  function getFlexNumber(value: number) {
    if (value === 0) return 0.3;
    return 1;
  }

  function getStatus(status: string) {
    return (
      <>
        <span style={{ marginLeft: "5px" }}>{status}</span>
      </>
    );
  }

  const toggle = () => {
    setShowArchiveModal(false);
    setShowImportModal(false);
    setShowInvite(false);
    dispatch(clearOnlineApplicationReceivedImportState())
    if(importLoadingSuccessState) return getOnlineApplicationsReceivedList();
  };

  const closePopup = (action?: () => void) => {
    if (actionRef && actionRef.current) actionRef.current.closeAction(action);
  };

  const handleImport = async () => {
    dispatch(getOnlineApplicationsReceivedImport(Array.from(selectedItems)))
  }

  const handleSuccessClose = () => {
    setShowImportModal(false)
    setShowArchiveModal(false);
    setSelectedItems(new Set())
    dispatch(getOnlineApplicationsReceivedList());
    dispatch(clearOnlineApplicationReceivedImportState())
    dispatch(clearOnlineApplicationReceivedArchiveState())
  }

  const handleArchive = async () => {
    dispatch(getOnlineApplicationsReceivedArchive(Array.from(selectedItems)))
  };

  const getImportUI = () => {
    if (importLoadingSuccessState) {
      return (
        <div className="import-appl-received-success-message">
          <DocSavedText>
            <span>{t('great', { name: currentLoginUserName })}</span>
            <span>{t("import_online_application_success_message", { name: (selectedRow?.contact_information?.first_name + " " + selectedRow?.contact_information?.last_name) })}</span>
          </DocSavedText>
        </div>
      )
    }
    return (
      <>
        <div className="uf-content import-appl-received-success-message">
          <span>
            {t("do_you_want_to_import", { name: selectedRow?.contact_information?.first_name + " " + selectedRow?.contact_information?.last_name })}
          </span>
        </div>
        {
          applicationsState?.import?.error && <div className="error-holder">
            <ApiError message={applicationsState?.import?.error?.message} onClose={() => clearOnlineApplicationReceivedImportState()} />
          </div>
        }
      </>
    )
  }

  const getActions = () => {
    if (importLoadingSuccessState) {
      return (
        <>
          <div className="btn-save">
            <CustomButton
              loading={false}
              textStyle={{ textTransform: 'capitalize' }}
              name={t('ok')}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={handleSuccessClose}
            />
          </div>
        </>
      )
    }
    return (
      <>
        <div className="btn-cancel">
          <CustomButton
            loading={false}
            textStyle={{ textTransform: "capitalize" }}
            name={t("Do Not Import")}
            enable
            backgroundColor={THEME.defaultHighLightColor}
            onClick={() => closePopup(toggle)}
          />
        </div>
        <div className="btn-save">
          <CustomButton
            loading={asyncLoading}
            textStyle={{ textTransform: "capitalize" }}
            name={t("Import")}
            enable
            backgroundColor={THEME.defaultHighLightColor}
            onClick={handleImport}
          />
        </div>
      </>
    )
  }

  return (
    <div className="cps-ud-container contacts-ud applications-received-ud">
      <div className="w-100">
        <div
          className="p-3 appli-received-header"
        >
          <div className="total-count">
            <span>
              {`${t("total_count")}: `}
              <span className="total-count-number">
                {getFilteredList().length}
              </span>
            </span>
          </div>
          <div className="apps-ud-actions">
            <div className="apps-ud-search">
              <SearchBar
                value={search}
                onChange={(value) => setSearch(value)}
                onSearch={() => { }}
              />
            </div>
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("import")}
              enable={selectedItems.size > 0}
              backgroundColor={"#00BE4B"}
              onClick={() => setShowImportModal(true)}
              customStyle={{ marginRight: "10px" }}
            />
            {!props.isArchiveDirectory && <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("archive")}
              enable={selectedItems.size > 0}
              backgroundColor={"#B5BAC5"}
              onClick={() => setShowArchiveModal(true)}
            />}
          </div>
        </div>
      </div>
      <div className="cps-ud-table">{getTable()}</div>
      {showImportModal && (
        <Portal>
          <ActionDialogHolder
            ref={actionRef}
            visible={showImportModal}
            wrapperWidth={"32%"}
            onClose={toggle}
            onDissmiss={toggle}
          >
            <DialogWrapper onClose={() => closePopup(toggle)}>
              <div className="uf-container contact-form import-appl-received">
                <div className="uf-header">
                  <FormDialogTilteHeader
                    title={"Import Online Application"}
                    titleIcon={
                      <ApplicationReceivedIcon width={"100%"} height={"100%"} />
                    }
                  />
                </div>
                <div className="uf-content import-appl-received-content">
                  {getImportUI()}
                </div>
                <div className="uf-actions">
                  {getActions()}
                </div>
              </div>
            </DialogWrapper>
          </ActionDialogHolder>
        </Portal>
      )}
      {showArchiveModal && (
        <Portal>
          <ActionDialogHolder
            ref={actionRef}
            visible={showArchiveModal}
            wrapperWidth={"50%"}
            onClose={toggle}
            onDissmiss={toggle}
          >
            <DialogWrapper onClose={() => closePopup(toggle)}>
              <div className="uf-container contact-form archive-appl-received">
                <div className="uf-header">
                  <FormDialogTilteHeader
                    title={"Online Application Received"}
                    titleIcon={
                      <ApplicationReceivedIcon width={"100%"} height={"100%"} />
                    }
                  />
                </div>
                {archiveLoadingSuccessState ?
                  <div className="uf-content archive-appl-received-content">
                    <DocSavedText>
                      <span>
                        <div><span className="aknw-name">{t('hey_name', { name: currentLoginUserName })}</span></div>
                        <span className="info-txt">{t('you_have_moved_items_to_archive_directory', { name: (selectedRow?.contact_information?.first_name + " " + selectedRow?.contact_information?.last_name) })}</span>
                      </span>
                    </DocSavedText>
                  </div>
                  :
                  <div className="uf-content archive-appl-received-content">
                    <span>{t('are_you_sure_you_want_to_archive', { name: (selectedRow?.contact_information?.first_name + " " + selectedRow?.contact_information?.last_name) })}</span>
                  </div>
                }
                <div className="uf-actions">
                  {archiveLoadingSuccessState ?
                    <div className="btn-save">
                      <CustomButton
                        loading={false}
                        textStyle={{ textTransform: "capitalize" }}
                        name={t("ok")}
                        enable
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={handleSuccessClose}
                      />
                    </div> :
                    <>
                      <div className="btn-cancel">
                        <CustomButton
                          loading={false}
                          textStyle={{ textTransform: "capitalize" }}
                          name={t("no")}
                          enable
                          backgroundColor={THEME.defaultHighLightColor}
                          onClick={() => closePopup(toggle)}
                        />
                      </div>
                      <div className="btn-save">
                        <CustomButton
                          loading={false}
                          textStyle={{ textTransform: "capitalize" }}
                          name={t("yes")}
                          enable
                          backgroundColor={THEME.defaultHighLightColor}
                          onClick={handleArchive}
                        />
                      </div>
                    </>
                  }
                </div>
              </div>
            </DialogWrapper>
          </ActionDialogHolder>
        </Portal>
      )}
      {showInvite && selectedRow && (
        <Portal>
          <ActionDialogHolder
            ref={actionRef}
            visible={showInvite}
            wrapperWidth={"30%"}
            onClose={toggle}
            onDissmiss={toggle}
          >
            <DialogWrapper onClose={() => closePopup(toggle)}>
              <SendInterviewCalendarInvitePopup
                setShowInvite={setShowInvite}
                closePopup={closePopup}
                data={selectedRow}
              />
            </DialogWrapper>
          </ActionDialogHolder>
        </Portal>
      )}
    </div>
  );
};

export default OnlineApplicationsReceivedUniversalDirectory;
