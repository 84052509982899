import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AdminCenterEmailConfiguration, AppError } from "../../../../interfaces";
import * as thunk from "./email_configuration_thunk";

export interface EmailConfigurationState {
    error?: AppError | null,
    loading: LoadingType,
    response: AdminCenterEmailConfiguration | null,
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}

const initialState: EmailConfigurationState = {
    error: null,
    loading: LoadingType.idle,
    response: null, 
    create: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
}

export const emailConfigurationStateSlice = createSlice({
    name: 'emailConfigurationState',
    initialState: initialState,
    reducers: {
        clearEmailConfigurationStateError(state) {
            return { ...state, error: null };
        },
        clearEmailConfigurationState(state) {
            return { ...state, ...initialState };
        },
        clearEmailConfigurationUpdateStateError(state) {
            return { ...state, update : { ...initialState.update , error: null} };
        },
        clearEmailConfigurationUpdateState(state) {
            return { ...state, update : {...initialState.update} };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getEmailConfigurationList.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getEmailConfigurationList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getEmailConfigurationList.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))

            .addCase(thunk.createEmailConfigurationList.pending, (state, action) => ({...state, create: {...state.create, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.createEmailConfigurationList.rejected, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.createEmailConfigurationList.fulfilled, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.updateEmailConfigurationList.pending, (state, action) => ({...state, update: {...state.update, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.updateEmailConfigurationList.rejected, (state, action) => ({ ...state, update: {...state.update, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.updateEmailConfigurationList.fulfilled, (state, action) => ({ ...state, update: {...state.update, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addDefaultCase(state => ({ ...state }));
    },
});

export const { clearEmailConfigurationStateError, clearEmailConfigurationState, clearEmailConfigurationUpdateStateError, clearEmailConfigurationUpdateState } = emailConfigurationStateSlice.actions;
export const selectState = (state: EmailConfigurationState) => state;


