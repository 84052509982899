import { createAsyncThunk } from "@reduxjs/toolkit";
import { CompaniesAPI } from "../../../../../apis";
import { ROLE, TALENT_SKILLS_TYPE } from "../../../../../enums";
import { AppError, IAddSkill, IAddCompanyStaff, IRemoveCompanyStaff, ICompany, ISkillsInfo, Profile, IRemoveSkill, IDepartment } from "../../../../../interfaces";
import { catchRequestError } from "../../../../../utils";
import { getCompanySalesProfile, getCompanyServiceProfile } from "../../../universal_directory";
import { getCompanyDepartmentStructures, getDeptChildDepartmentStructures } from "../departments_structure/departments_structure_thunk";


export const getCompanyById = createAsyncThunk<
    ICompany,
    { companyId: string, departmentId?: string, parentDeptId?: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@company/company_details/company/get',
    async (doc, thunkAPI) => {
        try {
            const res = doc.departmentId ? await CompaniesAPI.departmentById(doc.departmentId) : await CompaniesAPI.companyById(doc.companyId);
            thunkAPI.dispatch(getCompanyDepartmentStructures(doc));
            thunkAPI.dispatch(getDeptChildDepartmentStructures(doc));
            thunkAPI.dispatch(getCompanyAllSkills(doc));
            thunkAPI.dispatch(getCompanyAccountManagers(doc));
            thunkAPI.dispatch(getCompanyRecruiters(doc));
            thunkAPI.dispatch(getCompanySalesProfile(doc));
            thunkAPI.dispatch(getCompanyServiceProfile(doc));
            return res.data as (ICompany | IDepartment);
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


/// [Company] ---> Skills
export const getCompanyAllSkills = createAsyncThunk<
    ISkillsInfo,
    { companyId: string, departmentId?: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@company/company_details/company/skills/get',
    async (payload, thunkAPI) => {
        try {
            const res = await CompaniesAPI.getCompanySkills(payload);
            if (typeof res.data === 'string') return {
                [TALENT_SKILLS_TYPE.professional_skills]: [],
                [TALENT_SKILLS_TYPE.certifications]: [],
                [TALENT_SKILLS_TYPE.education]: [],
                [TALENT_SKILLS_TYPE.credentials]: [],
                [TALENT_SKILLS_TYPE.safety_equipment]: []
            };
            return res.data as ISkillsInfo;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const addCompanySkills = createAsyncThunk<
    string,
    { companyId: string, departmentId?: string, value: IAddSkill },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@company/company_details/company/skills/post',
    async (data, thunkAPI) => {
        try {
            const res = await CompaniesAPI.addCompanySkill(data);
            thunkAPI.dispatch(getCompanyAllSkills({ companyId: data.companyId, departmentId: data.departmentId }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const removeCompanySkills = createAsyncThunk<
    string,
    { companyId: string, departmentId?: string, value: IRemoveSkill },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@company/company_details/company/skills/delete',
    async (data, thunkAPI) => {
        try {
            const res = await CompaniesAPI.deleteCompanySkill(data);
            thunkAPI.dispatch(getCompanyAllSkills({ companyId: data.companyId, departmentId: data.departmentId }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);



/// [Company]--> [Alpha omega staffing] --> staff

export const getCompanyAccountManagers = createAsyncThunk<
    Array<Profile>,
    { companyId: string, departmentId?: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@company/company_details/company/account_managers/get',
    async (query, thunkAPI) => {
        try {
            const res = await CompaniesAPI.getCompanyStaff({ ...query, role: ROLE.accountManager, });

            if (typeof res.data === 'string') return [];
            return res.data as Profile[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const addCompanyAccountManagers = createAsyncThunk<
    string,
    IAddCompanyStaff,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@company/company_details/company/account_managers/post',
    async (data, thunkAPI) => {
        try {
            const res = await CompaniesAPI.addCompanyStaff(data);
            thunkAPI.dispatch(getCompanyAccountManagers({ companyId: data.company_id, departmentId: data.department_id }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const removeCompanyAccountManagers = createAsyncThunk<
    string,
    IRemoveCompanyStaff,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@company/company_details/company/account_managers/delete',
    async (data, thunkAPI) => {
        try {
            const res = await CompaniesAPI.deleteCompanyStaff(data);
            thunkAPI.dispatch(getCompanyAccountManagers({ companyId: data.company_id, departmentId: data.department_id }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const getCompanyRecruiters = createAsyncThunk<
    Array<Profile>,
    { companyId: string, departmentId?: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@company/company_details/company/recruiters/get',
    async (query, thunkAPI) => {
        try {
            const res = await CompaniesAPI.getCompanyStaff({ ...query, role: ROLE.recruiter, });
            if (typeof res.data === 'string') return [];
            return res.data as Profile[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const addCompanyRecruiters = createAsyncThunk<
    string,
    IAddCompanyStaff,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@company/company_details/company/recruiters/post',
    async (data, thunkAPI) => {
        try {
            const res = await CompaniesAPI.addCompanyStaff(data);
            thunkAPI.dispatch(getCompanyRecruiters({ companyId: data.company_id, departmentId: data.department_id }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const removeCompanyRecruiters = createAsyncThunk<
    string,
    IRemoveCompanyStaff,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@company/company_details/company/recruiters/delete',
    async (data, thunkAPI) => {
        try {
            const res = await CompaniesAPI.deleteCompanyStaff(data);
            thunkAPI.dispatch(getCompanyRecruiters({ companyId: data.company_id, departmentId: data.department_id }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);