import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../components/action_dialog_holder/action_dialog_holder";
import ApiError from "../../../components/api_error";
import { THEME, LoadingType } from "../../../enums";
import { saveCertification, saveCredential, saveEducationRequirement, saveEquipment, saveSkill, selectSave_state } from "../../../redux/admin_center";
import { manageSkillListActions } from "../../../redux/admin_center/system_configuration/skill_list/skill_list_reducer";
import { postSnackbarMessage, useAppDispatch, useAppSelector } from "../../../redux/store";
import { CustomButton, FormInput } from "../../../utils";
import '../user_management/action.scss';
import DialogWrapper, { FormDialogTilteHeader } from "../../../components/dialog_wrapper/dialog_wrapper";
import { SkillSetsIcon } from "../../../icons";

interface ICategoryDetails {
    showCategoryDialog: boolean,
    setShowCategoryDialog: any,
    categoryId: number;
    selectedData: any
}
const SystemCategoryDetails = ({ showCategoryDialog, setShowCategoryDialog, categoryId, selectedData }: ICategoryDetails) => {
    const dispatch = useAppDispatch();
    const { loading, error, response } = useAppSelector((state) => selectSave_state(state));
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [description, setDescription] = useState(selectedData.value);

    useEffect(() => {
        if (loading == LoadingType.succeeded) {
            dispatch(postSnackbarMessage(response ?? null));
            closePopup(onClose);
            dispatch(manageSkillListActions.clearCreateState());
        }
        return () => { }
    }, [loading])


    const headerTitle = () => {
        switch (categoryId) {
            case 1: return "professional_skills";
            case 2: return "certifications";
            case 3: return "credentials";
            case 4: return "education_requirements";
            case 5: return "equipment";
            default: return "";
        }
    }

    const onClose = () => {
        setShowCategoryDialog(false);
    }
    const onDissmiss = () => {
        setShowCategoryDialog(false);
    }
    function handleFieldChange(fieldId, value) {
        setDescription(value);
    }
    const addCategory = () => {
        const payload = {
            id: selectedData.id,
            value: description,
            sector_id: selectedData.sector_id,
            agency_id: ""
        };

        switch (categoryId) {
            case 1:
                dispatch(saveSkill(payload));
                break;
            case 2:
                dispatch(saveCertification(payload));
                break;
            case 3:
                dispatch(saveCredential(payload));
                break;
            case 4:
                dispatch(saveEducationRequirement(payload));
                break;
            case 5:
                dispatch(saveEquipment(payload));
                break;
            default:
                break;
        }
    }
    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={showCategoryDialog}
            wrapperWidth={'30%'}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={() => {
                handleClearError();
                closePopup(onClose);
            }}>
                <div className="add-role-container">
                    <div className="add-role-header">
                        <FormDialogTilteHeader
                            title={`${selectedData.id ? t("update") : t("add")}` + " " + `${t(headerTitle())}`}
                            titleIcon={<SkillSetsIcon width={"100%"} height={"100%"} style={{ color: THEME.talentOptionTextColor }} />}
                        />
                    </div>
                    <div className="add-role-content add-role-content-des">
                        <FormInput
                            id={description}
                            onChange={handleFieldChange}
                            placeholder={"Enter Value"}
                            type={"text"}
                            value={description}
                            label={t("")}
                            keyboardtype={''}
                        />
                        {error && <div className={"error-section"}>
                            <ApiError message={error.message} onClose={handleClearError} />
                        </div>}

                    </div>
                    <div className="add-role-action-holder">
                        <div className="add-role-action">
                            <div className="btn-no">
                                <CustomButton
                                    loading={false}
                                    textStyle={{ textTransform: 'capitalize' }}
                                    name={t('cancel')}
                                    enable={true}
                                    backgroundColor={THEME.secondaryColor4}
                                    onClick={() => closePopup(onClose)}
                                />
                            </div>
                            <div className="btn-yes">
                                <CustomButton
                                    loading={loading === LoadingType.pending}
                                    textStyle={{ textTransform: 'capitalize' }}
                                    name={selectedData.id === "" ? t('add') : t('update')}
                                    backgroundColor={THEME.defaultHighLightColor}
                                    onClick={addCategory}
                                    enable={description.length > 0}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </DialogWrapper>
        </ActionDialogHolder>
    )

    function handleClearError() {
        dispatch(manageSkillListActions.clearCreateState());
    }
}

export default SystemCategoryDetails;