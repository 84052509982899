import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { RouteComponentProps } from "react-router-dom";
import CustomFormSelect, { CustomMultiFormSelect } from "../../../../components/form_selector/form_select";
import SortableTable, {
  TableData,
} from "../../../../components/sortable_table/sortable_table";
import TableEmpty, {
  TableErrorHandler,
  TableLoading,
} from "../../../../components/table_empty/table_empty";
import { COMPANY_TYPE, LoadingType, THEME } from "../../../../enums";
import { ICompany, IDepartment } from "../../../../interfaces";
import { manageCompanyActions } from "../../../../redux/companies/universal_directory/manage_company/manage_company_reducer";
import {
  selectMyCallDeckListState,
} from "../../../../redux/sales_marketing/my_call_deck/my_call_deck_selector";
import { getMyCallDeckList, getMyCallDeckListPage } from "../../../../redux/sales_marketing/my_call_deck/my_call_deck_thunk";
import {
  getRoleUsersList,
  postSnackbarMessage,
  selectAccountManagerRoleUsersList,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { AppRoutes } from "../../../../routes";
import {
  CustomButton,
  getPlusBtnIcon,
  getTableAccessIcon,
  getWebsiteInfo,
  sortByOrders,
} from "../../../../utils";
import OpenOrderRow from "../../../../utils/open_orders";
import CreateCompanyPopup from "../../../companies/popup/create_company/create_company";
import "./my_call_deck.scss";
import SearchBar from "../../../../components/search_bar";
import { getCompanyStatus, selectCompanyStatusList } from "../../../../redux/admin_center";
import { IIMyCallDeckByPage } from "../../../../interfaces/my_call_deck";
import Pagination from "../../../components/pagination/pagination";

interface Props extends RouteComponentProps<any> { }

const tableHeader = [
  { title: "access_company", code: "access_company" },
  { title: "open_orders", code: "open_orders" },
  { title: "company", code: "name" },
  { title: "department", code: "name" },
  { title: "Industry Sector", code: "business_sector" },
  { title: "website", code: "website" },
  { title: "status", code: "status" },
];

const MyCallDeck: FC<Props> = (props) => {
  const { t } = useTranslation();
  const [sortedField, setSortedField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [search, setSearch] = useState("");

  const dispatch = useAppDispatch();
  const [managerFilter, setManagerFilter] = useState<string[]>([]);
  const [managerList, setManagerList]: any = useState([]);
  const accountManagersList = useAppSelector((state) =>
    selectAccountManagerRoleUsersList(state)
  );
  const companyStatusList = useAppSelector((state) => selectCompanyStatusList(state));
  const [status, setStatus] = useState<string[]>([]);

  const { loading, error, myCallDeckByPage } = useAppSelector((state) =>
    selectMyCallDeckListState(state)
);

  const [myCallDeckList, setMyCallDeckList] = useState<IIMyCallDeckByPage | null>(null);
  const [addCompany, setAddCompany] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [resetPage, setResetPage] = useState<boolean>(false);
  const [size, setSize]=useState(25)

  useEffect(() => {
    getmyCallDeck();
    return () => { };
  }, [managerFilter, status]);

  useEffect(() => {
    if (resetPage) return getmyCallDeck();
    if (search?.length === 0) return getmyCallDeck();
    return () => {
      setResetPage(false)
    }
  }, [resetPage, search])
  
  useEffect(() => {
    if (accountManagersList && accountManagersList.length > 0) {
      const list: any = [];
      accountManagersList.map((h: any) => {
        list.push({
          value: h.id,
          label: h.last_name + " " + h.first_name,
        });
      });
      setManagerList(list);
    }
  }, [accountManagersList]);
  
  useEffect(() => {
    if (myCallDeckByPage?.loading === LoadingType.succeeded && myCallDeckByPage?.response) {
      setMyCallDeckList(myCallDeckByPage?.response);
    }
  }, [myCallDeckByPage?.loading]);
  
  const getmyCallDeck = () => {
    dispatch(getMyCallDeckListPage({ page: resetPage ? 1 : currentPage, size: size, filter_ids: { acc_manager_ids: managerFilter, status_ids: status }, search_str: search }));
  };

  function handleSortFieldChange(value: string) {
    if (value === "open_orders") {
      if (sortedField && sortedField === value) {
      } else {
        setSortedField(value);
      }
    }
    else {
      if (sortedField && sortedField === value) {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      } else {
        setSortedField(value);
        setSortDirection('asc');
      }
    }
  }

  useEffect(() => {
    dispatch(getRoleUsersList());
    dispatch(getCompanyStatus());
  }, []);

  useEffect(() => {
    if (error != null && myCallDeckList?.items?.length != 0) {
      dispatch(postSnackbarMessage(error.message));
    }
  }, [error]);

  const getFilteredList = (): (ICompany | IDepartment)[] => {
    let list = [...myCallDeckList?.items ?? []];
    let resultList: (ICompany | IDepartment)[] | undefined;
    if (sortedField != null) {
      if (sortedField === "open_orders") {
        list = sortByOrders(list, "order_status");
      } else {
        list = [...list].sort((a, b) => {
          const valueA =
            a[sortedField] != null && a[sortedField] != undefined
              ? typeof a[sortedField] == typeof "1"
                ? a[sortedField].trim().toLowerCase()
                : a[sortedField]
              : "";
          const valueB =
            b[sortedField] != null
              ? typeof b[sortedField] == typeof "1"
                ? b[sortedField].trim().toLowerCase()
                : b[sortedField]
              : "";
          if (sortDirection === "asc") {
            return valueA > valueB ? 1 : -1;
          } else {
            return valueA < valueB ? 1 : -1;
          }
        });
      }
    }

    return resultList ?? list;
  };

  const showSpinner = () => loading === LoadingType.pending;

  const getTable = () => {
    if (showSpinner()) return <TableLoading />;

    if (error != null && myCallDeckList?.items?.length === 0) {
      return <TableErrorHandler error={error} onRefresh={getmyCallDeck} />;
    }

    if (myCallDeckList?.items?.length === 0 || myCallDeckList?.items?.length === 0) {
      return <TableEmpty title={"No calls found"} onClick={getmyCallDeck} />;
    }

    if (myCallDeckList?.items?.length !== 0) {
      return (
        <SortableTable
          headerList={tableHeader}
          sortedField={sortedField}
          onSortChange={handleSortFieldChange}
          flexNumber={getFlexNumber}
          isAsc={sortDirection}
        >
          {myCallDeckList?.items &&
            getFilteredList().length > 0 &&
            getFilteredList().map((doc: any) => {
              return (
                <tr key={doc.id}>
                  <TableData customStyle={{ flex: getFlexNumber(0) }}>
                    {getTableAccessIcon(() =>
                      doc.type === COMPANY_TYPE.department
                        ? handleDepartmentSelect(doc)
                        : handleCompanySelect(doc)
                    )}
                  </TableData>
                  <TableData customStyle={{ flex: getFlexNumber(1) }}>
                    {OpenOrderRow(doc, "order_status", t)}
                  </TableData>
                  <TableData
                    customStyle={{ flex: getFlexNumber(2) }}
                    isButton={true}
                    onClick={() => handleCompanySelect(doc)}
                  >
                    <span>
                      {doc.type === COMPANY_TYPE.company
                        ? doc.name
                        : doc.company_name}
                    </span>
                  </TableData>
                  <TableData
                    customStyle={{ flex: getFlexNumber(3) }}
                    isButton={true}
                    onClick={() => handleDepartmentSelect(doc)}
                  >
                    <span>
                      {doc.type === COMPANY_TYPE.department
                        ? doc.name
                        : t("corporate")}
                    </span>
                  </TableData>
                  <TableData customStyle={{ flex: getFlexNumber(4) }}>
                    <span>{doc.business_sector}</span>
                  </TableData>
                  <TableData customStyle={{ flex: getFlexNumber(5) }}>
                    {getWebsiteInfo(doc.website)}
                  </TableData>
                  <TableData customStyle={{ flex: getFlexNumber(6) }}>
                    <span>
                      {doc.status ?? ""}
                    </span>
                  </TableData>
                </tr>
              );
            })}
        </SortableTable>
      );
    }
  };

  function getFlexNumber(value: number) {
    if (value === 0) return 1;
    if (value === 1) return 2;
    if (value === 2) return 2;
    if (value === 3) return 2;
    if (value === 4) return 2;
    if (value === 5) return 1;
    if (value === 6) return 2;
    return 1;
  }

  function handleCompanySelect(value: ICompany | IDepartment) {
    if (value.type === COMPANY_TYPE.company) {
      props.history.push({
        pathname: `${AppRoutes.companiesDetailsPage}/${value.id}`,
        state: {
          id: value.id,
          name: value.name,
        },
      });
      return;
    } else if (
      value.type === COMPANY_TYPE.department &&
      (value as IDepartment).company_id != null
    ) {
      const department = value as IDepartment;
      props.history.push({
        pathname: `${AppRoutes.companiesDetailsPage}/${department.company_id}`,
        state: {
          id: department.company_id,
          name: department.company_name,
        },
      });
      return;
    } else {
      dispatch(postSnackbarMessage(t("unable_to_open_please_try_again_later")));
    }
  }

  function handleDepartmentSelect(value: ICompany | IDepartment) {
    if (value.type === COMPANY_TYPE.company) {
      props.history.push({
        pathname: `${AppRoutes.companiesDetailsPage}/${value.id}`,
        state: {
          id: value.id,
          name: value.name,
        },
      });
      return;
    } else if (
      value.type === COMPANY_TYPE.department &&
      (value as IDepartment).company_id != null
    ) {
      const department = value as IDepartment;
      props.history.push({
        pathname: `${AppRoutes.companiesDetailsPage}/${department.company_id}/dashboard/${department.id}`,
        state: {
          id: department.company_id,
          name: department.company_name,
          departmentId: department.id,
          departmentName: department.name,
        },
      });
      return;
    } else {
      dispatch(postSnackbarMessage(t("unable_to_open_please_try_again_later")));
    }
  }

  function handleGotoAgencyDashboard() {
    handleAddCompanySuccess();
    props.history.push(AppRoutes.dashboardPage);
  }

  function handleOnGotoCompany(id: string, name: string) {
    handleAddCompanySuccess();
    props.history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${id}`,
      state: {
        id: id,
        name: name,
      },
    });
  }

  function handleAddCompanySuccess() {
    setAddCompany(false);
    getmyCallDeck();
    dispatch(manageCompanyActions.clearCreateCompanyState());
  }

  const handlePageChange = (pageNum: number, size: number) => {
    dispatch(getMyCallDeckListPage({ page: resetPage ? 1 : pageNum, size: size, filter_ids: { acc_manager_ids: managerFilter, status_ids: status }, search_str: search }));
    setCurrentPage(pageNum);
    setSize(size ? size : myCallDeckList?.size!)
  };

  const handleSearch = () => {
    setResetPage(true)
  }

  return (
    <div className="cps-ud-container">
      <div className="cps-ud-table-header">
        <div className="cps-ud-table-header-option">
          <div className="cps-ud-table-search-bar">
            <CustomMultiFormSelect
              label={t("account_manager")}
              name={"account_manager"}
              list={managerList}
              onChange={setManagerFilter}
              required={false}
              placeholder={t("select")}
              value={managerFilter}
              customStyle={{ width: "-webkit-fill-available" }}
            />
          </div>
          <div className="company-status-selector">
            <CustomMultiFormSelect
              label={t("status")}
              name={"company_status"}
              list={companyStatusList.map((doc) => ({
                label: doc.company_status,
                value: doc.id,
              }))}
              onChange={setStatus}
              required={false}
              placeholder={t("all")}
              value={status}
              customStyle={{ width: "-webkit-fill-available" }}
            />
          </div>
          <div className="total-count">
            <span>
              {`${t("total_count")}: `}
              <span className="total-count-number">
                {myCallDeckList?.total!}
              </span>
            </span>
          </div>
        </div>
        <div className="cps-ud-actions">
          <div className="mcd-search">
            <SearchBar
              value={search}
              onChange={(value) => setSearch(value)}
              loading={myCallDeckByPage?.loading === LoadingType.pending && search?.length !== 0}
              onSearch={handleSearch}
              enable={search?.length >= 2}
            />
          </div>
          <div className="cps-ud-add-user-btn">
            <CustomButton
              leftIcon={getPlusBtnIcon()}
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("add_company")}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() => setAddCompany(true)}
            />
          </div>
        </div>
      </div>
      <div className="cps-ud-table">{getTable()}</div>
      <div className="as-table-footer">
        <Pagination
          totalPages={myCallDeckList?.pages!}
          onPageChange={handlePageChange}
          curPageNum={currentPage}
          totalCount={myCallDeckList?.total!}
          size={myCallDeckList?.size!}
          loadingState={myCallDeckByPage?.loading}
        />
      </div>
      {addCompany && (
        <Portal>
          <CreateCompanyPopup
            visible={addCompany}
            title={t("add_company")}
            successTitle={t("new_company")}
            onClose={() => setAddCompany(false)}
            onSuccessClose={handleAddCompanySuccess}
            onGotoAgencyDashboard={handleGotoAgencyDashboard}
            onGotoCreated={handleOnGotoCompany}
          />
        </Portal>
      )}
    </div>
  );
};

export default MyCallDeck;
