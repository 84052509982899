import { useTranslation } from "react-i18next";
import { CustomButton, FormInput, RegxPattern } from "../../../../utils";
import { useEffect, useState } from "react";
import ApiError from "../../../../components/api_error";
import { RouteComponentProps } from "react-router-dom";
import { postSnackbarMessage, useAppDispatch, useAppSelector } from "../../../../redux/store";
import { getEmailConfigurationList, selectEmailConfigurationState, updateEmailConfigurationList } from "../../../../redux/admin_center";
import { LoadingType, THEME } from "../../../../enums";
import TabOptionsHeader from "../../../../components/tab_options_header/tab_options_header";
import { AdminCenterEmailConfiguration } from "../../../../interfaces";
import { clearEmailConfigurationUpdateStateError } from "../../../../redux/admin_center/system_configuration/email_configuration/email_configuration_reducer";
import { EditIcon } from "../../../../icons";
import './email_configuration.scss';

interface Props extends RouteComponentProps<any> { }

const EmailConfiguration: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const emailConfigState = useAppSelector((state) => selectEmailConfigurationState(state))
    const [formState, setFormState] = useState<AdminCenterEmailConfiguration | null>(null)
    const [inputEnable, setInputEnable] = useState<boolean>(true);
    const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getEmailConfigurationList())
        return () => {
            handleClearError()
        }
    }, [])

    useEffect(() => {
        if (emailConfigState?.loading === LoadingType.succeeded && emailConfigState?.response) {
            setFormState(emailConfigState?.response);
        }
    }, [emailConfigState?.loading, emailConfigState?.response]);

    useEffect(() => {
        if (emailConfigState?.update?.loading === LoadingType.succeeded && emailConfigState?.update?.response) {
            dispatch(postSnackbarMessage(emailConfigState?.update?.response))
        }
    }, [emailConfigState?.update?.loading, emailConfigState?.update?.response]);

    const handleFieldChange = (fieldId: string, value: string) => {
        setFormState({ ...formState, [fieldId]: value });
    }

    const handleEdit = () => {
        setInputEnable(false);
    };

    const handleSave = () => {
        setCheckValidationOnSubmit(true);
        if (_isFormValid() && formState?.email && formState.password) {
            dispatch(updateEmailConfigurationList(formState));
            setInputEnable(true);
            setCheckValidationOnSubmit(false);
        }
    };

    const handleClearError = () => {
        dispatch(clearEmailConfigurationUpdateStateError())
    }
    const _isFormValid = () => {
        if (formState?.email === "" || formState?.password === "") return false;
        if (!RegxPattern.email.test(formState?.email!)) return false;
        return true;
    };
    
    return (
        <div className="sys-config-email-config-container">
            <div><TabOptionsHeader onClose={() => props.history.goBack()} /></div>
            <div className="sys-config-email-config-content">
                <div className="header-content">
                    <div className="sc-ec-header-title"><span>{t('email_configuration')}</span></div>
                    <button className="icon-btn ac-ob-edit-icon" onClick={() => { }}>
                        <EditIcon
                            width={"100%"}
                            height={"100%"}
                            style={{ color: "#fff" }}
                            onClick={handleEdit}
                        />
                    </button>
                </div>
                <div className="body-content">
                    <FormInput
                        id={"email"}
                        onChange={handleFieldChange}
                        type={"text"}
                        value={formState?.email}
                        label={t("email")}
                        placeholder=""
                        customStyle={{ maxWidth: "30vw" }}
                        disabled={inputEnable}
                        validations={formValidators}
                        checkValidationOnSubmit={checkValidationOnSubmit}
                        required
                    />
                </div>
                <div>
                    <FormInput
                        id={"password"}
                        onChange={handleFieldChange}
                        type={"password"}
                        value={formState?.password}
                        label={t("password")}
                        placeholder=""
                        customStyle={{ maxWidth: "30vw" }}
                        disabled={inputEnable}
                        validations={formValidators}
                        checkValidationOnSubmit={checkValidationOnSubmit}
                        required
                    />
                </div>
                <div className="sc-ec-actions-holder">
                    <div className="error-holder">
                        {emailConfigState?.update?.error && <ApiError message={emailConfigState?.update?.error?.message} onClose={handleClearError} />}
                    </div>
                    <div className="sc-ec-actions">
                        <div className="btn-cancel">
                            <CustomButton
                                name={t('cancel')}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => {
                                    setFormState(emailConfigState?.response);
                                    handleClearError()
                                    setInputEnable(true);
                                    setCheckValidationOnSubmit(true)
                                }}
                            />
                        </div>
                        <div className="btn-save">
                            <CustomButton
                                loading={emailConfigState?.update?.loading === LoadingType.pending}
                                backgroundColor={THEME.buttonColor1}
                                name={t('update')}
                                onClick={handleSave}
                                enable={!inputEnable}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    function formValidators(value: { text: any, field: string, }) {
        switch (value.field) {
            case "email": {
                if (value.text === null || value.text === "") return t('validators.required');
                if (!RegxPattern.email.test(formState?.email!)) return t('validators.enterValidEmail');
                return null;
            };
            case "password": {
                if (value.text === "") return t("validators.required");
                return null;
              }
            default: {
                return null;
            }
        }
    }

}

export default EmailConfiguration;