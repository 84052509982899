import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { THEME, LoadingType } from "../../../../enums";
import { AgencySetup, WorkCompConfigIcon } from "../../../../icons";
import { AppError } from "../../../../interfaces";
import { FormInput, CustomButton, allowNumbers, convertNumberToMoney, formatToMoney } from "../../../../utils";
import CustomFormSelect from "../../../../components/form_selector/form_select";
import { US_states } from "../../../../utils/States_US";
import "./add_work_comp.scss"
import { createWorkCompConfigList, updateWorkCompConfigList } from "../../../../redux/admin_center";
import { AddWorkCompConfiguration, WorkCompConfiguration } from "../../../../interfaces/work_comp_configuration";
import { postSnackbarMessage, useAppDispatch } from "../../../../redux/store";
import { WorkCompConfigListState } from "../../../../redux/admin_center/work_comp_configuration/work_comp_configuration_reducer";
import ApiError from "../../../../components/api_error";
import { DateInput } from "../../../../components/date_input/date_input";

interface Props {
    visible: boolean;
    workCompState?: WorkCompConfiguration;
    onClose: () => void;
    onDismiss: () => void;
    error: AppError | null | undefined;
    loading: LoadingType;
    onSuccessClose: () => void;
    onClearError: () => void;
    workCompListState: WorkCompConfigListState;
}

const workCompTaxInitialForm: AddWorkCompConfiguration = {
    agency_id: "",
    id: "",
    work_comp_code: "",
    base_rate: 0,
    start_date: 0,
    end_date: 0,
    code_description: "",
    state: "",
};

const getWorkCompFormData = (value: WorkCompConfiguration): AddWorkCompConfiguration => {
    return {
        id: value.id ?? "",
        agency_id: value.agency_id ?? "",
        work_comp_code: value.work_comp_code ?? "",
        base_rate: value.base_rate ?? 0,
        start_date: value.start_date ?? 0,
        end_date: value.end_date ?? 0,
        code_description: value.code_description ?? "",
        state: value.state ?? "",
    }
}

const AddWorkCompPopUp: React.FunctionComponent<Props> = (props) => {
    const { onClose, onDismiss, visible, workCompState, loading, error, workCompListState, onSuccessClose, onClearError } = props;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [workCompDetails, setWorkCompDetails] = useState<AddWorkCompConfiguration>(
        workCompState ? getWorkCompFormData(workCompState) : workCompTaxInitialForm
    );
    const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean | undefined>();

    useEffect(() => {
        if (workCompListState.create.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage("Work Comp Configuration added successfully!"));
            closePopup(onSuccessClose);
        }
        else if (workCompListState.update.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage("Work Comp Configuration updated successfully!"));
            closePopup(onSuccessClose);
        }

    }, [workCompListState.create.loading, workCompListState.update.loading])

    function handleSave() {
        setCheckValidationOnSubmit(true);
        if (_isFormValid()) {
            if (workCompState?.id) {
                dispatch(updateWorkCompConfigList({
                    ...workCompDetails,
                    work_comp_code: workCompDetails.work_comp_code.trim(),
                    base_rate: convertNumberToMoney(workCompDetails.base_rate, 'number') as number,
                }));
            }
            else {
                dispatch(createWorkCompConfigList({
                    ...workCompDetails,
                    work_comp_code: workCompDetails.work_comp_code.trim(),
                    base_rate: convertNumberToMoney(workCompDetails.base_rate, 'number') as number,
                }));
            }
            setCheckValidationOnSubmit(false);
        }
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    function handleOnChange(id, value) {
        setWorkCompDetails({ ...workCompDetails, [id]: value });
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={'30%'}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <DialogWrapper onClose={() => closePopup(onClose)}>
                <div className="ac-wcc-awc-popup-container">
                    <div className="ac-wcc-awc-popup-header">
                        <FormDialogTilteHeader
                            title={workCompState && workCompState?.id ? "Edit Work Comp" : "Add Work Comp"}
                            titleIcon={<WorkCompConfigIcon width={"100%"} height={"100%"} style={{ color: THEME.talentOptionTextColor }} />}
                        />
                    </div>
                    <div className="ac-wcc-awc-popup-content">
                        <div className="ac-wcc-awc-popup-row">
                            <div className={"ac-wcc-awc-popup-input-holder"}>
                                <FormInput
                                    id={"work_comp_code"}
                                    onChange={(id, val) => handleOnChange(id, val)}
                                    placeholder={"Work Comp Name"}
                                    type={"text"}
                                    value={workCompDetails.work_comp_code}
                                    required={true}
                                    label={t("work_comp_class_code")}
                                    // disabled={workCompState?.id ? true : false}
                                    validations={formValidators}
                                    checkValidationOnSubmit={checkValidationOnSubmit}
                                />
                            </div>
                            <div className={"ac-wcc-awc-popup-input-holder"}>
                                <CustomFormSelect
                                    list={US_states.map((doc) => ({
                                        label: doc.name,
                                        value: doc.code,
                                    }))}
                                    name={"state"}
                                    value={workCompDetails.state}
                                    required={false}
                                    label={t("state")}
                                    placeholder={"Select"}
                                    onChange={(val) => handleOnChange('state', val)}
                                />
                            </div>
                            <div className={"ac-wcc-awc-popup-input-holder"}>
                                <FormInput
                                    id={'base_rate'}
                                    onChange={(id, val) => handleOnChange(id, val)}
                                    required={false}
                                    placeholder={""}
                                    type={"text"}
                                    value={convertNumberToMoney(workCompDetails.base_rate, "string") as string}
                                    label={t("base_rate")}
                                    prefix={<span>$</span>}
                                    onBlur={formatToMoney}
                                    onKeyDown={formatToMoney}
                                    onKeyUp={formatToMoney}
                                />
                            </div>
                        </div>
                        <div className="ac-wcc-awc-popup-date-row">
                            <div className={"ac-wcc-awc-popup-date-input-holder"}>
                                <DateInput
                                    id={'start_date'}
                                    onChange={(id, val) => handleOnChange(id, val)}
                                    placeholder={t("")}
                                    value={workCompDetails.start_date}
                                    label={t('start_date')}
                                    setHoursToZero={true}
                                    required={true}
                                    validations={formValidators}
                                    checkValidationOnSubmit={checkValidationOnSubmit}
                                />
                            </div>
                            <div className={"ac-wcc-awc-popup-date-input-holder"}>
                                <DateInput
                                    id={'end_date'}
                                    onChange={(id, val) => handleOnChange(id, val)}
                                    placeholder={t("")}
                                    value={workCompDetails.end_date}
                                    label={t('end_date')}
                                    setHoursToZero={true}
                                    required={true}
                                    validations={formValidators}
                                    checkValidationOnSubmit={checkValidationOnSubmit}
                                />
                            </div>
                        </div>
                        <div className={"ac-wcc-awc-popup-input-holder"}>
                            <FormInput
                                id={'code_description'}
                                onChange={(id, val) => handleOnChange(id, val)}
                                required={false}
                                placeholder={""}
                                type={"text"}
                                multiline={true}
                                value={workCompDetails.code_description}
                                label={t("description")}
                                customStyle={{ width: '32vw' }}
                                validations={formValidators}
                            />
                        </div>
                        {error && (
                            <div className={"error-section"}>
                                <ApiError
                                    message={error?.message}
                                    onClose={onClearError}
                                />
                            </div>
                        )}
                    </div>
                    <div className="ac-wcc-awc-popup-actions">
                        <div className="btn-cancel">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('cancel')}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => closePopup(onClose)}
                            />
                        </div>
                        <div className="btn-save">
                            <CustomButton
                                textStyle={{ textTransform: 'capitalize' }}
                                name={(workCompState && workCompState?.id) ? t('update') : t('add')}
                                // enable={_isFormValid()}
                                enable={true}
                                loading={loading === LoadingType.pending}
                                backgroundColor={THEME.buttonColor1}
                                onClick={handleSave}
                            />
                        </div>

                    </div>
                </div>
            </DialogWrapper>


        </ActionDialogHolder>
    )

    function _isFormValid() {
        const { work_comp_code, start_date, end_date } = workCompDetails;
        if (work_comp_code === '') return false;
        if (!start_date) return false;
        if (!end_date) return false;
        return true;
    }

    function formValidators(value: { text: string; field: string }) {
        switch (value.field) {
            case "work_comp_code": {
                if (value.text === "") return t("validators.required");
                return null;
            }
            case "code_description": {
                if (value.text === "") return t("validators.required");
                return null;
            }
            case "start_date": {
                if (!value.text) return t("validators.required");
                return null;
            }
            case "end_date": {
                if (!value.text) return t("validators.required");
                return null;
            }
            default: {
                return null;
            }
        }
    }
}

export default AddWorkCompPopUp