import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError, ICompetitor, ICompetitorsByPage } from "../../../interfaces";
import * as thunk from "./competitors_list_thunk";

export interface CompetitorsListState extends EntityState<ICompetitor> {
    error?: AppError | null,
    loading: LoadingType,
    competitorsByPage: {
        error?: AppError | null;
        loading: LoadingType | null;
        response: ICompetitorsByPage | null;
    }
}

const competitorsListAdapter: EntityAdapter<ICompetitor> = createEntityAdapter<ICompetitor>({
    selectId: (doc) => doc.id,
})

const initialState: CompetitorsListState = competitorsListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    competitorsByPage: {
        error: null,
        loading: LoadingType.idle,
        response: null
    }
})


export const competitorsListSlice = createSlice({
    name: 'competitorsList',
    initialState: initialState,
    reducers: {
        clearcompetitorsListError(state){
            return {...state, error: null};
        },
        clearCompetitorsListState(state){
            return competitorsListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getCompetitorsList.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getCompetitorsList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getCompetitorsList.fulfilled, (state, action) => competitorsListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addCase(thunk.geCompetitersByPage.pending, (state, action) => ({...state, competitorsByPage: {...state.competitorsByPage, loading: LoadingType.pending, error: null}}))
            .addCase(thunk.geCompetitersByPage.rejected, (state, action) => ({...state, competitorsByPage: {...state.competitorsByPage, loading: LoadingType.failed, error: action.payload} }))
            .addCase(thunk.geCompetitersByPage.fulfilled, (state, action) => ({...state, competitorsByPage: {...state.competitorsByPage, loading: LoadingType.succeeded, response: action.payload}}))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearcompetitorsListError, clearCompetitorsListState } = competitorsListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = competitorsListAdapter.getSelectors();
export const selectState = (state: CompetitorsListState) => state;
export const selectLoading = (state: CompetitorsListState) => state.loading;
export const selectError = (state: CompetitorsListState) => state.error;


