import { createAsyncThunk } from "@reduxjs/toolkit";
import { CompaniesAPI,  } from "../../../apis";
import { AppError, ICompetitor, ICompetitorByPagePayload, ICompetitorsByPage, } from "../../../interfaces";
import { catchRequestError } from "../../../utils";

export const getCompetitorsList = createAsyncThunk<
    Array<ICompetitor>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@selectors/competitors/get',
    async (_, thunkAPI) => {
        try {
            const res = await CompaniesAPI.getAllCompetitors();
            if(typeof res.data === 'string') return [];
            return res.data as ICompetitor[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const geCompetitersByPage = createAsyncThunk<
    ICompetitorsByPage,
    ICompetitorByPagePayload,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@sales-and-marketing/competitors/competitors-by-page/post',
    async (payload, thunkAPI) => {
        try {
            const res = await CompaniesAPI.getCompetitorsByPage(payload);
            return res.data as ICompetitorsByPage;
        } catch (error) {
            return catchRequestError(error, thunkAPI)
        }
    }
)