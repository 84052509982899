import { useTranslation } from "react-i18next";
import { THEME } from "../enums";
import { SearchIcon } from "../icons";
import './search_bar.scss';
import { CustomButton } from "../utils";

interface Props {
    placeholder?: string,
    value: string,
    onChange: (value: string) => void,
    onSearch: () => void,
    loading?: boolean,
    enable?: boolean
}

const SearchBar = (props: Props) => {

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            props.onSearch();
        }
    };

    const { t } = useTranslation();
    return (
        <div className="sb-container ">
            <div className="form-input-div">
                <input
                    className={""}
                    type={"search"}
                    placeholder={props.placeholder ?? `${t('type_to_search')}...`}
                    onChange={event => {
                        props.onChange(event.target.value);
                    }}
                    value={props.value}
                    autoFocus={false}
                    onKeyDown={handleKeyDown}
                />
            </div>
            <div className="search-btn">
                <CustomButton
                    leftIcon={<SearchIcon />}
                    name={t("")}
                    enable={props.enable}
                    backgroundColor={THEME.defaultHighLightColor}
                    onClick={props.onSearch}
                    customClassName="sb-s-icon"
                    loading={props.loading}
                />
            </div>
        </div>
    )
}

export default SearchBar;