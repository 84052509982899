import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, IJobOrder, IJobOrdersByPage } from "../../../../interfaces";
import * as thunk from "./jobs_list_thunk";

export interface JobsListState extends EntityState<IJobOrder> {
    error?: AppError | null,
    loading: LoadingType,
    jobsByPage: {
        error?: AppError | null;
        loading: LoadingType | null;
        response: IJobOrdersByPage | null;
    }
}

const jobsListAdapter: EntityAdapter<IJobOrder> = createEntityAdapter<IJobOrder>({
    selectId: (doc) => doc.id,
})

const initialState: JobsListState = jobsListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    jobsByPage: {
        error: null,
        loading: LoadingType.idle,
        response: null
    }
})


export const jobsListSlice = createSlice({
    name: 'jobsList',
    initialState: initialState,
    reducers: {
        clearJobsListError(state){
            return {...state, error: null};
        },
        clearJobsListState(state){
            return jobsListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getJobsListThunk.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getJobsListThunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getJobsListThunk.fulfilled, (state, action) => jobsListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addCase(thunk.getJobsByPage.pending, (state, action) => ({...state, jobsByPage: {...state.jobsByPage, loading: LoadingType.pending, error: null}}))
            .addCase(thunk.getJobsByPage.rejected, (state, action) => ({...state, jobsByPage: {...state.jobsByPage, loading: LoadingType.failed, error: action.payload} }))
            .addCase(thunk.getJobsByPage.fulfilled, (state, action) => ({...state, jobsByPage: {...state.jobsByPage, loading: LoadingType.succeeded, response: action.payload}}))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const jobsListActions = jobsListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = jobsListAdapter.getSelectors();
export const selectState = (state: JobsListState) => state;
export const selectLoading = (state: JobsListState) => state.loading;
export const selectError = (state: JobsListState) => state.error;


