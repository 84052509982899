
import axios from "axios";
import {
    IAddSkill,
    IAddTalentStaff,
    ICreateEmploymentHistory,
    ICreateTalent,
    ICreateTalentCertifications,
    ICreateTalentClientCompanyInterview,
    ICreateTalentCrimeHistory,
    ICreateTalentInHouseInterview,
    ICreateTalentInterviewQuestion,
    ICreateTalentInterviewStatus,
    ICreateTalentStatus,
    IRemoveTalentStaff,
    ITalent,
    ITalentClientCompanyInterview,
    ITalentInHouseInterview,
    ITalentInterviewQuestion,
    ITalentInterviewStatus,
    ITalentMatchesRequestBody,
    ITalentOBW4I9Data,
    ITalentsByPagePayload,
    ITalentStatus,
    IUpdateTalentOnboardingDocsReviewStatus,
    IUploadUrlsQuery
} from "../interfaces";
import interceptor from "./interceptor";
import UrlBuilder from "./url_builder";
import { ICreateTalentBOPRDirectDeposit, ITalentBOPRDirectDeposit, ITalentBackOfficePayrollSetup } from "../interfaces/talent_back_office";

export const TalentAPI = {

    /// [Talent]

    getTalents() {
        return interceptor.get(UrlBuilder.talent);
    },

    getTalentsByPage(doc: ITalentsByPagePayload) {
        let url = `${UrlBuilder.getTalentsByPage}?page=${doc.page}&size=${doc.size}`;
        if (doc.employee) {
            url += `&employee=${doc.employee}`;
        }
        if (doc.search_str) {
            url += `&search_str=${doc.search_str}`;
          }
        if (doc.filter_ids) return interceptor.post(url, doc.filter_ids);
        return interceptor.post(url);
    },

    getTalentsDropdown(doc: any) {
        if (doc.employee_portal_access) {
            return interceptor.get(`${UrlBuilder.getTalentDropdown}?employee_portal_access=${doc.employee_portal_access}`)
        }
        return interceptor.get(UrlBuilder.getTalentDropdown)
    },

    createTalent(doc: { forceAdd: boolean, value: ICreateTalent }) {
        if (doc.forceAdd) return interceptor.post(`${UrlBuilder.talent}?force_add=${doc.forceAdd}`, doc.value);
        return interceptor.post(UrlBuilder.talent, doc.value);
    },

    deleteTalent(id: string) {
        return interceptor.delete(`${UrlBuilder.talent}?talent_id=${id}`);
    },

    updateTalent(doc: ITalent) {
        return interceptor.patch(UrlBuilder.talent, doc);
    },

    talentById(talentId: string) {
        return interceptor.get(`${UrlBuilder.talent}?talent_id=${talentId}`);
    },

    /// [Talent status]
    getTalentStatus() {
        return interceptor.get(UrlBuilder.talentStatus);
    },
    createTalentStatus(doc: ICreateTalentStatus) {
        return interceptor.post(UrlBuilder.talentStatus, doc);
    },
    updateTalentStatus(doc: ITalentStatus) {
        return interceptor.patch(UrlBuilder.talentStatus, doc);
    },
    deleteTalentStatus(talentStatusId: string) {
        return interceptor.delete(`${UrlBuilder.talentStatus}?talent_status_id=${talentStatusId}`);
    },

    /// [Talent interview question]
    getTalentInterviewQuestion() {
        return interceptor.get(UrlBuilder.talentInterviewQuestion);
    },
    createTalentInterviewQuestion(doc: ICreateTalentInterviewQuestion) {
        return interceptor.post(UrlBuilder.talentInterviewQuestion, doc);
    },
    updateTalentInterviewQuestion(doc: ITalentInterviewQuestion) {
        return interceptor.patch(UrlBuilder.talentInterviewQuestion, doc);
    },
    deleteTalentInterviewQuestion(talentInterviewQuestionId: string) {
        return interceptor.delete(`${UrlBuilder.talentInterviewQuestion}?talent_interview_question_id=${talentInterviewQuestionId}`);
    },


    /// [Talent interview status]
    getTalentInterviewStatus() {
        return interceptor.get(UrlBuilder.talentInterviewStatus);
    },
    createTalentInterviewStatus(doc: ICreateTalentInterviewStatus) {
        return interceptor.post(UrlBuilder.talentInterviewStatus, doc);
    },
    updateTalentInterviewStatus(doc: ITalentInterviewStatus) {
        return interceptor.patch(UrlBuilder.talentInterviewStatus, doc);
    },
    deleteTalentInterviewStatus(talentInterviewStatusId: string) {
        return interceptor.delete(`${UrlBuilder.talentInterviewStatus}?talent_interview_status_id=${talentInterviewStatusId}`);
    },

    /// [Talent Employment History]
    getTalentEmploymentHistory(talentId: string) {
        return interceptor.get(`${UrlBuilder.talentEmploymentHistoryAll}?talent_id=${talentId}`);
    },

    createTalentEmploymentHistory(doc: { forceAdd: boolean, value: ICreateEmploymentHistory }) {
        return interceptor.post(UrlBuilder.createTalentEploymentHistoryEP, doc.value);
    },
    updateTalentEmploymentHistory(doc: ICreateEmploymentHistory) {
        return interceptor.patch(UrlBuilder.createTalentEploymentHistoryEP, doc);
    },

    /// [Talent] ---> [skills]
    getTalentSkills(talentId: string) {
        return interceptor.get(`${UrlBuilder.talentSkills}?talent_id=${talentId}`);
    },
    addTalentSkill(doc: { talentId: string, value: IAddSkill }) {
        return interceptor.post(`${UrlBuilder.talentSkills}?talent_id=${doc.talentId}`, doc.value);
    },

    deleteTalentSkill(doc: { talentId: string, value: IAddSkill }) {
        return interceptor.delete(`${UrlBuilder.talentSkills}?talent_id=${doc.talentId}`, { data: doc.value });
    },

    /// [Talent] --> Stafff
    getTalentStaff(doc: { talent_id: string, role: string }) {
        return interceptor.get(`${UrlBuilder.talentStaffs}?talent_id=${doc.talent_id}&role=${doc.role}`);
    },

    addTalentStaff(data: IAddTalentStaff) {
        return interceptor.post(UrlBuilder.talentStaffs, data);
    },

    deleteTalentStaff(doc: IRemoveTalentStaff) {
        return interceptor.delete(UrlBuilder.talentStaffs, { data: doc });
    },

    /// [Talent] --> [JOB Matches]

    getJobMatches(doc: { talentId: string, query: ITalentMatchesRequestBody }) {
        return interceptor.post(`${UrlBuilder.jobMatches}?talent_id=${doc.talentId}`, doc.query);
    },

    getCompanyMatches(doc: { talentId: string, query: ITalentMatchesRequestBody }) {
        return interceptor.post(`${UrlBuilder.companyMatches}?talent_id=${doc.talentId}`, doc.query);
    },

    /// [Talent] --> upload fles
    getFileUploadUrls(query: IUploadUrlsQuery) {
        if (query.profile_pic_file_name && query.resume_file_name) {
            return interceptor.get(`${UrlBuilder.talentGetUploadUrls}?object_id=${query.object_id}&profile_pic_file_name=${query.profile_pic_file_name}&resume_file_name=${query.resume_file_name}`);
        }
        if (query.profile_pic_file_name && query.resume_file_name === undefined) {
            return interceptor.get(`${UrlBuilder.talentGetUploadUrls}?object_id=${query.object_id}&profile_pic_file_name=${query.profile_pic_file_name}`);
        }
        if (query.profile_pic_file_name === undefined && query.resume_file_name) {
            return interceptor.get(`${UrlBuilder.talentGetUploadUrls}?object_id=${query.object_id}&resume_file_name=${query.resume_file_name}`);
        }
        return interceptor.get(`${UrlBuilder.talentGetUploadUrls}?object_id=${query.object_id}&profile_pic_file_name=${query.profile_pic_file_name}&resume_file_name=${query.resume_file_name}`);
    },

    uploadPhotoAndResume(url: string, data) {
        return axios.put(url, data);
    },


    /// [Talent] --> In House interviews
    getAllTalentInHouseInterviews(talent_id: string) {
        return interceptor.get(`${UrlBuilder.talentInHouseInterviewsGetAll}?talent_id=${talent_id}`);
    },

    getTalentInHouseInterview(id: string) {
        return interceptor.get(`${UrlBuilder.talentInHouseInterviews}?talent_in_house_interview_id=${id}`);
    },

    postTalentInHouseInterview(doc: ICreateTalentInHouseInterview) {
        return interceptor.post(UrlBuilder.talentInHouseInterviews, doc);
    },
    patchTalentInHouseInterview(doc: ITalentInHouseInterview) {
        return interceptor.patch(`${UrlBuilder.talentInHouseInterviews}?talent_in_house_interview_id=${doc.id}`, doc);
    },
    deleteTalentInHouseInterview(id: string) {
        return interceptor.delete(`${UrlBuilder.talentInHouseInterviews}?talent_in_house_interview_id=${id}`);
    },

    /// [Talent] --> Client Company interviews
    getAllTalentClientInterviews(talent_id: string) {
        return interceptor.get(`${UrlBuilder.talentClientInterviewsGetAll}?talent_id=${talent_id}`);
    },

    getTalentClientInterview(id: string) {
        return interceptor.get(`${UrlBuilder.talentClientInterviews}?talent_client_interview_id=${id}`);
    },

    postTalentClientInterview(doc: ICreateTalentClientCompanyInterview) {
        return interceptor.post(UrlBuilder.talentClientInterviews, doc);
    },

    patchTalentClientInterview(doc: ITalentClientCompanyInterview) {
        return interceptor.patch(`${UrlBuilder.talentClientInterviews}?talent_client_interview_id=${doc.id}`, doc);
    },

    deleteTalentClientInterview(id: string) {
        return interceptor.delete(`${UrlBuilder.talentClientInterviews}?talent_client_interview_id=${id}`);
    },

    /// [Talent] --> Interview questions
    getTalentInHouseInterviewQuestions() {
        return interceptor.get(`${UrlBuilder.talentInterviewQuestions}`);
    },


    /// [Talent] --> Available dates
    getTalentAvailableDates(id: string) {
        return interceptor.get(`${UrlBuilder.talentAvailableDates}?talent_id=${id}`);
    },

    patchTalentAvailableDates(doc: { talent_id: string, available_dates: Array<number> }) {
        return interceptor.patch(UrlBuilder.talentAvailableDates, doc);
    },

    /// [Talent] ---> SSN

    getTalentSSN(id: string) {
        return interceptor.get(`${UrlBuilder.talentSSN}?talent_id=${id}`);
    },

    /// [Talent] ---> Create Login
    createLoginTalent(talentId: string) {
        return interceptor.post(`${UrlBuilder.createLoginTalent}?talent_id=${talentId}`);
    },


    /// [Talent] -->Details ----> Onboarding Docs

    getOnboardingReviewStatus(talentId: string) {
        return interceptor.get(`${UrlBuilder.getOnboardingDocsReviewedStatus}?talent_id=${talentId}`)
    },

    updateOnboardingReviewStatus(doc: IUpdateTalentOnboardingDocsReviewStatus) {
        return interceptor.patch(`${UrlBuilder.updateOnboardingDocsReviewedStatus}?talent_id=${doc.talent_id}`, doc)
    },

    //Certifications
    getTalentCertifications(talent_id: string) {
        return interceptor.get(`${UrlBuilder.talentCertifications}?talent_id=${talent_id}`)
    },
    postTalentCertifications(doc: ICreateTalentCertifications) {
        return interceptor.post(UrlBuilder.talentCertifications, doc)
    },
    updateTalentCertifications(doc: ICreateTalentCertifications) {
        return interceptor.patch(UrlBuilder.talentCertifications, doc)
    },
    deleteTalentCertifications(id: string) {
        return interceptor.delete(`${UrlBuilder.talentCertifications}?skill_doc_id=${id}`);
    },
    getTalentCrimeHistory(talent_id: string) {
        return interceptor.get(`${UrlBuilder.talentCrimeHistory}?talent_id=${talent_id}`)
    },
    postTalentCrimeHistory(doc: ICreateTalentCrimeHistory) {
        return interceptor.post(UrlBuilder.talentCrimeHistory, doc)
    },
    updateTalentCrimeHistory(doc: ICreateTalentCrimeHistory | undefined) {
        return interceptor.patch(UrlBuilder.talentCrimeHistory, doc)
    },
    deleteTalentCrimeHistory(id: string) {
        return interceptor.delete(`${UrlBuilder.talentCrimeHistory}?object_id=${id}`);
    },
    getCertiFileDownloadURL(file_path: string) {
        return interceptor.get(`${UrlBuilder.fileDownLoad}?file_path=${file_path}`);
    },

    getBOTalentPayrollSetup(talent_id: string) {
        return interceptor.get(`${UrlBuilder.backOfficePayrollSetup}?talent_id=${talent_id}`);
    },
    updateBOTalentPayrollSetup(doc: ITalentBackOfficePayrollSetup) {
        return interceptor.patch(`${UrlBuilder.backOfficePayrollSetup}?talent_id=${doc.talent_id}`, doc);
    },
    getBOTalentPayrollSetupDirectDeposit(talent_id: string) {
        return interceptor.get(`${UrlBuilder.backOfficePayrollSetupDirectDeposit}?talent_id=${talent_id}`);
    },
    updateBOTalentPayrollSetupDirectDeposit(talent_id: string, doc: ICreateTalentBOPRDirectDeposit) {
        return interceptor.patch(`${UrlBuilder.backOfficePayrollSetupDirectDepositUpdate}?talent_id=${talent_id}`, doc);
    },
    postValidateTalentDDOnBoarding(data: ITalentBOPRDirectDeposit) {
        return interceptor.post(`${UrlBuilder.validateTalentDD}`, data);
    },


    /// Terminate Talent
    terminateTalent(talent_id: string, termination_date: number) {
        return interceptor.patch(`${UrlBuilder.terminateEmployee}?talent_id=${talent_id}&termination_date=${termination_date}`);
    },

    // send w4 and i9 data to backend 
    updateW4I9DocsData(doc: ITalentOBW4I9Data) {
        return interceptor.patch(UrlBuilder.talentDocsParser, doc);

    }

}