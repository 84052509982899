import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import { AppError, IDepartmentStructure } from "../../../../../interfaces";
import * as thunk from './departments_structure_thunk';

export interface CompanyDepartmentStructurestate {
    error?: AppError | null,
    loading: LoadingType,
    response: Array<IDepartmentStructure> | null,
    getChildDept: {
        error?: AppError | null,
        loading: LoadingType,
        response: Array<IDepartmentStructure> | null,
    }
}

const initialState: CompanyDepartmentStructurestate = {
    error: null,
    loading: LoadingType.idle,
    response: null,
    getChildDept: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    }
}

export const companyDepartmentStructureSlice = createSlice({
    name: 'departmentsStructure',
    initialState: initialState,
    reducers: {
        clearCompanyDepartmentStructureStateError(state) {
            return { ...state, error: null };
        },
        clearCompanyDepartmentStructureState(state) {
            return { ...state, ...initialState };
        },
        clearCompanyDepartmentStructureChildDeptStateError(state) {
            return { ...state, getChildDept: { ...state.getChildDept, error: null } };
        },
        clearCompanyDepartmentStructureChildDeptState(state) {
            return { ...state, getChildDept: { ...state.getChildDept, error: null } };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getCompanyDepartmentStructures.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getCompanyDepartmentStructures.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getCompanyDepartmentStructures.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))

            .addCase(thunk.getDeptChildDepartmentStructures.pending, (state, action) => ({ ...state, getChildDept: { ...state.getChildDept, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.getDeptChildDepartmentStructures.rejected, (state, action) => ({ ...state, getChildDept: { ...state.getChildDept, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.getDeptChildDepartmentStructures.fulfilled, (state, action) => ({ ...state, getChildDept: { ...state.getChildDept, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addDefaultCase(state => ({ ...state }));
    },
});

export const { clearCompanyDepartmentStructureStateError, clearCompanyDepartmentStructureState } = companyDepartmentStructureSlice.actions;
export const selectState = (state: CompanyDepartmentStructurestate) => state;


