import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import { AppError } from "../../../../../interfaces/app_error";
import { ContactListItem } from "../../../../../entities/ContactInfo";
import * as thunk from './company_contact_thunk';
import { IContactsByPage } from "../../../../../interfaces";


export interface CompanyContactsState {
    data: IContactsByPage | null;
    error?: AppError | null,
    loading: LoadingType,
}


const initialState: CompanyContactsState = {
    data: null,
    error: null,
    loading: LoadingType.idle,
}

// function getLoadingState(state: any) {
//     return {
//         ...state,
//         loading: LoadingType.pending,
//         error: null,
//     }
// }

// function getErrorState(state: any, payload: any) {
//     return {
//         ...state,
//         loading: LoadingType.failed,
//         error: payload,
//     }
// }

// function getSuccessState(state: any, payload: any) {
//     return {
//         ...state,
//         loading: LoadingType.succeeded,
//         data: payload,
//         error: null,
//     }
// }

export const companyContactSlice = createSlice({
    name: 'contactList',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getCompanyContactByPage.pending, (state, action) => ({
                ...state,
                loading: LoadingType.pending,
                error: null,
            }))
            .addCase(thunk.getCompanyContactByPage.rejected, (state, action) => ({
                ...state,
                loading: LoadingType.failed,
                error: action.payload,
            }))
            .addCase(thunk.getCompanyContactByPage.fulfilled, (state, action) => ({
                ...state,
                loading: LoadingType.succeeded,
                data: action.payload,
            }))
            // .addCase(
            //     thunk.getCompanyContacts.pending,
            //     (state, action) => ({
            //         ...state,
            //         [action.meta.arg.department_id ?? action.meta.arg.company_id]: {
            //             ...getLoadingState(state[action.meta.arg.department_id ?? action.meta.arg.company_id])
            //         }
            //     })
            // )
            // .addCase(
            //     thunk.getCompanyContacts.rejected,
            //     (state, action) => ({
            //         ...state,
            //         [action.meta.arg.department_id ?? action.meta.arg.company_id]: {
            //             ...getErrorState(state[action.meta.arg.department_id ?? action.meta.arg.company_id], action.payload),
            //         }
            //     })
            // )
            // .addCase(
            //     thunk.getCompanyContacts.fulfilled,
            //     (state, action) => ({
            //         ...state,
            //         [action.meta.arg.department_id ?? action.meta.arg.company_id]: {
            //             ...getSuccessState(state[action.meta.arg.department_id ?? action.meta.arg.company_id], action.payload),
            //         }
            //     })
            // )

            // .addCase(thunk.getCompanyContactByPage.pending, (state, action) => ({ ...state, companyContactsByPage: { ...state.companyContactsByPage, loading: LoadingType.pending, error: null } }))
            // .addCase(thunk.getCompanyContactByPage.rejected, (state, action) => ({ ...state, companyContactsByPage: { ...state.companyContactsByPage, loading: LoadingType.failed, error: action.payload } }))
            // .addCase(thunk.getCompanyContactByPage.fulfilled, (state, action) => ({ ...state, companyContactsByPage: { ...state.companyContactsByPage, loading: LoadingType.succeeded, response: action.payload } }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const companyContactSliceActions = companyContactSlice.actions;
export const selectState = (state: CompanyContactsState) => state;
export const selectAll = (state: CompanyContactsState) => state.data;

