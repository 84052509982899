import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError } from "../../../interfaces";
import { ICompany, IDepartment } from "../../../interfaces";
import { getMyCallDeckList, getMyCallDeckListPage } from "./my_call_deck_thunk";
import { IIMyCallDeckByPage } from "../../../interfaces/my_call_deck";


export interface MyCallDeckListState extends EntityState<ICompany | IDepartment> {
    error?: AppError | null,
    loading: LoadingType,
    myCallDeckByPage: {
        error?: AppError | null;
        loading: LoadingType | null;
        response: IIMyCallDeckByPage | null;
    }
}

const myCallDeckListAdapter: EntityAdapter<ICompany | IDepartment> = createEntityAdapter<ICompany | IDepartment>({
    selectId: (doc) => doc.id,
})

const initialState: MyCallDeckListState = myCallDeckListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    myCallDeckByPage: {
        error: null,
        loading: LoadingType.idle,
        response: null
    }
})


export const myCallDeckListSlice = createSlice({
    name: 'myCallDeckList',
    initialState: initialState,
    reducers: {
        clearMyCallDeckListError(state){
            return {...state, error: null};
        },
        clearMyCallDeckListState(state){
            return myCallDeckListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMyCallDeckList.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(getMyCallDeckList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(getMyCallDeckList.fulfilled, (state, action) => myCallDeckListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addCase(getMyCallDeckListPage.pending, (state, action) => ({...state, myCallDeckByPage: {...state.myCallDeckByPage, loading: LoadingType.pending, error: null}}))
            .addCase(getMyCallDeckListPage.rejected, (state, action) => ({...state, myCallDeckByPage: {...state.myCallDeckByPage, loading: LoadingType.failed, error: action.payload} }))
            .addCase(getMyCallDeckListPage.fulfilled, (state, action) => ({...state, myCallDeckByPage: {...state.myCallDeckByPage, loading: LoadingType.succeeded, response: action.payload}}))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearMyCallDeckListError, clearMyCallDeckListState } = myCallDeckListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = myCallDeckListAdapter.getSelectors();
export const selectState = (state: MyCallDeckListState) => state;
export const selectLoading = (state: MyCallDeckListState) => state.loading;
export const selectError = (state: MyCallDeckListState) => state.error;


