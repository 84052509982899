import { createSlice } from '@reduxjs/toolkit';
import { IJobOrderContactsByPage } from '../../../../../entities/ContactInfo';
import { LoadingType } from '../../../../../enums';
import { AppError } from '../../../../../interfaces';
import * as thunk from './job_order_contact_list_thunk';

export interface SingleJobOrderContactState {
    error?: AppError | null;
    loading: LoadingType | null;
    response: IJobOrderContactsByPage | null;
}

const initialState: SingleJobOrderContactState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}


export const jobOrderContactSlice = createSlice({
    name: 'contactList',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getContactsByJobOrderByPage.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getContactsByJobOrderByPage.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, response: null, }))
            .addCase(thunk.getContactsByJobOrderByPage.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const {} = jobOrderContactSlice.actions;
export const selectState = (state: SingleJobOrderContactState) => state;

