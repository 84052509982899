import {createAsyncThunk} from "@reduxjs/toolkit";
import {IJobOrderContact, IJobOrderContactsByPage, IJobOrderContactsByPagePayload} from "../../../../../entities/ContactInfo";
import {AppError} from "../../../../../interfaces";
import {catchRequestError} from "../../../../../utils";
import {getJobOrderContacts, getJobOrderContactsByPage} from "../../../../../apis/contacts_api";

export const getContactsByJobOrderByPage = createAsyncThunk<
    IJobOrderContactsByPage,
    IJobOrderContactsByPagePayload,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/contacts/contact-list-by-page/post',
    async (payload, thunkAPI) => {
        try {
            const res = await getJobOrderContactsByPage(payload);
            return res.data as IJobOrderContactsByPage;
        } catch (error) {
            return catchRequestError(error, thunkAPI)
        }
    }
)
