import { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import CustomFormSelect from "../../../../../../components/form_selector/form_select";
import { UpdateAssignment, WorkCompCode } from "../../../../../../interfaces";
import { FormInput } from "../../../../../../utils";
import { convertNumberToMoney, enforceUSFormat, formatToMoney } from "../../../../../../utils/form_validations";
import { mapOptions } from "../../../../../../utils/helpers";
import "../summary/assignment_summary.scss";
import "./payroll_billing.scss";
import { WorkCompConfiguration } from "../../../../../../interfaces/work_comp_configuration";

export interface PayrollBillingProps {
  assignmentInfo: UpdateAssignment;
  setAssignment: Dispatch<SetStateAction<UpdateAssignment>>;
  updateProp: Function;
  wcCodes: { id: string, work_comp_code: string }[];
  disabled: boolean;
}

export const PayrollBilling: FC<PayrollBillingProps> = ({
  assignmentInfo,
  updateProp,
  wcCodes,
  disabled,
  setAssignment
}) => {
  const { t } = useTranslation();

  const handlePayRateChange = (id, value) => {
    const payRate = value.replace(/,/g, ''); // Remove commas from input value
    if (payRate.trim() === '') {
      setAssignment({
        ...assignmentInfo,
        regular_pay_rate: '',
        double_time_pay_rate: '0.00', // Set bill rate to '0.00' if pay rate is empty
        overtime_pay_rate: '0.00' // Set bill rate to '0.00' if pay rate is empty
      });
    } else if (!isNaN(payRate)) {
      const billRate = parseFloat(payRate) * 2;
      const overTimeBillRate = parseFloat(payRate) * 1.5;
      setAssignment({
        ...assignmentInfo,
        regular_pay_rate: value,
        double_time_pay_rate: isNaN(billRate) ? '' : convertNumberToMoney(billRate, "string") as string,
        overtime_pay_rate: isNaN(overTimeBillRate) ? '' : convertNumberToMoney(overTimeBillRate, "string") as string
      });
    }
  };

  const handleBillRateChange = (id, value) => {
    const regularbillRate = value.replace(/,/g, ''); // Remove commas from input value
    if (regularbillRate.trim() === '') {
      setAssignment({
        ...assignmentInfo,
        regular_bill_rate: '',
        double_time_bill_rate: '0.00' // Set bill rate to '0.00' if pay rate is empty
      });
    } else if (!isNaN(regularbillRate)) {
      const doubleTimeBillRate = parseFloat(regularbillRate) * 2;
      const overTimeTimeBillRate = parseFloat(regularbillRate) * 1.5;
      setAssignment({
        ...assignmentInfo,
        regular_bill_rate: value,
        double_time_bill_rate: isNaN(doubleTimeBillRate) ? '' : convertNumberToMoney(doubleTimeBillRate, "string") as string,
        overtime_bill_rate: isNaN(overTimeTimeBillRate) ? '' : convertNumberToMoney(overTimeTimeBillRate, "string") as string
      });
    }
  };


  return (
    <div className={`pb-container ${disabled ? "disabled" : ""}`}>
      <div className="pb-header">{`PAYROLL & BILLING`}</div>
      <div className="pb-content">
        <div className="pb-item-container">
          <div className="pb-item-header">Regular</div>
          <div className="pb-item">
            <FormInput
              id={"regular_pay_rate"}
              // onChange={(id, val) => updateProp(id, enforceUSFormat(val))}
              onChange={(id, value) => handlePayRateChange(id, value)}
              type={"text"}
              value={convertNumberToMoney(assignmentInfo.regular_pay_rate, "string") as string}
              label={t("pay_rate")}
              placeholder=""
              prefix={<span>$</span>}
              step="0.01"
              customStyle={{ marginRight: "1em" }}
              min={"0.00"}
              onBlur={formatToMoney}
              onKeyDown={formatToMoney}
              onKeyUp={formatToMoney}
            />
            <FormInput
              id={"regular_bill_rate"}
              // onChange={(id, val) => updateProp(id, enforceUSFormat(val))}
              onChange={(id, value) => handleBillRateChange(id, value)}
              type={"text"}
              value={convertNumberToMoney(assignmentInfo.regular_bill_rate, "string") as string}
              label={t("bill_rate")}
              placeholder=""
              prefix={<span>$</span>}
              step="0.01"
              customStyle={{ marginRight: "3rem" }}
              min={"0.00"}
              onBlur={formatToMoney}
              onKeyDown={formatToMoney}
              onKeyUp={formatToMoney}
            />
          </div>
        </div>
        <div className="pb-item-container">
          <div className="pb-item-header">Overtime</div>
          <div className="pb-item">
            <FormInput
              id={"overtime_pay_rate"}
              onChange={(id, val) => updateProp(id, enforceUSFormat(val))}
              // onChange={(id, value)=>handleOverTimePayRateChange(id, value)}
              type={"text"}
              value={convertNumberToMoney(assignmentInfo.overtime_pay_rate, "string") as string}
              label={t("pay_rate")}
              placeholder=""
              prefix={<span>$</span>}
              step="0.01"
              customStyle={{ marginRight: "1em" }}
              min={"0.00"}
              onBlur={formatToMoney}
              onKeyDown={formatToMoney}
              onKeyUp={formatToMoney}
              disabled
            />
            <FormInput
              id={"overtime_bill_rate"}
              onChange={(id, val) => updateProp(id, enforceUSFormat(val))}
              // onChange={(id, value)=>handleOverTimeBillRateChange(id, value)}
              type={"text"}
              value={convertNumberToMoney(assignmentInfo.overtime_bill_rate, "string") as string}
              label={t("bill_rate")}
              placeholder=""
              prefix={<span>$</span>}
              step="0.01"
              min={"0.00"}
              customStyle={{ marginRight: "3rem" }}
              onBlur={formatToMoney}
              onKeyDown={formatToMoney}
              onKeyUp={formatToMoney}
            />
          </div>
        </div>
        <div className="pb-item-container">
          <div className="pb-item-header">Double Time</div>
          <div className="pb-item">
            <FormInput
              id={"double_time_pay_rate"}
              onChange={(id, val) => updateProp(id, enforceUSFormat(val))}
              type={"text"}
              value={convertNumberToMoney(assignmentInfo.double_time_pay_rate, "string") as string}
              label={t("pay_rate")}
              placeholder=""
              prefix={<span>$</span>}
              step="0.01"
              customStyle={{ marginRight: "1em" }}
              min={"0.00"}
              onBlur={formatToMoney}
              onKeyDown={formatToMoney}
              onKeyUp={formatToMoney}
              disabled
            />
            <FormInput
              id={"double_time_bill_rate"}
              onChange={(id, val) => updateProp(id, enforceUSFormat(val))}
              type={"text"}
              value={convertNumberToMoney(assignmentInfo.double_time_bill_rate, "string") as string}
              label={t("bill_rate")}
              placeholder=""
              prefix={<span>$</span>}
              step="0.01"
              min={"0.00"}
              onBlur={formatToMoney}
              onKeyDown={formatToMoney}
              onKeyUp={formatToMoney}
            />
          </div>
        </div>
        {/* <div className="pb-item-container">
          <div className="pb-item-header">Triple Time</div>
          <div className="pb-item">
            <FormInput
              id={"triple_time_pay_rate"}
              onChange={(id, val) => updateProp(id, enforceUSFormat(val))}
              type={"number"}
              value={assignmentInfo?.triple_time_pay_rate}
              label={t("pay_rate")}
              placeholder=""
              prefix={<span>$</span>}
              step="0.01"
              customStyle={{ marginRight: "0.3vw" }}
              min={"0.00"}
            />
            <FormInput
              id={"triple_time_bill_rate"}
              onChange={(id, val) => updateProp(id, enforceUSFormat(val))}
              type={"number"}
              value={assignmentInfo?.triple_time_bill_rate}
              label={t("bill_rate")}
              placeholder=""
              prefix={<span>$</span>}
              step="0.01"
              min={"0.00"}
            />
          </div>
        </div> */}
      </div>
      {/* <div className="pb-item-container"> */}
      <div className="pb-item-header">&nbsp;</div>
      <div className="row2">
        <FormInput
          id={"mark_up_factor"}
          onChange={(_field: any, value: any) => {
            if (value !== null) {
              updateProp("mark_up_factor", Number(value));
            }
          }}
          required={false}
          placeholder={""}
          type={"number"}
          value={assignmentInfo.mark_up_factor}
          label={"Mark Up Factor"}
          customStyle={{ width: "11.500vw", }}
        />
        {/* <CustomFormSelect
          name="wc_code_id"
          value={assignmentInfo?.wc_code_id}
          label={"wc_code"}
          placeholder={t("select_placeholder")}
          list={mapOptions(wcCodes ?? [], "work_comp_code", "id")}
          onChange={(value) => updateProp("wc_code_id", value)}
          customStyle={{ width: "11.500vw" }}
          menuPlacement={"top"}
        /> */}
      </div>
      {/* </div> */}
    </div>
  );
};
