import { useTranslation } from "react-i18next";
import "./form_select.scss";
import React, { Component, useEffect, useState } from "react";
import Select, {
  ClearIndicatorProps,
  components,
  CSSObjectWithLabel,
  MenuPlacement,
  Options,
  StylesConfig,
} from "react-select";
import { FONT, THEME } from "../../enums";
import { ArrowDownIcon, CloseNormalIcon, ExpandMoreIcon } from "../../icons";
import { ISelectorOption } from "../../interfaces";
import { getLabelText } from "../../utils";
import CustomText from "../../utils/custom_text";
import { AppRoutes } from "../../routes";
import { useHistory } from "react-router-dom";

const customStyles: (error: boolean) => StylesConfig<ISelectorOption> = (hasError) => ({
  menuPortal: (styles, _): CSSObjectWithLabel => {
    return {
      ...styles,
      color: THEME.defaultHighLightColor,
      zIndex: 9999,
    };
  },
  control: (styles, { isDisabled }): CSSObjectWithLabel => {
    const borderColor = hasError ? "red" : isDisabled ? THEME.optionDisableColor : THEME.inputoutlineColor;
    return {
      ...styles,
      backgroundColor: "white",
      border: `0.3px solid ${borderColor}`,
      minHeight: "1.9692307692307693vw",
      maxHeight: "1.9692307692307693vw",
      padding: "0 0.18572916666666667vw",
      ":focus-visible": {
        ...styles[":focus-visible"],
        border: `0.3px solid ${isDisabled ? THEME.optionDisableColor : THEME.inputoutlineColor
          }`,
      },
    };
  },
  valueContainer: (styles, _): CSSObjectWithLabel => {
    return {
      ...styles,
      padding: "0px",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "row",
      overflowY: "hidden",
      overflowX: "auto",
      flexWrap: "nowrap",
      // margin: 0,
    };
  },
  option: (
    styles,
    { data, isDisabled, isFocused, isSelected }
  ): CSSObjectWithLabel => {
    function getBackgroundColor() {
      if (isDisabled) return "#91909033";
      if (isSelected) return "#0b3e7910";
      if (isFocused) return "#0b3e7920";
      return undefined;
    }

    function getColor() {
      if (isDisabled) return "#ccc";
      if (isSelected) return "#0b3e7920";
      if (isFocused) return "#0b3e7920";
      return "#0b3e7920";
    }

    function getActiveBackgroundColor() {
      if (isDisabled) return undefined;
      if (isSelected) return "#0b3e7920";
      return "#0b3e7920";
    }
    return {
      ...styles,
      backgroundColor: getBackgroundColor(),
      color: getColor(),
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: getActiveBackgroundColor(),
      },
    };
  },
  input: (styles, _): CSSObjectWithLabel => {
    return {
      ...styles,
      color: THEME.defaultHighLightColor,
      padding: 0,
      margin: 0,
    };
  },
  placeholder: (styles, _): CSSObjectWithLabel => ({
    ...styles,
    color: THEME.defaultHighLightColor,
  }),
  singleValue: (styles, { data, isDisabled }): CSSObjectWithLabel => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? THEME.transparent
        : data.backgroundColor ?? "#eff2f7",
      borderRadius: "0.3076923076923077vw",
      padding: "0.0261538461538461vw 0.4261538461538461vw",
      margin: "0px 2px",
      color: data.color ?? THEME.defaultHighLightColor,
      height: "100%",
      fontWeight: "800",
    };
  },

  multiValue: (styles, { data, isDisabled }): CSSObjectWithLabel => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? THEME.transparent
        : data.backgroundColor ?? "#eff2f7",
      borderRadius: "0.3076923076923077vw",
      padding: "0.0261538461538461vw 0.2261538461538461vw",
      margin: "0px 2px",
      color: isDisabled
        ? THEME.optionDisableColor
        : data.color ?? THEME.defaultHighLightColor,
      fontWeight: "800",
      // paddingLeft: '0.20833333333333334vw',
      // paddingRight: '0.20833333333333334vw',
    };
  },
  multiValueLabel: (styles, { data }): CSSObjectWithLabel => {
    return { ...styles, color: data.color ?? THEME.defaultHighLightColor };
  },
  multiValueRemove: (styles, { data }): CSSObjectWithLabel => {
    return {
      ...styles,
      display: "none",
      borderRadius: "100%",
      color: data.color ?? THEME.defaultHighLightColor,
      padding: "0px",
      // width: '0.29230769230769234vw',
      height: "0.39230769230769234vw",
      margin: "0px 0.36923076923076925vw",
      ":hover": {
        ...styles[":hover"],
        backgroundColor: THEME.transparent,
      },
    };
  },

  indicatorsContainer: (styles, _): CSSObjectWithLabel => {
    return {
      ...styles,
      padding: "0px 0.41666vw",
    };
  },
  dropdownIndicator: (styles, _): CSSObjectWithLabel => {
    return {
      ...styles,
      padding: "0px",
      color: THEME.defaultHighLightColor,
      fontWeight: "normal",
      width: "0.69230769230769234vw",
      height: "0.79230769230769234vw",
    };
  },
  clearIndicator: (styles, _): CSSObjectWithLabel => {
    return {
      ...styles,
      // display: 'none',
      color: THEME.defaultHighLightColor,
      fontWeight: "normal",
      padding: "0px",
      width: "0.49230769230769234vw",
      height: "0.49230769230769234vw",
      cursor: "pointer",
      marginRight: "0.6153846153846154vw",
      ":hover": {
        ...styles[":hover"],
        color: "red",
      },
    };
  },
  indicatorSeparator: (styles, _): CSSObjectWithLabel => {
    return {
      ...styles,
      display: "none",
    };
  },
});

interface CustomMultiFormSelectProps {
  name: string;
  isMulti?: boolean;
  placeholder?: string;
  required?: boolean;
  label?: string;
  labelPosition?: "top" | "left" | "right";
  value: string[];
  list: Array<ISelectorOption>;
  onChange: (value: string[]) => void;
  customStyle?: React.CSSProperties;
  disabled?: boolean;
  customClassName?: string;
  customClassPrefixName?: string;
  menuPlacement?: MenuPlacement | undefined;
  isClearable?: boolean;
  validations?: (value: { text: string, field: string }) => string | null,
  checkValidationOnSubmit?: boolean,

  isOptionSelected?:
  | ((
    option: ISelectorOption,
    selectValue: Options<ISelectorOption>
  ) => boolean)
  | undefined;
}

const DownChevron = (props) => {
  return (
    <components.DownChevron {...props}>
      <div className="mult-down-chevron">
        <ExpandMoreIcon
          width={"100%"}
          height={"100%"}
          style={{ color: THEME.defaultHighLightColor }}
        />
      </div>
    </components.DownChevron>
  );
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className="mult-option-holder">
        <input
          className="multi-select-input-check"
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        <div className="label-txt">
          <span style={{ color: props.data.color ?? undefined }}>
            {props.label}
          </span>
        </div>
      </div>
    </components.Option>
  );
};

const SingleOption = (props) => {
  return (
    <components.Option {...props}>
      <div className="mult-option-holder">
        {/* <input
                    className='multi-select-input-check'
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                /> */}
        <div className="label-txt">

          {props.data.icon && <div className="option-icon"><props.data.icon width={"100%"} height={"100%"} /></div>}
          <span style={{ color: props.data.color ?? undefined }}>
            {props.label}
          </span>
        </div>
      </div>
    </components.Option>
  );
};
const MoreSelectedBadge = ({ items }) => {
  const style: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#eff2f7",
    borderRadius: "5px",
    padding: "0.1261538461538461vw 0.4261538461538461vw",
    marginLeft: "2px",
    height: "100%",
    fontWeight: 800,
    // order: 0
  };

  const title = items.join(", ");
  const length = items.length;
  const label = `+ ${length}`; //`${length} Selected`

  return (
    <div style={style}>
      <span className={"ellipse-label"} title={title}>
        {label}
      </span>
    </div>
  );
};

const SingleValue = (props: any) => {
  const history = useHistory();

  const handleRedirect = () => {
    const selectedVal = props.data; // Access the selected option data

    if (selectedVal.clickableValue) {
      const url = selectedVal.clickActionType === "company"
      ? `${AppRoutes.companiesDetailsPage}/${selectedVal.value}`
      : `${AppRoutes.contactsDetailsPage}/${selectedVal.value}`;

    // Open the URL in a new tab
    window.open(url, "_blank");
      // history.push({
      //   pathname: selectedVal.clickActionType === "company" ?
      //     `${AppRoutes.companiesDetailsPage}/${selectedVal.value}` :
      //     `${AppRoutes.contactsDetailsPage}/${selectedVal.value}`,
      //   state: {
      //     id: selectedVal.value,
      //     name: selectedVal.label,
      //   },
      // });
    }
  };

  return (
    <div onClick={handleRedirect}>
      <components.SingleValue {...props} />
    </div>
  );
};


const MultiValue = (props) => {
  const maxToShow = 2;
  const overflow = props
    .getValue()
    .slice(maxToShow)
    .map((x) => x.label);

  return props.index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : props.index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};

const CrossIcon = (props) => (
  <components.CrossIcon {...props}>
    <div className="cross-icon">
      <CloseNormalIcon
        width={"100%"}
        height={"100%"}
        style={{ color: "#bdc2cc" }}
      />
    </div>
  </components.CrossIcon>
);

const ClearIndicator = (props) => {
  const {
    children = (
      <CloseNormalIcon
        width={"100%"}
        height={"100%"}
        style={{ color: "#bdc2cc" }}
      />
    ),
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props) as React.CSSProperties}
    >
      {children}
    </div>
  );
};

const Control = (props) => (
  <components.Control {...props}>{props.children}</components.Control>
);

const DropdownIndicator = (props) => {
  const {
    children = (
      <ArrowDownIcon
        width={"100%"}
        height={"100%"}
        style={{ color: THEME.defaultHighLightColor }}
      />
    ),
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("dropdownIndicator", props) as React.CSSProperties}
    >
      {children}
    </div>
  );
};

const MultiValueRemove = (props) => {
  // const {
  //     children = <CloseNormalIcon width={"100%"} height={"100%"} style={{ color: THEME.defaultHighLightColor }} />
  //     getStyles,
  //     innerProps: { ref, ...restInnerProps },
  // } = props;
  return (
    <components.MultiValueRemove {...props}>
      <CloseNormalIcon
        width={"100%"}
        height={"100%"}
        style={{ color: THEME.defaultHighLightColor }}
      />
    </components.MultiValueRemove>
    // <div
    //     {...restInnerProps}
    //     ref={ref}
    //     style={getStyles('multiValueRemove', props) as React.CSSProperties}
    // >
    //     {children}
    // </div>
  );
};

export const CustomMultiFormSelect: React.FunctionComponent<
  CustomMultiFormSelectProps
> = (props) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>('');
  const handleValidation = (text: any) => {
    setError(props.validations ? props.validations({ text, field: props.name }) : null);
  }

  useEffect(() => {
    if (props.checkValidationOnSubmit !== undefined && props.checkValidationOnSubmit) {
      handleValidation(props.value)
    }
  }, [props.checkValidationOnSubmit, props.validations])
  const values =
    props.list.length !== 0 && props.value != null
      ? props.list.filter((doc) => props.value.includes(doc.value))
      : [];

  return (
    <div
      className="cmfs-container cmfs-margin-top"
      style={{
        ...(props.labelPosition === "left" && {
          flexDirection: "row",
          alignItems: "center",
          marginTop: 0,
        }),
        ...(props.labelPosition === "right" && {
          flexDirection: "row-reverse",
          alignItems: "center",
          marginTop: 0,
        }),
      }}
    >
      {props.label && (
        <div
          className="cmfs-label"
          style={{
            ...(props.labelPosition === "left" && {
              marginRight: "1em",
              width: "-webkit-fill-available",
            }),
            ...(props.labelPosition === "right" && {
              marginLeft: "1em",
              width: "-webkit-fill-available",
            }),
          }}
        >
          <span>
            {getLabelText(t(props.label), {
              position: props.labelPosition,
              required: props.required,
            })}
          </span>
        </div>
      )}
      <span
        className="d-inline-block"
        data-toggle="popover"
        data-trigger="focus"
        data-content="Please selecet user role"
        style={props.customStyle}
      >
        <Select
          // styles={customStyles(false)}
          styles={customStyles(error !== null && error !== '' ? true : false)}
          placeholder={props.placeholder}
          isMulti
          isDisabled={props.disabled}
          // isMulti={props.isMulti ?? true}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          menuPortalTarget={document.body}
          isClearable={props.isClearable ?? true}
          options={props.list.map(
            (doc) => ({ ...doc, label: t(doc.label), icon: doc.icon } as ISelectorOption)
          )}
          components={{
            Control,
            DropdownIndicator,
            MultiValue,
            Option,
            DownChevron,
            ClearIndicator,
            MultiValueRemove,
            CrossIcon,
          }}
          onChange={(value) =>
            value
              ? props.onChange(value.map((doc) => doc?.value) as string[])
              : {}
          }
          value={values}
          // menuIsOpen={true}
          className={props.customClassName}
          classNamePrefix={props.customClassPrefixName}
          maxMenuHeight={180}
          isOptionSelected={props.isOptionSelected}
          menuPlacement={props.menuPlacement}
        />
        {error ? <CustomText customStyle={styles.validatorError}>{error}</CustomText> : null}
      </span>
    </div>
  );
};

interface Props {
  name: string;
  value: any;
  placeholder?: string;
  required?: boolean;
  label?: string;
  labelPosition?: "top" | "left" | "right";
  list: Array<ISelectorOption>;
  onChange: (value: any) => void;
  customStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  defaultOptionName?: string;
  doNotTranslate?: boolean;
  disabled?: boolean;
  isClearable?: boolean;
  menuPlacement?: MenuPlacement | undefined;
  validations?: (value: { text: string, field: string }) => string | null,
  checkValidationOnSubmit?: boolean,
  customError?: boolean;
  clickableValue?: boolean;
  clickActionType?: string;
}

const CustomFormSelect: React.FunctionComponent<Props> = (props) => {

  const { t } = useTranslation();
  const [error, setError] = useState<string | null>('');

  const handleValidation = (text: any) => {
    setError(props.validations ? props.validations({ text, field: props.name }) : null);
  }


  useEffect(() => {
    if (props.checkValidationOnSubmit !== undefined && props.checkValidationOnSubmit) {
      handleValidation(props.value)
    }
  }, [props.checkValidationOnSubmit, props.validations]);

  // const values =
  //   props.list.length !== 0 && props.value != null
  //     ? props.list
  //       .map((doc) => ({ ...doc, label: t(doc.label) } as ISelectorOption))
  //       .filter((doc) => props.value === doc.value)
  //     : [];
  const values =
    props.list.length !== 0 && props.value != null
      ? props.list
        .map((doc) => {
          const transformedDoc = {
            ...doc,
            label: t(doc.label),
            value: doc.value,
          };

          // Only add clickableValue and clickActionType if clickableValue is true
          if (props.clickableValue) {
            transformedDoc.clickableValue = props.clickableValue;  // Add clickableValue
            transformedDoc.clickActionType = props.clickActionType; // Add clickActionType
          }

          return transformedDoc;
        })
        .filter((doc) => props.value === doc.value)
      : [];

  return (
    <div
      className="cmfs-container cmfs-margin-top"
      style={{
        ...(props.labelPosition === "left" && {
          flexDirection: "row",
          alignItems: "center",
          marginTop: 0,
        }),
        ...(props.labelPosition === "right" && {
          flexDirection: "row-reverse",
          alignItems: "center",
          marginTop: 0,
        }),
      }}
    >
      {props.label && (
        <div
          className="cmfs-label"
          style={{
            ...(props.labelPosition === "left" && {
              marginRight: "1em",
              width: "-webkit-fill-available",
            }),
            ...(props.labelPosition === "right" && {
              marginLeft: "1em",
              width: "-webkit-fill-available",
            }),
          }}
        >

          <span>
            {getLabelText(t(props.label), {
              position: props.labelPosition,
              required: props.required,
            })}
          </span>
        </div>
      )}
      <span
        className="d-inline-block"
        data-toggle="popover"
        data-trigger="focus"
        data-content="Please selecet user role"
        style={props.customStyle}
      >
        <div style={{
          ...(props.disabled ? { opacity: 0.6, } : null),
        }}>
          <Select
            placeholder={props.placeholder}
            styles={customStyles((error !== null && error !== '') || props.customError ? true : false)}
            isMulti={false}
            isDisabled={props.disabled}
            isClearable={props.isClearable ?? true}
            // isMulti={props.isMulti ?? true}
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            menuPortalTarget={document.body}
            options={props.list.map(
              (doc) => ({ ...doc, label: t(doc.label) } as ISelectorOption)
            )}
            components={{
              Control,
              DropdownIndicator,
              SingleValue,
              Option: SingleOption,
              DownChevron,
              ClearIndicator,
              MultiValueRemove,
            }}
            onChange={(item) => props.onChange(item ? item.value : "")}
            value={(props.value !== undefined && props.value !== null && props.value !== "") ? values[0] : null}
            menuPlacement={props.menuPlacement}
          />
        </div>
        {error ? <CustomText customStyle={styles.validatorError}>{error}</CustomText> : null}

      </span>
    </div >
  );

};
const styles: { [key: string]: React.CSSProperties } = ({
  validatorError: {
    color: 'red',
    padding: 5,
    fontSize: 10,
    fontFamily: FONT.lato
  },

})
export default CustomFormSelect;
