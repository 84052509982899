export class SalesCall {
  accomplishment: string;
  agency_id: string;
  branch_id: string;
  call_date_time: number;
  call_goal: string;
  call_made_to: string;
  call_result: string;
  communicated_with_dm: boolean;
  communicated_with_someone: boolean;
  company_id: string;
  company_name: string;
  company_status: string;
  contact_first_name: string;
  contact_id: string;
  contact_last_name: string;
  contact_method: string;
  department_id: string;
  date_of_completed: number;
  department_name: string;
  dm_reached: boolean;
  gross_profit_potential: string;
  gross_revenue_potential: string;
  id: string;
  is_completed: boolean;
  industry_sector_id: string;
  industry_sector_name: string;
  schedule_date_time: number;
  schedule_date: number | null;
  schedule_time: string | null| undefined;
  sequence_number: number;
  status: string;
  user_id: string;
  website_url: string;
  account_managers:Array<string>;
  open_orders:number;
  order_status:string;
  modified_by:string;
  created_by:string;
  planned_by_id: string;
  planned_by_name: string;
  assigned_to_id: string;
  assigned_to_name: string;
}

export class SalesCallsDataParam {
  my_upcoming_calls: boolean;
  start_date: number;
  end_date: number;
}

export interface ISalesCallsByPage {
  items: SalesCall[];
  total: number,
  page: number,
  size: number,
  pages: number
}
export interface ISalesCallsByPagePayload {
  page: number,
  size: number,
  my_upcoming_calls: boolean;
  start_date: number;
  end_date: number;
  users?: Array<string>
  search_str?: string,

}
