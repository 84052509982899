import { IRootState } from "../../../../store";
import { createSelector } from "@reduxjs/toolkit";
import { selectState, selectAll } from './company_contact_reducer';

const select = (state: IRootState) => state.companies.details.contacts.contactList;
export const selectCompanyContactsState = createSelector(select, selectState);
export const selectCompanyContactsListData = createSelector(select, selectAll)

// export const selectSingleCompanyContactsStateById = (id: string | undefined | null) => createSelector(
//     selectCompanyContactsState,
//     (state) => {
//         if (id && state[id]) return reducer.selectSingleCompanyContactssState(state, id);
//         return reducer.selectInitialSingleCompanyContactsState();
//     }
// );