import React from 'react';
import { useTranslation } from 'react-i18next';
import { SkillSetsIcon, SystemWideIcon,
DashboardIcon, ContactsIcon, Jobsicon, TalentIcon, AssignmentIcon, OnBoardingIcon, TextEmAllIcon,
RiskmanagIcon,
EmailIcon} from '../../../icons';
import { AppRoutes } from '../../../routes';
import MenuOption from '../../../components/menu_option/menu_option';
import { RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps<any> { }

const options = [
    {
        title: 'skill_sets',
        icon: SkillSetsIcon,
        route: AppRoutes.systemConfigurationSkillSets,
    },
    {
        title: 'system_wide',
        icon: SystemWideIcon,
        route: AppRoutes.systemConfigurationSystemWide,
    },
    // {
    //     title: 'sales_and_marketing',
    //     icon: SalesmarketIcon,
    //     route: AppRoutes.systemConfigurationSalesAndMarketing,
    // },
    {
        title: 'contacts',
        icon: ContactsIcon,
        route: AppRoutes.systemConfigurationContacts,
    },
    {
        title: 'dashboard',
        icon: DashboardIcon,
        route: AppRoutes.systemConfigurationTrafficLightEngagementLight,
    },
    {
        title: 'jobs',
        icon: Jobsicon,
        route: AppRoutes.systemConfigurationJobs,
    },
    {
        title: 'assignments',
        icon: AssignmentIcon,
        route: AppRoutes.systemConfigurationAssignments,
    },
    {
        title: 'talent',
        icon: TalentIcon,
        route: AppRoutes.systemConfigurationTalent,
    },
    {
        title: 'onboarding',
        icon: OnBoardingIcon,
        route: AppRoutes.systemConfigurationOnboarding,
    },
    {
        title: 'risk_management',
        icon: RiskmanagIcon,
        route: AppRoutes.systemConfigurationRiskManagement,
    },
    {
        title: 'email_configuration',
        icon: EmailIcon,
        route: AppRoutes.systemConfigurationEmailConfiguration,
    }

]

const AdminCenterSystemConfigurationPage: React.FunctionComponent<Props> = (props) => {
    const { history } = props;
    const { t } = useTranslation();
    return (
        <div className="menu-option-container">
            {options.map((doc, index) => {
                return (
                    <MenuOption key={index} doc={doc} t={t} onClick={() => history.push(doc.route)} />
                )
            })}

        </div>

    )
}

export default AdminCenterSystemConfigurationPage;
