import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { getAllKinisoJobOrdersThunk, selectKinisoJobsList, selectKinisoJobsListState } from "../../../redux/kiniso_job_orders";
import { useEffect, useState } from "react";
import { LoadingType } from "../../../enums";
import { IKinisoJobOrderResponse } from "../../../interfaces/kiniso_job_orders";
import './job_orders_list.scss';
import { getDateString } from "../../../variables";
import { CompaniesIcon, LocationIcon } from "../../../icons";
import { GlobalRoutes } from "../../../routes";
import SearchBar from "../../../components/search_bar";
import SortableTable, { TableData } from "../../../components/sortable_table/sortable_table";
import { getTableAccessIcon } from "../../../utils";

const headers = [
        { title: 'access_job', code: 'access_user' },
        { title: 'company', code: 'hiring_organization' },
        { title: 'job_title', code: 'job_title' },
        { title: 'city', code: 'job_location' },
        { title: 'state', code: 'job_location' },
        { title: 'zip_code', code: 'job_location' },
        { title: 'date_posted', code: 'date_posted' },

]

interface Props extends RouteComponentProps<any> { }
const KinisoJobOrders: React.FunctionComponent<Props> = (props) => {
        const { history, location, match } = props;
        useEffect(() => {
                // Find the script element by its widget_id attribute
                const scriptElement = document.querySelector(
                        '#freshworks-container'
                );
                // Remove the script element if found
                if (scriptElement) {
                        scriptElement.remove();
                }
        }, []);
        const { params } = match;
        const { t } = useTranslation();
        const dispatch = useAppDispatch();
        const [search, setSearch] = useState('');
        const [sortedField, setSortedField] = useState<string | null>(null);
        const [sortDirection, setSortDirection] = useState('asc');
        const jobsListState = useAppSelector((state) =>
                selectKinisoJobsListState(state)
        );
        const kinisoJobsList = useAppSelector((state) => selectKinisoJobsList(state));
        useEffect(() => {
                dispatch(getAllKinisoJobOrdersThunk({
                        token: params.key,
                        branch_id: params.branch_id,
                        agency_id: params.agency_id
                }));
                return () => { };
        }, []);
        const [jobOrderListData, setJobOrderListData] = useState<IKinisoJobOrderResponse[]>()
        useEffect(() => {
                if (jobsListState && jobsListState.loading === LoadingType.succeeded && kinisoJobsList.length !== 0) {
                        setJobOrderListData(kinisoJobsList)
                }
        }, [jobsListState, jobsListState.loading]);

        function handleJobSelect(value: IKinisoJobOrderResponse) {
                props.history.push({
                        pathname: `${GlobalRoutes.kinisoJobOrders}/${params.key}/${params.branch_id}/${params.agency_id}/${value.job_id}`,
                        state: {
                                id: value.job_id,
                                name: value.job_title ?? "",
                        },
                });
                return;
        }

        const getFilteredList = () => {
                let list = jobOrderListData;

                if (list && sortedField != null) {
                        list = [...list].sort((a, b) => {
                                const valueA =
                                        a[sortedField] != null && a[sortedField] !== undefined
                                                ? typeof a[sortedField] == typeof "1"
                                                        ? a[sortedField].trim().toLowerCase()
                                                        : a[sortedField]
                                                : "";
                                const valueB =
                                        b[sortedField] != null
                                                ? typeof b[sortedField] == typeof "1"
                                                        ? b[sortedField].trim().toLowerCase()
                                                        : b[sortedField]
                                                : "";
                                if (sortDirection === "asc") {
                                        return valueA > valueB ? 1 : -1;
                                } else {
                                        return valueA < valueB ? 1 : -1;
                                }
                        });
                }

                return list?.filter(doc => {
                        const str = search.trim().toLowerCase();
                        return Object.values(doc).some(val =>
                                val && val.toString().trim().toLowerCase().includes(str)
                        );
                });
        }

        function handleChange(value: string) {
                setSearch(value);
        }
        function handleSearch() {
                console.log(search);
        }
        function handleSortFieldChange(value: string) {
                if (sortedField && sortedField === value) {
                        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                } else {
                        setSortedField(value);
                        setSortDirection('asc');
                }
        }
        function getFlexNumber(value: number) {
                if (value === 0) return 0.5;
              
                return 1;
        }
        return (<>
                <div className="job-orderlist-container">
                        <div className="job-orderlist-header">
                                {/* <div className="jobs-at-title">Job listings</div> */}
                                <div className="total-count">
                                        <span>
                                                {`${t("total_count")}: `}
                                                <span className="total-count-number"><b>{getFilteredList()?.length}</b></span>
                                        </span>
                                </div>
                                <div>
                                        <SearchBar value={search} onChange={handleChange} onSearch={handleSearch} placeholder="Search for jobs by title, keyword, location or company name" />
                                </div>
                        </div>
                        <div className="job-orderlist-content">
                                <SortableTable
                                        headerList={headers}
                                        sortedField={sortedField}
                                        onSortChange={handleSortFieldChange}
                                        flexNumber={getFlexNumber}
                                        isAsc={sortDirection}
                                >
                                        {getFilteredList()?.map((job, index) => {
                                                const [city, state, zipcode] = job.job_location.split(",").map(part => part.trim());
                                                return (
                                                        <tr key={"branch_list" + index} >
                                                                <TableData customStyle={{ flex: getFlexNumber(0) }}>{getTableAccessIcon(() => handleJobSelect(job))}</TableData>
                                                                <TableData customStyle={{ flex: getFlexNumber(1), wordWrap: "break-word" }}><span>{job?.hiring_organization}</span></TableData>
                                                                <TableData customStyle={{ flex: getFlexNumber(2), wordWrap: "break-word" }}><span>{job.job_title}</span></TableData>
                                                                <TableData customStyle={{ flex: getFlexNumber(3), wordWrap: "break-word" }}><span>{city}</span></TableData>
                                                                <TableData customStyle={{ flex: getFlexNumber(4), wordWrap: "break-word" }}><span>{state}</span></TableData>
                                                                <TableData customStyle={{ flex: getFlexNumber(5), wordWrap: "break-word" }}><span>{zipcode}</span></TableData>
                                                                <TableData customStyle={{ flex: getFlexNumber(6), wordWrap: "break-word" }}><span>{getDateString(job.date_posted)}</span></TableData>
                                                        </tr>
                                                );
                                        })}
                                </SortableTable>
                                {/* {getFilteredList()?.length === 0 && <>
                                        <div className="no-match">
                                                <span>No Matches Found!</span>
                                        </div>
                                </>}
                                {jobOrderListData !== undefined && getFilteredList()?.map((job, index) =>
                                        <>
                                                <div key={index} className="job-card">
                                                        <div className="job-card-row">
                                                                <div className="job-title" onClick={() => handleJobSelect(job)}>{job.job_title}</div>
                                                                <div className="posted">Posted {getDateString(job.date_posted)}</div>
                                                        </div>
                                                        <div className="job-card-row">
                                                                <div className="comp-loc-div"><div className="label-title"> <CompaniesIcon width={'100%'} height={'100%'} /><span>{job?.hiring_organization}</span></div>
                                                                        <div className="label-title"><LocationIcon width={'100%'} height={'100%'} /><span>{job?.job_location}</span></div>
                                                                </div>

                                                        </div>
                                                </div>
                                        </>)} */}
                        </div>
                        <div className="job-orderlist-footer">

                        </div>
                </div>
        </>)
}

export default KinisoJobOrders;