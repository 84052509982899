import { createSelector } from "@reduxjs/toolkit";
import { selectAll, selectState } from "./ninety_day_calls_report_reducer";
import { IRootState } from "../../../store";

const select = (state: IRootState) => state.reportCenter.reportCenterService.ninetyDayCallsReportsList;
export const selectNinetyDayCallsReportsListState = createSelector(select, selectState);
export const selectNinetyDayCallsReportsList = createSelector(
    select,
    selectAll
);
